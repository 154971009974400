import * as React from 'react';
import { useSelector } from 'react-redux';

import Tabs from 'antd/lib/tabs';

import TabsWithUrl, { TabMode } from '@common/react/components/UI/TabsWithUrl/TabsWithUrl';
import { getParams, loadable, loadableDelay } from '@common/react/loadable/loadableSettings';

import '@app/scss/ui/blocks/tabs.scss';
import { ApplicationState } from '@app/store';
import { Permission, activePermission } from '@app/objects/Permission';

const { TabPane } = Tabs;

const ClinicsSearchTab = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicsPage" */ '@app/components/Pages/Clinics/ClinicList')), getParams('Clinics'));
export const CrematoryInvoicesTab = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CrematoryInvoicesTab" */
			'@app/components/Pages/Clinics/ClinicTabs/Invoices/Invoices'
		)),
	getParams('CrematoryInvoices'),
);
export const RoutesTab = loadable(() =>
	loadableDelay(import('@app/components/Pages/Clinics/ClinicTabs/Routes/Routes')), getParams('CrematoryRoutes'));

export const CrematoryClinics = (): JSX.Element => {
	const user = useSelector((state: ApplicationState) => state.login.user);
	const activeCrematoryId = user?.activeCrematoryId;

	return (
		<TabsWithUrl
			defaultKey="clinic-list"
			tabsUrlKey="tab"
			className="subheader"
			mode={TabMode.Location}
		>
			<TabPane
				tab="Clinics"
				key="clinic-list"
			>
				<ClinicsSearchTab />
			</TabPane>
			{activePermission(user, Permission.ManageInvoices) && (
				<TabPane
					tab="Invoices"
					key="invoice-list"
				>
					<CrematoryInvoicesTab crematoryId={activeCrematoryId} />
				</TabPane>
			)}
			<TabPane
				tab="Routes"
				key="routes"
			>
				<RoutesTab />
			</TabPane>
		</TabsWithUrl>
	);
};
