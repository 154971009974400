import * as React from 'react';

import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { PetPrice } from '@app/objects/Pet';

interface OwnProps {
	services: Array<PetPrice>;
	onChange: (service: PetPrice) => void;
}

export const SpecialServicesList: React.FC<OwnProps> = ({ services, onChange }: OwnProps) => {
	if (!services.length) return null;

	const handleChange = (value: boolean, service: PetPrice) => {
		const newService = {
			...service,
			completedCount: value ? service.count : 0,
		};

		onChange(newService);
	};

	return (
		<table className="table">
			<thead>
				<tr>
					<th>Service </th>
					<th className="text-center">Count</th>
					<th className="text-center">Done</th>
				</tr>
			</thead>
			<tbody>
				{services.sort((a, b) => b.id - a.id).map((service) => (
					<tr
						key={`${service.priceId}-${service.id}-${service.done}`}
						onClick={() => handleChange(!service.completedCount, service)}
					>
						<td>
							{service?.name}
							{service.note ? <div className="special-service-description form-group">{service.note}</div> : null}
						</td>
						<td className="text-center">{service.count}</td>
						<td className="text-center">
							<Checkbox
								checked={service.count === service.completedCount}
								onChange={(event: CheckboxChangeEvent) => handleChange(event.target.checked, service)}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
