import * as React from 'react';

import Modal from 'antd/lib/modal/Modal';
import {
	Field, FieldProps, Form, Formik, FormikProps,
} from 'formik';

import Alert from 'antd/lib/alert';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { List } from '@common/typescript/objects/List';

import { ModalControls } from '@app/hooks/Editors/useModal';

import { SelectableRadioList } from '@app/components/UI/SelectableList/SelectableradioList';
import { StatusChangeComponent, StatusResponse } from '@app/components/UI/Status/StatusChangeComponent';
import { PromoteDirection, PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';
import { request } from '@app/components/Api';

import { Pet } from '@app/objects/Pet';
import { StatusOrder } from '@app/objects/StatusOrder';
import { CurrentStatus } from '@app/objects/CurrentStatus';

import { alertMessage, MessageType } from '@app/utilities/alert';

interface ForceStatusFormProps {
	pet: Pet;
	modal: ModalControls;
	onChangeStatus: (response: CurrentStatus) => void;
}

interface ForceStatus {
	id: number,
	name: string,
	description: string | null,
	statusId: number,
}

const initialValues = {
	id: -1,
	name: '',
	description: '',
	statusId: -1,
};

export const ForceStatusForm: React.FC<ForceStatusFormProps> = (props: ForceStatusFormProps) => {
	const { pet, modal, onChangeStatus } = props;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<boolean>(false);
	const [data, setData] = React.useState<Array<ForceStatus>>([]);
	const reqParams = React.useMemo(() => ({
		objectId: pet.crematoryId,
		serviceType: pet.serviceType,
		priceType: pet.priceType,
	}), [pet]);

	React.useEffect(() => {
		setIsLoading(true);
		request<List<StatusOrder>>('statusOrderList', { ...reqParams })
			.then((statusOrder: List<StatusOrder>) => {
				const items: Array<ForceStatus> = statusOrder.list.map((item: StatusOrder) => {
					return {
						id: item.status?.id ?? -1,
						name: item.status?.name ?? '',
						description: item.status?.description ?? '',
						statusId: item.statusId,
					};
				});
				setData(items);
			})
			.catch((err) => alertMessage(MessageType.error, err))
			.finally(() => setIsLoading(false));
	}, [reqParams]);

	return (
		<StatusChangeComponent>
			{(promote) => (
				<Formik
					onSubmit={(values, { setSubmitting }) => 	{
						if (values.statusId < 0) {
							setError(true);
							setSubmitting(false);
						} else {
							promote.request(PromoteDirection.PD_NEXT, pet.id, { confirmChanges: true, forceStatusId: values.statusId })
								.then((response: StatusResponse) => {
									if (response.currentStatus && response.code === PromoteResponseCode.Success) {
										onChangeStatus(response.currentStatus);
									} else {
										alertMessage(MessageType.warning, response.message);
									}
									modal.close();
								})
								.catch((error: string) => alertMessage(MessageType.error, error))
								.finally(() => setSubmitting(false));
						}
					}}
					initialValues={initialValues}
				>
					{(formikBag: FormikProps<ForceStatus>) => (
						<Form>
							<Modal
								title="Statuses"
								visible={modal.state}
								onOk={() => formikBag.handleSubmit()}
								onCancel={() => {
									modal.close();
									formikBag.resetForm();
								}}
								okText="Save"
								cancelText="Cancel"
								okButtonProps={{ loading: formikBag.isSubmitting }}
							>
								<div className="">
									<Field name="statusId">
										{(fieldProps: FieldProps<ForceStatus>) => (
											<FormikInput
												fieldProps={fieldProps}
												containerClassName="form-group"
												title=""
												render={({ field, form }) => (
													<SelectableRadioList
														items={data}
														isLoading={isLoading}
														value={field.value}
														onChange={(id: number) => form.setFieldValue(field.name, id)}
														withSingleDefault
													/>
												)}
											/>
										)}
									</Field>
								</div>
								{error && <Alert message="Select a status!" type="error" />}
							</Modal>
						</Form>
					)}
				</Formik>
			)}
		</StatusChangeComponent>
	);
};
