import * as React from 'react';

import AutoComplete, { AutoCompleteProps, DataSourceItemType } from 'antd/lib/auto-complete';
import { SelectValue } from 'antd/es/select';
import Icon from 'antd/lib/icon';

import { request } from '@common/react/components/Api';
import { Rows } from '@common/typescript/objects/List';
import { debounce } from '@common/typescript/Utils';
import { WithId } from '@common/typescript/objects/WithId';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { isFunction, isUndefined } from '@common/react/utils/guards';

export const Option = AutoComplete.Option;

interface AutocompleteState {
	items: DataSourceItemType[];
	value: string;
	isLoading: boolean;
}

type RenderTitleFunc<T> = (item: T) => React.ReactNode;

export interface AutocompleteProps<T extends WithId = any> extends Pick<AutoCompleteProps, 'children'>{
	type: string;
	onSelect: (value: string, option?: any) => void;
	onChange?: (value: string) => void;
	renderOption?: (item: T) => DataSourceItemType;
	renderTitle?: keyof T | RenderTitleFunc<T>;
	params?: BaseParams;
	paramName?: string;
	min?: number;
	value: string;
	isClear?: boolean;
	antdProps?: AutoCompleteProps;
	loadOnFocus?: boolean;
	disabled?: boolean;
	placeholder?: string;
	onExtraRender?: (state: AutocompleteState, props: AutocompleteProps<T>) => void;
	loaderMarkup?: JSX.Element;
	updateAfterSelect?: boolean;
	transformValue?: (value: string) => string;
	shouldSelectMatch?: boolean;
}

export default class Autocomplete<T extends WithId = any> extends React.Component<AutocompleteProps<T>, AutocompleteState> {
	public static defaultProps: Partial<AutocompleteProps> = {
		value: '',
		loaderMarkup: <Icon type="loading" />,
		paramName: 'text',
		shouldSelectMatch: true,
	};

	constructor(props: AutocompleteProps) {
		super(props);

		this.state = {
			items: [],
			value: this.props.value || '',
			isLoading: false,
		};

		this.loadItems = debounce(this.loadItems.bind(this), 300);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.defaultRender = this.defaultRender.bind(this);
		this.selectMatchingOption = this.selectMatchingOption.bind(this);
	}

	defaultRender(item: T) {
		const { renderTitle } = this.props;

		let title;

		if (isFunction(renderTitle)) {
			title = renderTitle(item);
		} else {
			title = `${item[(renderTitle || 'name') as keyof T]}`;
		}

		return <Option key={item.id} value={`${item.id}`} title={title} {...{ item }}>{title}</Option>;
	}

	public componentDidUpdate(prevProps: Readonly<AutocompleteProps<T>>) {
		if (this.props.value !== prevProps.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	shouldComponentUpdate(nextProps: AutocompleteProps, nextState: AutocompleteState): boolean {
		return nextProps.value !== this.props.value
			|| nextProps.disabled !== this.props.disabled
			|| nextProps.placeholder !== this.props.placeholder
			|| nextState.value !== this.state.value
			|| nextState.items !== this.state.items
			|| nextState.isLoading !== this.state.isLoading;
	}

	onChange = (value: SelectValue) => {
		this.setState({
			value: value as string,
		});

		this.props.onChange && this.props.onChange(value as string);
	}

	onSelect = (value, option) => {
		this.props.onSelect && this.props.onSelect(value, option);

		if (this.props.isClear) {
			setTimeout(() => {
				this.setState({
					value: '',
				});
			}, 0);
		} else {
			setTimeout(() => {
				this.setState({
					items: [],
				});
			}, 0);
		}

		if (this.props.updateAfterSelect) {
			setTimeout(() => this.loadItems(this.state.value), 0);
		}
	}

	loadItems(value: string) {
		const {
			renderOption, type, params, paramName, transformValue, shouldSelectMatch,
		} = this.props;

		this.setState({ isLoading: true });

		const newParams = {
			...params,
			[paramName!]: transformValue ? transformValue(value) : value,
		};

		request<Rows<T>, BaseUser, BaseApplicationState<BaseUser>>(type, newParams).then((response) => {
			const items = response.list.map(renderOption || this.defaultRender);

			this.setState({
				isLoading: false,
				items,
			});

			shouldSelectMatch && this.selectMatchingOption(items, value);
		}).catch(() => {
			this.setState({
				isLoading: false,
				items: [],
			});
		});
	}

	selectMatchingOption(items, value: string) {
		if (items.length === 1 && value) {
			const item = this.state.items[0] as any;

			const condition: boolean = item.props && typeof item.props.children === 'string'
				&& item.props.children.toLowerCase() === value.toLowerCase();

			if (condition) {
				this.setState({
					value: item.key,
				});

				this.onSelect(item.key, item);
			}
		}
	}

	onSearchHandler(value: string) {
		const { min = 3, loadOnFocus } = this.props;

		if (value.length >= min || loadOnFocus) {
			this.loadItems(value);
		} else {
			this.setState({
				items: [],
			});
		}
	}

	onFocus = () => {
		if (this.state.value === '') {
			this.onSearchHandler('');
		}
	}

	render(): JSX.Element {
		const {
			disabled, onExtraRender, loadOnFocus, loaderMarkup, placeholder, antdProps, children,
		} = this.props;

		return (
			<>
				<div className={`autocomplete-component ${disabled ? 'autocomplete-component_disabled' : ''}`}>
					{this.state.isLoading && <div className="autocomplete-component__loader ">{loaderMarkup}</div>}
					<AutoComplete
						onChange={this.onChange}
						dataSource={this.state.items}
						allowClear
						onSelect={this.onSelect}
						onFocus={loadOnFocus ? this.onFocus : undefined}
						onSearch={this.onSearchHandler}
						value={this.state.value}
						optionLabelProp="title"
						disabled={disabled}
						placeholder={isUndefined(placeholder) ? 'Start typing for search...' : placeholder}
						{...antdProps}
					>
						{children}
					</AutoComplete>
				</div>
				{onExtraRender && onExtraRender(this.state, this.props)}
			</>
		);
	}
}
