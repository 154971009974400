import * as React from 'react';

import clsx from 'clsx';

export enum AlertType {
	Primary = 0,
	Secondary = 1,
	Success = 2,
	Danger = 3,
	Warning = 4,
	Info = 5,
	Light = 6,
	Dark = 7,
}

interface AlertMessageProps {
	message?: string | null;

	type?: AlertType;
	style?: React.CSSProperties;
	className?: string;
}

function toClassName(type: AlertType): string {
	switch (type) {
	case AlertType.Primary: return 'alert-primary';
	case AlertType.Secondary: return 'alert-secondary';
	case AlertType.Success: return 'alert-success';
	case AlertType.Danger: return 'alert-danger';
	case AlertType.Warning: return 'alert-warning';
	case AlertType.Info: return 'alert-info';
	case AlertType.Light: return 'alert-light';
	case AlertType.Dark: return 'alert-dark';

	default: return '';
	}
}

export const AlertMessage: React.FC<AlertMessageProps> = (props: AlertMessageProps) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const className = clsx('alert', toClassName(props.type!), props.className);

	return props.message ? <div className={className} style={props.style}>{props.message}</div> : null;
};

AlertMessage.defaultProps = {
	message: null,
	type: AlertType.Danger,
};
