import * as React from 'react';
import { Link } from 'react-router-dom';

import '@app/scss/ui/footer.scss';

interface FooterState {
	color?: string;
	colors?: Array<string>;
}

export const Footer: React.FC<FooterState> = () => (
	<footer className="footer">
		<div className="footer__container">
			<Link to="/"><img src={require('@images/logo.png')} alt="Logo" className="footer__logo" /></Link>
			<span className="icon-margin">Powered By Wings Tracker</span>
		</div>
	</footer>
);
