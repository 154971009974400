import * as React from 'react';

import { ColumnProps } from 'antd/lib/table';

import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import { WithId } from '@common/typescript/objects/WithId';

interface ActionHandlers<TEntity> {
	isEdit: (id: number) => boolean;
	handleInputSubmit: (record: TEntity) => void;
	handleInputCancel: (record: TEntity) => void;
	handleDelete: (event: React.MouseEvent<HTMLButtonElement>, record: TEntity) => void;
	handleInput: (record: TEntity) => void;
}

interface ActionOptions {
	withEdit: boolean;
	withDelete: boolean;
}

export default class TableBuilder<TEntity extends WithId> {
	private constructor() {}
	private template: Array<ColumnProps<TEntity>> = Array<ColumnProps<TEntity>>();

	public static shape<TEntity extends WithId>() {
		return new TableBuilder<TEntity>();
	}

	public build(): Array<ColumnProps<TEntity>> {
		const result = this.template;
		this.template = Array<ColumnProps<TEntity>>();

		return result;
	}

	public addColumn(column: ColumnProps<TEntity>, condition: boolean = true): TableBuilder<TEntity> {
		if (condition) {
			this.template.push(column);
		}

		return this;
	}

	public addIdColumn(options?: ColumnProps<TEntity>, condition: boolean = true): TableBuilder<TEntity> {
		const baseColumn = {
			title: '#',
			dataIndex: 'id',
			render: (text: string, record: TEntity): JSX.Element | null => (
				<MobileCell caption="#">
					{
						record.id > 0
							? <span style={{ whiteSpace: 'nowrap' }}>{record.id}</span>
							: null
					}
				</MobileCell>
			),
		};

		this.addColumn(options ? {
			...baseColumn,
			...options,
		} : baseColumn, condition);

		return this;
	}

	public addActionsColumn(
		{
			isEdit,
			handleInputSubmit,
			handleInputCancel,
			handleDelete,
			handleInput,
		}: ActionHandlers<TEntity>,
		{
			withEdit,
			withDelete,
		}: ActionOptions,
	): TableBuilder<TEntity> {
		this.addColumn({
			title: '',
			render: (text: string, record: TEntity) => (
				<div className="text-right table-actions">
					{
						withEdit && isEdit(record.id)
							? (
								<div>
									<button
										className="btn btn-sm btn-primary"
										type="button"
										title="Save"
										onClick={() => handleInputSubmit(record)}
									>
										<i className="fa fa-save" />
									</button>
									<button
										className="btn btn-sm btn-default"
										type="button"
										title="Cancel"
										onClick={() => handleInputCancel(record)}
									>
										<i className="fa fa-times" />
									</button>
									{
										withDelete && record.id !== -1
											? (
												<button
													className="btn btn-sm btn-danger"
													type="button"
													title="Delete"
													onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDelete(e, record)}
												>
													<i className="fa fa-trash" />
												</button>
											) : null
									}
								</div>
							)
							: (
								<div>
									{
										withEdit
											? (
												<button
													className="btn btn-sm btn-default"
													type="button"
													title="Edit"
													onClick={() => handleInput(record)}
												>
													<i className="fa fa-pencil" />
												</button>
											) : null
									}
									{
										withDelete
											? (
												<button
													className="btn btn-sm btn-danger"
													type="button"
													title="Delete"
													onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDelete(e, record)}
												>
													<i className="fa fa-trash" />
												</button>
											) : null
									}
								</div>
							)
					}
				</div>
			),
		});

		return this;
	}
}
