import * as React from 'react';

import { List, transformArrayToList } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import { request } from '@app/components/Api';

interface OwnProps<T> {
	endpoint: string;
	filterParams: Record<string, unknown>;
	perPage?: number;
	children: (data: LoadedData<T>) => JSX.Element;
	onCompleted?: () => void;
}

export interface LoadedData<T> {
	reload: () => void;
	items: List<T>;
	error: Nullable<string>;
	isLoading: boolean;
}

export const DataProvider = <T extends WithId> (props: OwnProps<T>): ReturnType<React.FC<OwnProps<T>>> => {
	const { endpoint, filterParams, perPage = 1000 } = props;

	const [isLoading, setLoading] = React.useState<boolean>(false);
	const [items, setItems] = React.useState<List<T>>(transformArrayToList([]));
	const [error, setError] = React.useState<string | null>(null);
	const controller = React.useRef<Nullable<AbortController>>(null);

	const reload = () => {
		setLoading(true);
		setError(null);

		const abortController = new AbortController();
		controller.current = abortController;

		const promise = request<List<T>>(endpoint, {
			filters: {
				...filterParams,
			},
			count: perPage,
		}, undefined, abortController.signal)
			.then((res) => {
				setItems(res);
			})
			.catch((error: string) => {
				if (abortController.signal.aborted) return;

				setError(error);
			})
			.finally(() => {
				if (abortController.signal.aborted) return;

				setLoading(false);
			});

		if (props.onCompleted) {
			promise.finally(() => {
				if (abortController.signal.aborted) return;

				props.onCompleted?.();
			});
		}
	};

	React.useEffect(() => {
		reload();

		return () => controller.current?.abort();
	}, Object.values(filterParams));

	const data: LoadedData<T> = {
		reload,
		items,
		error,
		isLoading,
	};

	return props.children(data);
};
