import * as React from 'react';

import clsx from 'clsx';
import Popover from 'antd/lib/popover';
import Pagination from 'antd/lib/pagination';
import Button from 'antd/lib/button';

import { Nullable } from '@common/typescript/objects/Nullable';
import { Pagination as IPagination } from '@common/react/smart components/Table/TableDataProvider';

import { ViewMode } from '@app/components/Utils/Utils';
import '@app/scss/pages/view-card.scss';

export enum ClickableType {
	Telephone = 0,
	Email = 1,
	Link = 2,
}

export enum CardSize {
	Tiny = 'xs',
	Small = 'sm',
	Medium = 'md',
	Large = 'lg',
}

export interface Field {
	title: string;
	data: React.ReactNode;
	caption?: string;
	clickableType?: ClickableType;
}

export interface ICardField {
	title: string;
	children: React.ReactNode;
}

interface BaseCardProps {
	name: string;

	size: CardSize;
	fullWidth?: boolean;
	twoColumns?: boolean;
	cardWrapperClassname?: string;

	withBtn?: boolean;
	btnCaption?: string;
	onClick?: () => void;
	extra?: React.ReactNode;

	className?: string;
	disabled?: boolean;
}

interface EditCardProps extends BaseCardProps {
	mode: ViewMode.edit;
	editContent: React.ReactNode | Array<React.ReactNode>;
}

interface ViewCardProps extends BaseCardProps {
	mode?: ViewMode.view;
	viewContent: React.ReactNode | Array<React.ReactNode>;
}

type CardProps = ViewCardProps | EditCardProps;

interface ListProps<T> {
	list: Array<T>;
	placeholder: string;

	pagination?: IPagination;

	bold?: boolean;
}

export interface ItemsList {
	id: number;
	title: string;
	caption?: string;
	data: React.ReactNode;
	content?: React.ReactNode;
	icon?: React.ReactNode;
}

interface InteractionData {
	label: string;
	href: string;
}

export function getInteraction(type: Nullable<ClickableType> | undefined, data: React.ReactNode): Nullable<InteractionData> {
	if (typeof data !== 'string') return null;
	if (!data) return null;

	switch (type) {
	case ClickableType.Telephone:
		return {
			label: 'call',
			href: `tel: ${data}`,
		};

	case ClickableType.Email:
		return {
			label: 'send email',
			href: `mailto:${data}`,
		};

	case ClickableType.Link:
		return {
			label: `link to ${data}`,
			href: data,
		};

	default:
		return null;
	}
}

export function getClickable(type: Nullable<ClickableType> | undefined, data: string, display?: string, target: string = '_blank'): React.ReactNode {
	const info = getInteraction(type, data);
	const title = display || data || '—';
	if (info === null) return title;

	return (
		<a
			aria-label={info.label}
			href={info.href}
			target={target}
			rel="noopener noreferrer"
		>
			{title}
		</a>
	);
}

export function getCaption(caption?: string) {
	if (!caption) return null;

	return (
		<Popover content={caption}>
			<i className="fa fa-question-circle-o" style={{ marginLeft: '5px' }} />
		</Popover>
	);
}

export const getCardFields = (fields: Array<Field | undefined>): JSX.Element => {
	return (
		<>
			{
				fields.map((field) => {
					if (field) {
						const data = getInteraction(field.clickableType, field.data);

						return (
							<div className="card__info-block" key={field.title}>
								<span className="card__title">{field.title} {getCaption(field.caption)}</span>
								{
									data ? (
										<a
											aria-label={data.label}
											href={data.href}
										>
											{field.data || '—'}
										</a>
									)
										: (
											<span className="card__data" title={field.caption}>
												{field.data || '—'}
											</span>
										)
								}
							</div>
						);
					}

					return undefined;
				})
			}
		</>
	);
};

export const CardField = ({ title, children }: ICardField) => (
	<div className="card__info-block" key={title}>
		<span className="card__title">{title}</span>
		<span className="card__data">{children || '—'}</span>
	</div>
);

export const ListComponent: React.FC<ListProps<ItemsList>> = (props: ListProps<ItemsList>) => {
	const list = props.list;

	if (list.length === 0) return <div className="card__list-container"><p>{props.placeholder}</p></div>;

	return (
		<div className="card__list-container">
			{
				list.map((item: ItemsList) => {
					const titleClass = clsx('card__title', props.bold && 'card__title--bold');

					return (
						<div className="card__info-block" key={item.id}>
							<div className="card__table-wrap">
								<span className={titleClass}>
									{item.title} {item.icon}
								</span>
								<span className="card__data">{item.data}</span>
							</div>
							{item.content}
						</div>
					);
				})
			}
			{props.pagination && (
				<Pagination
					total={props.pagination.total}
					hideOnSinglePage
					pageSize={props.pagination.pageSize}
					onChange={(value: number) => props.pagination?.onChange(value)}
					current={props.pagination.current}
					style={{ textAlign: 'center' }}
					size="small"
				/>
			)}
		</div>
	);
};

function getHeader(props: CardProps): React.ReactNode {
	if (props.withBtn) {
		return (
			<div className="card__heading-with-btn">
				<span className="card__heading">{props.name}</span>
				<Button
					type="link"
					className="card__button-add"
					title={props.btnCaption}
					onClick={props.onClick}
					disabled={props.disabled}
				>
					{!props.btnCaption ? <i className="fa fa-plus btn-icon" /> : null}
					<span className="icon-margin btn-text">{props.btnCaption || 'Add'}</span>
				</Button>
			</div>
		);
	}

	if (props.name) {
		return (
			<div className="card__heading-with-btn">
				<span className="card__heading">
					{props.name}
				</span>
				{props.extra}
			</div>
		);
	}

	return null;
}

export const Card: React.FC<CardProps> = (props: CardProps) => {
	const cardWrapperClassname = clsx('card', props.size && `card--${props.size}`, props.cardWrapperClassname);
	const cardContentClassname = clsx('card__content', {
		'card__content--full-width': props.fullWidth,
		'card__content--edit': props.mode === ViewMode.edit,
		'card__content--two-columns': props.twoColumns,
	}, props.className);

	const header: React.ReactNode = getHeader(props);
	let content: React.ReactNode = null;
	if (props.mode === ViewMode.view) {
		content = props.viewContent;
	} else if (props.mode === ViewMode.edit) {
		content = props.editContent;
	}

	return (
		<div className={cardWrapperClassname}>
			{header}
			<div className={cardContentClassname}>{content}</div>
		</div>
	);
};

Card.defaultProps = {
	mode: ViewMode.view,

	fullWidth: false,
	twoColumns: false,
	withBtn: false,
	disabled: false,
	extra: null,
};
