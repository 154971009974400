import { createBrowserHistory, History } from 'history';

import { ClientSideError } from '@common/react/errors/ClientSideError';
import { DOMError } from '@common/react/errors/DOMError';
import { Nullable } from '@common/typescript/objects/Nullable';
import { IHistoryProvider } from '@common/react/services/interfaces/IHistoryProvider';

export class HistoryProvider implements IHistoryProvider {
	private readonly _history: History;

	constructor() {
		if (!document) throw new ClientSideError('history is unavailable on server');

		const elements = document.getElementsByTagName('base');
		if (elements.length === 0) throw new DOMError('missing base element');

		const baseUrl: Nullable<string> = elements[0].getAttribute('href');
		if (!baseUrl) throw new DOMError('missing href attribute');

		this._history = createBrowserHistory({ basename: baseUrl });
	}

	public get history(): History {
		return this._history;
	}
}
