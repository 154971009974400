/* eslint-disable import/no-import-module-exports */
import * as React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ReducersMapObject } from 'redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { AppContainer } from 'react-hot-loader';

import * as Sentry from '@sentry/browser';

import { BootManager as BaseBootManager } from '@common/react/services/BootManager';
import { StoreProvider } from '@common/react/services/StoreProvider';
import { IStoreProvider } from '@common/react/services/interfaces/IStoreProvider';
import { IHistoryProvider } from '@common/react/services/interfaces/IHistoryProvider';
import { HistoryProvider } from '@common/react/services/HistoryProvider';
import { updateReducers } from '@common/react/configureStore';

import { ApplicationState, reducers } from '@app/store';
import { routes } from '@app/routes';

import { User } from '@app/objects/User';
import { SentryErrorBoundary } from '@app/components/UI/ErrorBoundary/SentryErrorBoundary';
import { initInternationalization } from '@app/internationalization/init';
import { InternationalizationController } from '@app/smart components/InternationalizationController';

export class BootManager extends BaseBootManager<User, ApplicationState> {
	protected readonly _storeProvider: IStoreProvider<User, ApplicationState> = new StoreProvider();
	protected readonly _historyProvider: IHistoryProvider = new HistoryProvider();

	protected _routes: React.ReactNode = routes;
	protected _reducers: ReducersMapObject = reducers;

	private readonly sentryKey: string = 'https://d6d8a8455ebd47528bf4bc6da7409c91@o376838.ingest.sentry.io/5198090';

	protected preBoot(): void {
		super.preBoot();

		Sentry.init({ dsn: this.sentryKey });
		initInternationalization();

		// Allow Hot Module Replacement
		if (module.hot) {
			module.hot.accept('../routes', () => {
				this._routes = require('../routes').routes;
				this.inject();
			});
		}

		// Enable Webpack hot module replacement for reducers
		if (module.hot) {
			module.hot.accept('../store', () => {
				const nextRootReducer = require('../store');
				updateReducers(nextRootReducer.reducers);
			});
		}
	}

	protected render(): React.ReactElement {
		const store = this._storeProvider.store;
		const history = this._historyProvider.history;

		return (
			<AppContainer>
				<Provider store={store}>
					<Router history={history}>
						<SentryErrorBoundary>
							<InternationalizationController>
								{this._routes}
							</InternationalizationController>
						</SentryErrorBoundary>
					</Router>
				</Provider>
			</AppContainer>
		);
	}
}
