import * as React from 'react';

import MaskedInput from 'react-text-mask';

import { phoneMask } from '@app/components/Utils/Utils';

interface PhoneInputProps {
	phoneCode?: string;
	name: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const defaultCode = '+1';
export const newZealandCode = '+64';
export const australiaCode = '+61';

export const PhoneInput: React.FC<PhoneInputProps> = (props: PhoneInputProps) => {
	const code = props.phoneCode ?? defaultCode;
	const isSpecialCode = code === newZealandCode || code === australiaCode;

	return (
		<MaskedInput
			mask={phoneMask(code)}
			className="form-control"
			placeholder={isSpecialCode ? code : `${code} (___) ___-____`}
			guide={!isSpecialCode}
			{...props}
			id={props.name}
		/>
	);
};
