import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Action, bindActionCreators, Dispatch } from 'redux';
import { FormikProps, FormikHelpers } from 'formik';

import { request } from '@common/react/components/Api';
import { BaseApplicationState } from '@common/react/store';
import { Extend } from '@common/typescript/utils/types';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseParams } from '@common/react/objects/BaseParams';
import Button from '@common/react/components/Forms/Button';
import { WithId } from '@common/typescript/objects/WithId';
import {
	getActionCreators as getItemActionCreators,
	IActionCreators as ItemActionCreators,
	IMappedActionCreators as MappedItemActionCreators,
	ItemState,
} from '@common/react/store/Item';
import {
	getActionCreators as getItemsActionCreators,
	IActionCreators as ItemsActionCreators,
	IMappedActionCreators as MappedItemsActionCreators,
} from '@common/react/store/ItemList';
import { Nullable } from '@common/typescript/objects/Nullable';

export interface Actions<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	itemActions: MappedItemActionCreators<T, TUser, TApplicationState>;
	pagesActions: MappedItemsActionCreators<T, TUser, TApplicationState>;
}

export type PageProps<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> =
	ItemState<T>
	& Actions<T, TUser, TApplicationState>
	& RouteComponentProps<{ id: string }>;

export interface ItemEditorDefaultState {
	isLoading: boolean;
	success: boolean;
	error: string | null;
}

type ComponentProps<
	TEntity extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>,
	TPropsExtension = never
> = Extend<PageProps<TEntity, TUser, TApplicationState>, TPropsExtension>;

interface LocationState {
	prevPath?: string;
}

export abstract class ExtendableItemEditor<
	TEntity extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>,
	TPropsExtension = never,
	TState extends ItemEditorDefaultState = ItemEditorDefaultState,
	TValues = Record<string, never>
> extends React.Component<ComponentProps<TEntity, TUser, TApplicationState, TPropsExtension>, TState> {
	public abstract store: keyof TApplicationState;
	public abstract itemsStore: keyof TApplicationState;

	public abstract saveItemPath: string;
	public abstract itemsPath: string;
	public abstract path: string;
	public abstract backPath: string;
	public additionalParams: BaseParams = {};

	public abstract defaultItem: TEntity;
	public state: TState = {
		isLoading: false,
		success: false,
		error: null,
	} as TState;

	protected form = React.createRef<FormikProps<TValues>>();

	constructor(props: ComponentProps<TEntity, TUser, TApplicationState, TPropsExtension>) {
		super(props);

		this.handlerBack = this.handlerBack.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleCheckItem = this.handleCheckItem.bind(this);
		this.saveItem = this.saveItem.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.clearForSubmit = this.clearForSubmit.bind(this);
		this.updateValues = this.updateValues.bind(this);
		this.returnAfterSubmit = this.returnAfterSubmit.bind(this);
		this.refreshPages = this.refreshPages.bind(this);
	}

	componentDidMount(): void {
		const itemPathOrId = this.getIdParam();
		this.props.itemActions.loadItem(this.store, this.path, itemPathOrId, this.defaultItem, this.additionalParams);
	}

	componentDidUpdate(prevProps: Readonly<ComponentProps<TEntity, TUser, TApplicationState, TPropsExtension>>): void {
		if (this.props.match.params.id && +this.props.match.params.id !== +prevProps.match.params.id) {
			this.props.itemActions.loadItem(this.store, this.path, +this.props.match.params.id, this.defaultItem, this.additionalParams);
		}
	}

	handlerBack() {
		const state: Nullable<LocationState> | undefined = this.props.location.state as (Nullable<LocationState> | undefined);
		if (state && state.prevPath) {
			this.props.history.push(state.prevPath);
		} else {
			console.log('this.backPath', this.backPath);
			this.props.history.push(this.backPath);
		}
	}

	clearForSubmit(values: TValues): any {
		return undefined;
	}

	returnAfterSubmit(values: TValues, fields: Array<string> = [], response?: TEntity): TValues | {} {
		if (!fields.length) {
			return {};
		}

		const isArrayOfObjects = (value): boolean => value instanceof Array && value[0] instanceof Object && value[0].id;

		const valuesToReturn = {};
		for (const key of fields) {
			valuesToReturn[key] = values[key];

			if (response && isArrayOfObjects(valuesToReturn[key])) {
				valuesToReturn[key] = valuesToReturn[key].filter((value) => !value.deleted).map((obj, index) => (response[key][index] ? {
					...obj,
					id: response[key][index].id,
				} : obj));
			}
		}

		return valuesToReturn;
	}

	submitForm(values: TValues, returnToList: boolean = true): Promise<TEntity | void> {
		const item: TEntity = { ...this.props.item, ...values, ...this.clearForSubmit(values) };

		return this.saveItem(item).then((response: TEntity) => {
			this.updateItem({ ...response, ...this.returnAfterSubmit(values, [], response) }, true);

			if (item.id === -1) {
				this.props.history.replace({
					...this.props.location,
					pathname: this.props.location.pathname.replace('/-1', `/${response.id}`),
				});
			}

			if (returnToList) {
				this.returnToList();
			} else {
				this.hideSuccess();
			}
		});
	}

	saveItem(item: Partial<TEntity>): Promise<TEntity> {
		this.setState({
			isLoading: true,
			error: null,
		});

		return request<TEntity, TUser, TApplicationState>(this.saveItemPath, item).then((response) => {
			this.setState({
				isLoading: false,
				success: true,
			});

			return response;
		}).catch((error: string) => {
			this.setState({
				error,
				isLoading: false,
			});

			throw error;
		});
	}

	updateItem(data: Partial<TEntity>, refreshItemsStore: boolean = false) {
		this.props.itemActions.updateItem(this.store, data);
		refreshItemsStore && this.refreshPages();
	}

	updateValues(data: Partial<TEntity>) {
		const form = this.form.current;

		if (form) {
			form.setValues({ ...form.values, ...data });
		}
	}

	returnToList(): void {
		setTimeout(() => {
			this.handlerBack();
		}, 2000);
	}

	handleSubmit(values: TValues, formikActions: FormikHelpers<TValues>) {
		this.submitForm(values);
	}

	hideSuccess() {
		setTimeout(() => {
			this.setState({
				success: false,
			});
		}, 2000);
	}

	messages() {
		return (
			<>
				{this.state.success ? <div className="alert alert-success">Successfully saved</div> : ''}
				{this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : ''}
			</>
		);
	}

	refreshPages() {
		this.props.pagesActions.refreshPages(this.itemsStore, this.itemsPath);
	}

	buttons() {
		return (
			<div className="text-center form-group">
				<Button isLoading={this.state.isLoading}>Save</Button>
				<button type="button" className="btn btn-danger" onClick={this.handlerBack}>Cancel</button>
			</div>
		);
	}

	handleCheckItem() {
		const item: Partial<TEntity> | null = this.props.item;

		if (item && this.props.match && item.id === +this.props.match.params.id) {
			return true;
		}
	}

	getIdParam() {
		return this.props.match ? +this.props.match.params.id : -1;
	}
}

export abstract class ItemEditor<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>,
	TState extends ItemEditorDefaultState = ItemEditorDefaultState,
	TValues = any
> extends ExtendableItemEditor<T, TUser, TApplicationState, never, TState, TValues> {}

export function dispatchToProps<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(dispatch: Dispatch<Action>): Actions<T, TUser, TApplicationState> {
	return {
		itemActions: bindActionCreators<
			ItemActionCreators<T, TUser, TApplicationState>,
			MappedItemActionCreators<T, TUser, TApplicationState>
			>(getItemActionCreators<T, TUser, TApplicationState>(), dispatch),
		pagesActions: bindActionCreators<
			ItemsActionCreators<T, TUser, TApplicationState>,
			MappedItemsActionCreators<T, TUser, TApplicationState>
			>(getItemsActionCreators<T, TUser, TApplicationState>(), dispatch),
	};
}
