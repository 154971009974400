import { Action, ActionCreatorsMapObject, Reducer } from 'redux';

import { request } from '@common/react/components/Api';
import { BaseApplicationState, BaseAppThunkAction } from '@common/react/store/index';
import { BaseUser } from '@common/react/objects/BaseUser';
import { equal } from '@common/typescript/Utils';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { List } from '@common/typescript/objects/List';
import { WithId } from '@common/typescript/objects/WithId';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { updateArrayItem } from '@common/typescript/utils/immutability';

export interface ItemsState<T extends WithId> {
	isLoading: boolean;
	items: Array<T>;
	pagination: {
		total: number;
		current: number;
		offset: number;
		pageSize?: number;
	};
	type: string;
	params: BaseParams;
}

const defaultItemsState = {
	isLoading: false,
	items: [],
	pagination: {
		total: 0,
		current: 0,
		offset: 0,
		pageSize: 0,
	},
	type: '',
	params: {},
};

export enum TypeKeys {
	REQUESTITEMS = 'REQUESTITEMS',
	RECEIVEITEMS = 'RECEIVEITEMS',
	REQUESTMOREITEMS = 'REQUESTMOREITEMS',
	RECEIVEMOREITEMS = 'RECEIVEMOREITEMS',
	UPDATEITEM = 'UPDATEITEM',
	UPDATEBATCH = 'UPDATEBATCH',
	ADDITEM = 'ADDITEM',
	ADDITEMS = 'ADDITEMS',
	DELETEITEM = 'DELETEITEM',
	INITSTORAGE = 'INITSTORAGE'
}

export interface InitStorageAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.INITSTORAGE;
	storageName: keyof TApplicationState | null;
	items: T[] | null | undefined;
	total?: number | null;
	params: any;
	objectType: string;
}

interface RequestItemsAction<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.REQUESTITEMS;
	storageName: keyof TApplicationState | null;
	params: any;
	objectType: string;
}

interface ReceiveItemsAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.RECEIVEITEMS;
	storageName: keyof TApplicationState | null;
	items: T[];
	total: number;
	offset: number;
	objectType: string;
}

interface UpdateItemAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.UPDATEITEM;
	storageName: keyof TApplicationState | null;
	paramName: keyof T;
	item: Partial<T>;
}

export enum ClipBy {
	Start,
	End,
	None
}

interface UpdateBatchAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.UPDATEBATCH;
	storageName: keyof TApplicationState | null;
	paramName: string;
	items: T[];
	sortBy: string | false;
	clip: ClipBy;
	insertNew: boolean;
}

interface RequestMoreItemsAction<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.REQUESTMOREITEMS;
	storageName: keyof TApplicationState | null;
	params: any;
}

interface ReceiveMoreItemsAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.RECEIVEMOREITEMS;
	storageName: keyof TApplicationState | null;
	items: T[];
	offset: number;
	total: number;
}

interface AddItemAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.ADDITEM;
	storageName: keyof TApplicationState | null;
	item: T;
	end?: boolean;
}

interface AddItemsAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.ADDITEMS;
	storageName: keyof TApplicationState | null;
	items: Array<T>;
	end?: boolean;
}

interface DeleteItemAction<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	type: TypeKeys.DELETEITEM;
	storageName: keyof TApplicationState | null;
	id: number;
}

export type KnownPageAction<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> = InitStorageAction<T, TUser, TApplicationState>
	| RequestItemsAction<TUser, TApplicationState>
	| ReceiveItemsAction<T, TUser, TApplicationState>
	| UpdateItemAction<T, TUser, TApplicationState>
	| RequestMoreItemsAction<TUser, TApplicationState>
	| ReceiveMoreItemsAction<T, TUser, TApplicationState>
	| AddItemAction<T, TUser, TApplicationState>
	| AddItemsAction<T, TUser, TApplicationState>
	| DeleteItemAction<T, TUser, TApplicationState>
	| UpdateBatchAction<T, TUser, TApplicationState>;

function loadPage<T extends WithId, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(
	dispatch: any,
	getState: any,
	store: keyof TApplicationState,
	type: string,
	path: string,
	params: any,
): Promise<Array<T>> {
	const fetchTask = request<List<T>, TUser, TApplicationState>(path, params, getState())
		.then((data: List<T>) => {
		dispatch({
			type: TypeKeys.RECEIVEITEMS,
			storageName: store,
			items: data.list,
			total: data.count,
			objectType: type,
			params,
			offset: data.offset,
		});

		return data.list;
	}).catch(() => {
		dispatch({
			type: TypeKeys.RECEIVEITEMS,
			storageName: store,
			items: [],
			total: 0,
			objectType: type,
			params,
			offset: 0,
		});

		return [];
	});

	dispatch({
		type: TypeKeys.REQUESTITEMS, storageName: store, params, objectType: type,
	});

	return fetchTask;
}

export interface IActionCreators<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> extends ActionCreatorsMapObject<BaseAppThunkAction<KnownPageAction<T, TUser, TApplicationState>, TUser, TApplicationState>> {
	initStorage: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		type: string,
		items?: TEntity[],
		params?: any
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	reqPages: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
		type: string,
		params: BaseParams
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	removeItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
		type: string,
		item: TEntity,
		newParams: BaseParams | null,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	refreshPages: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
		params?: BaseParams
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	updateItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		item: Partial<TEntity>,
		paramName?: keyof TEntity,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	updateArrayInItem: <TEntity extends WithId & T[keyof T]>(
		store: keyof TApplicationState,
		compareParam: T[keyof T],
		field: keyof T,
		changedArrayItem: Partial<TEntity>,
		compareParamName: keyof T,
		arrayItemParamName: keyof TEntity,
	) => BaseAppThunkAction<KnownPageAction<T, TUser, TApplicationState>, TUser, TApplicationState>;
	updateBatch: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		items: TEntity[],
		paramName?: string,
		sortBy?: string | false,
		clip?: ClipBy,
		insertNew?: boolean,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	loadMoreItems: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	addItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		item: TEntity,
		end?: boolean
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	addItems: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		items: Array<TEntity>,
		end: boolean,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
	deleteItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		id: number,
	) => BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;
}

export interface IMappedActionCreators<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> extends ActionCreatorsMapObject<Promise<Array<T>> | Promise<T> | Promise<void> | void> {
	initStorage: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		type: string,
		items?: TEntity[],
		params?: any
	) => void;
	reqPages: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
		type: string,
		params: BaseParams
	) => Promise<Array<TEntity>>;
	removeItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
		type: string,
		item: TEntity,
		newParams?: BaseParams | null,
	) => Promise<Array<TEntity>>;
	refreshPages: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		path: string,
		params?: BaseParams
	) => Promise<void>;
	updateItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		item: Partial<TEntity>,
		paramName?: keyof TEntity,
	) => void;
	updateArrayInItem: <TEntity extends WithId & T[keyof T]>(
		store: keyof TApplicationState,
		compareParam: T[keyof T],
		field: keyof T,
		changedArrayItem: Partial<TEntity>,
		compareParamName: keyof T,
		arrayItemParamName: keyof TEntity,
	) => void;
	updateBatch: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		items: TEntity[],
		paramName?: string,
		sortBy?: string | false,
		clip?: ClipBy,
		insertNew?: boolean,
	) => void;
	loadMoreItems: (
		store: keyof TApplicationState,
		path: string,
	) => void;
	addItem: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		item: TEntity,
		end?: boolean,
	) => void;
	addItems: <TEntity extends WithId = T>(
		store: keyof TApplicationState,
		items: Array<TEntity>,
		end?: boolean,
	) => void;
	deleteItem: (
		store: keyof TApplicationState,
		id: number,
	) => void;
}

export function getActionCreators<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(): IActionCreators<T, TUser, TApplicationState> {
	type ResultType<TEntity extends WithId = T> = BaseAppThunkAction<KnownPageAction<TEntity, TUser, TApplicationState>, TUser, TApplicationState>;

	return {
		initStorage: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			type: string,
			items?: TEntity[],
			params?: any,
		): ResultType<TEntity> => (dispatch) => {
			dispatch({
				items,
				params,
				type: TypeKeys.INITSTORAGE,
				storageName: store,
				objectType: type,
			});
		},
		reqPages: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			path: string,
			type: string,
			params: BaseParams,
		): ResultType<TEntity> => (dispatch, getState) => {
			const storeState = (getState() as TApplicationState)[store] as unknown as ItemsState<TEntity>;

			if (!equal(storeState.params, params) || storeState.type !== type) {
				return loadPage<TEntity, TUser, TApplicationState>(dispatch, getState, store, type, path, params);
			}

			return Promise.resolve(storeState.items);
		},
		removeItem: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			path: string,
			type: string,
			item: TEntity,
			newParams: BaseParams | null = null,
		): ResultType<TEntity> => (dispatch, getState) => {
			(item as WithDeleted).deleted = true;

			const state = (getState() as TApplicationState)[store] as unknown as ItemsState<TEntity>;
			const params = state.params;

			return request<TEntity, TUser, TApplicationState>(type, item, getState())
				.then(() => loadPage<TEntity, TUser, TApplicationState>(dispatch, getState, store, type, path, newParams ? { ...params, ...newParams } : params));
		},
		refreshPages: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			path: string,
			params?: BaseParams,
		): ResultType<TEntity> => (dispatch, getState) => {
			const storeState = (getState() as any)[store];

			return loadPage<TEntity, TUser, TApplicationState>(dispatch, getState, store, storeState.type, path, params || storeState.params);
		},
		updateItem: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			item: Partial<TEntity>,
			paramName: keyof TEntity = 'id',
		): ResultType<TEntity> => (dispatch) => {
			dispatch({
				type: TypeKeys.UPDATEITEM, storageName: store, item, paramName,
			});
		},
		updateArrayInItem: <TEntity extends WithId & T[keyof T]>(
			store: keyof TApplicationState,
			compareParam: T[keyof T],
			field: keyof T,
			changedArrayItem: Partial<TEntity>,
			compareParamName: keyof T = 'id',
			arrayItemParamName: keyof TEntity = 'id',
		): ResultType => (dispatch, getState) => {
			// Same thing as in Item.ts
			const storeState: ItemsState<T> = (getState() as TApplicationState)[store] as unknown as ItemsState<T>;
			const foundItem = storeState.items.find((item: T) => item[compareParamName] === compareParam);

			if (foundItem) {
				const arr = foundItem[field];

				if (Array.isArray(arr)) {
					const updated = updateArrayItem<TEntity>(arr as Array<TEntity>, arrayItemParamName, changedArrayItem);

					dispatch({
						type: TypeKeys.UPDATEITEM,
						storageName: store,
						item: {
							[compareParamName]: compareParam,
							[field]: updated,
						} as Partial<T>,
						paramName: compareParamName,
					});
				}
			}
		},
		/**
		 * UpdateBatch - action to update an array of entities
		 * @param store		{string}			- store to update at
		 * @param items		{items}				- items to be updated
		 * @param paramName	{string}			- field to compare items by
		 * @param sortBy	{string | false}	- whether to sort or not and what field to sort by
		 * @param clip		{ClipBy}			- whether to clip resulting items array to its original size and where to align to
		 * @param insertNew	{boolean}			- whether to insert new items or only to update existing ones
		 */
		updateBatch: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			items: TEntity[],
			paramName: string = 'id',
			sortBy: string | false = false,
			clip: ClipBy = ClipBy.None,
			insertNew: boolean = false,
		): ResultType<TEntity> => (dispatch) => {
			dispatch({
				items,
				paramName,
				sortBy,
				clip,
				insertNew,
				type: TypeKeys.UPDATEBATCH,
				storageName: store,
			});
		},
		loadMoreItems: <TEntity extends WithId = T>(
			store: keyof TApplicationState,
			path: string,
		): ResultType<TEntity> => (dispatch, getState) => {
			const storeState = (getState() as any)[store];

			const params = {
				...storeState.params,
				offset: (storeState.params.offset || 0) + storeState.params.count,
			};

			request<List<TEntity>, TUser, TApplicationState>(path, params, getState()).then((data) => {
				dispatch({
					type: TypeKeys.RECEIVEMOREITEMS, storageName: store, items: data.list, offset: data.offset, total: data.count,
				});
			}).catch(() => dispatch({
				type: TypeKeys.RECEIVEMOREITEMS, storageName: store, items: [], offset: 0, total: 0,
			}));

			dispatch({ type: TypeKeys.REQUESTMOREITEMS, storageName: store, params });
		},
		addItem: <TEntity extends WithId = T>(store: keyof TApplicationState, item: TEntity, end: boolean = false): ResultType<TEntity> => (dispatch) => {
			dispatch({
				type: TypeKeys.ADDITEM, storageName: store, item, end,
			});
		},
		addItems: <TEntity extends WithId = T>(store: keyof TApplicationState, items: Array<TEntity>, end: boolean = false): ResultType<TEntity> =>
			(dispatch) => {
				dispatch({
					type: TypeKeys.ADDITEMS, storageName: store, items, end,
				});
			},
		deleteItem: <TEntity extends WithId = T>(store: keyof TApplicationState, id: number): ResultType<TEntity> => (dispatch) => {
			dispatch({ type: TypeKeys.DELETEITEM, storageName: store, id });
		},
	};
}

export function getReducer<
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
>(storageName: string): Reducer<ItemsState<T>> {
	return (state: ItemsState<T> = defaultItemsState, incomingAction: Action) => {
		const action = incomingAction as KnownPageAction<T, TUser, TApplicationState>;

		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.INITSTORAGE:
					return {
						isLoading: false,
						items: action.items || [],
						params: action.params || {},
						pagination: {
							total: action.total || (action.items && action.items.length) || 0,
							current: 0,
							offset: 0,
							pageSize: action.params?.count || 10,
						},
						type: action.objectType,
					};
				case TypeKeys.REQUESTITEMS:
					return {
						...state, isLoading: true, params: action.params, type: action.objectType,
					};
				case TypeKeys.RECEIVEITEMS:
					return {
						isLoading: false,
						items: action.items,
						params: state.params,
						pagination: {
							total: action.total,
							current: state.params.page,
							offset: action.offset,
							pageSize: state.params.count || 10,
						},
						type: action.objectType,
					};
				case TypeKeys.UPDATEITEM:
					return {
						...state,
						items: state.items.map((item: T) => (item[action.paramName] === action.item[action.paramName] ? { ...item, ...action.item } : item)),
					};
				case TypeKeys.UPDATEBATCH:
					const size = state.items.length;
					let items = state.items
					.map((item) => {
						const uid = action.items.findIndex((elem: T) =>
							elem[action.paramName] === item[action.paramName]);

						if (uid === -1) return item;

						return { ...(item as any), ...(action.items[uid] as any) };
					});

					if (action.insertNew) {
						items = items.concat(action.items.filter((itm: T) =>
						!items.some((storedItem: T) => storedItem[action.paramName] === itm[action.paramName])));
					}

					if (action.sortBy !== false) {
						items = items.sort((a, b) =>
						a[action.sortBy as string] - b[action.sortBy as string]);
					}

					switch (action.clip) {
						case ClipBy.Start:
							items = items.slice(0, size);
							break;

						case ClipBy.End:
							const shift = items.length - size;
							items = items.slice(shift, shift + size);
							break;

						case ClipBy.None:
						default:
							break;
					}

					return {
						...state,
						items,
					};
				case TypeKeys.REQUESTMOREITEMS:
					return { ...state, isLoading: true, params: action.params };
				case TypeKeys.RECEIVEMOREITEMS:
					return {
						...state,
						items: state.items.concat(action.items),
						isLoading: false,
						pagination: {
							total: action.total,
							current: state.params.page,
							offset: action.offset,
							pageSize: state.params.count || 10,
						},
					};
				case TypeKeys.ADDITEM:
					return state.items
					? {
						...state,
						items: action.end ? state.items.concat(action.item) : [action.item].concat(state.items),
					}
					: state;
				case TypeKeys.ADDITEMS:
					return state.items
					? {
						...state,
						items: action.end ? state.items.concat(action.items) : [...action.items].concat(state.items),
					}
					: state;
				case TypeKeys.DELETEITEM:
					return {
						...state,
						pagination: {
							...state.pagination,
							total: state.pagination.total > 0 ? state.pagination.total - 1 : 0,
						},
						items: state.items.filter((item: T) => item.id !== action.id),
					};
			}
		}

		return state;
	};
}
