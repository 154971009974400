import * as React from 'react';

import { ImageComponent } from '@common/react/components/UI/Image/Image';

import noImage from '@images/no_image.jpg';

interface ThumbnailProps {
	id: number;
	thumb: string;
	path: string;

	confirmDelete: (value) => void;
	removeFileUpload: (value) => void;
	openPreview: (path: string) => void;

	style?: React.CSSProperties;
	lineHeight?: number;
	withDeleteBtn?: boolean;
	withConfirm?: boolean;
	fallback?: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = (props: ThumbnailProps) => {
	const {
		removeFileUpload,
		openPreview,
		confirmDelete,
		path,
		thumb,
		id,
		style,
		lineHeight = 142,
		withDeleteBtn,
		withConfirm,
		fallback = noImage,
	} = props;
	const onDelete = React.useCallback(() => (
		withConfirm ? confirmDelete(id) : removeFileUpload(id)), [removeFileUpload, confirmDelete, withConfirm]);
	const onPreview = React.useCallback(() => openPreview(path), [openPreview]);

	return (
		<div className="thumbnail avatar-component__thumb" style={style}>
			<ImageComponent src={thumb} alt="Source image" fallback={fallback} />
			<span className="avatar-component__actions" style={{ lineHeight: `${lineHeight}px` }}>
				<i className="avatar-component__preview fa fa-eye" onClick={onPreview} />
				{withDeleteBtn && (
					<i className="avatar-component__removed fa fa-trash-o" onClick={onDelete} />)}
			</span>
		</div>
	);
};
