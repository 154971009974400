import * as React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import clsx from 'clsx';

import { UserComponent } from '@app/components/UI/Header/UserComponent';
import { ApplicationState } from '@app/store';
import { PanelMenu } from '@app/components/UI/Menu';
import { menuList as menus } from '@app/components/UI/SidePanel/MenuItems';

import '@app/scss/ui/side-panel.scss';

export enum PanelPosition {
	Left = 0,
	Right = 1,
}

interface SidePanelProps {
	position?: PanelPosition;

	panelOpened: boolean;
	togglePanel: (panelOpened?: boolean) => void;
}

const rootId = 'side-panel-overflow';
export const SidePanel: React.FC<SidePanelProps> = (props: SidePanelProps) => {
	const login = useSelector((state: ApplicationState) => state.login);
	const user = login.user;

	const onOverflowClicked = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
		const target = event.target as Element & HTMLElement;

		if (target.id === rootId) {
			props.togglePanel(false);
		}
	}, [props.togglePanel]);

	if (user === null) return null;

	return (
		<CSSTransition
			in={props.panelOpened}
			timeout={500}
			classNames="side-panel-overflow fade-inout fade-inout"
			unmountOnExit
			mountOnEnter
		>
			<div
				id={rootId}
				onClick={onOverflowClicked}
			>
				<div
					className={clsx(
						'side-panel',
						props.position === PanelPosition.Right
							? 'side-panel_right'
							: 'side-panel_left',
					)}
				>
					<UserComponent />
					<div className="side-panel__inner">
						<PanelMenu
							items={menus[user.role]}
							toggle={props.togglePanel}
						/>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};
