import { Store } from 'redux';

import { BaseApplicationState } from '@common/react/store';
import { Nullable } from '@common/typescript/objects/Nullable';
import { MissingStoreError } from '@common/react/errors/MissingStoreError';
import { BaseUser } from '@common/typescript/objects/BaseUser';

export class StoreProvider<TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>> {
	private _store: Nullable<Store<TApplicationState>> = null;

	public get store(): Store<TApplicationState> {
		if (this._store === null) throw new MissingStoreError();

		return this._store;
	}

	public set store(value: Store<TApplicationState>) {
		this._store = value;
	}
}
