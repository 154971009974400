import { Nullable } from '@common/typescript/objects/Nullable';
import { WithName } from '@common/typescript/objects/WithName';
import { WithId } from '@common/typescript/objects/WithId';

import { WithSearch } from '@app/objects/WithSearch';

import { SelectList } from '@app/store/SelectList/SelectList';
import {
	FilterType,
	EntityType,
} from '@app/store/SelectList/UtilityTypes';
import { GroupProps } from '@app/components/UI/Inputs/CoreSelect';
import { ServiceType } from '@app/objects/Pet';
import { PriceKind, PriceType } from '@app/objects/Price';
import { ItemCategory } from '@app/objects/Inventory';
import { SpecialServiceType } from '@app/objects/SpecialService';

/**
	WithName is used to filter by name (used on list pages)
	WithSearch is used to filter by name and sort according to priority
		(words starting with WithSearch.search have greater priority)
 */
export type Searchable = Partial<WithName> & WithSearch;

export type CrematorySelectFilter = Searchable;
export type StatusSelectFilter = Searchable;
export type AdjustmentCodeFilter = Searchable;
export type MeasuringUnitFilter = Searchable;
export type MachineSelectFilter = Searchable;
export type StoreEntrySelectFilter = Searchable;

export interface ClinicSelectFilter extends Searchable {
	isCustomer?: Nullable<boolean>;
	crematories?: Nullable<Array<number>>;
}

export interface PetBreedSelectFilter extends Searchable {
	petSpecie: Array<number>;
}

export interface UrnSelectFilter extends Searchable {
	parentId?: Array<Nullable<number>>;
}

export interface SpecialServicesSelectFilter extends Searchable {
	specialServiceType?: Array<SpecialServiceType>;
	priceType?: PriceType;
	serviceType?: ServiceType;
	availableForCrematory?: boolean;
	availableForClinic?: number;
}
export interface PickupServicesSelectFilter extends Searchable {
	priceType?: PriceType;
	serviceType?: ServiceType;
	clinicId?: Nullable<number>;
	availableForPricing?: boolean;
}

export interface UrnCategoriesSelectFilter extends Searchable {
	crematoryId?: number;
	clinicId: Nullable<number>;
	serviceType: ServiceType;
	priceType: PriceType;
}

export interface RegionSelectFilter extends Searchable {
	country: string;
}

export interface ProductSelectFilter extends Searchable {
	crematoryId?: number;
	availableForCrematory?: boolean;
	availableForClinic?: Nullable<number>;
	serviceType?: ServiceType;
	priceType?: PriceType;
	category?: Array<ItemCategory>,
	parentId?: Array<Nullable<number>>;
}

export interface InventoryItemFilter extends Searchable {
	crematoryId: number;
	category: Array<ItemCategory>;
}

export interface InventoryParentsSelectFilter extends Searchable {
	category?: Array<ItemCategory>;
}

export interface DiscountSelectFilter extends Searchable {
	priceKind: Array<PriceKind>;
}

export interface CountrySelectFilter {
	name?: string;
	search: string;
}

export interface LanguageSelectFilter {
	name?: string;
	search: string;
}

export interface UserSelectFilter {
	name?: string;
	search: string;
}

export interface RouteSelectFilter {
	name?: string;
	search: string;
}

export interface LabelPaperSelectFilter {
	name?: string;
	search: string;
	template: boolean;
}

export type FilterComparator<TStore extends keyof SelectList> = (a: Nullable<FilterType<TStore>>, b: Nullable<FilterType<TStore>>) => boolean

export interface SelectRequestProps<TStore extends keyof SelectList> {
	endpoint: string;

	defaultFilters: FilterType<TStore>;
	isEqual: FilterComparator<TStore>;
	isEmpty: (a: Nullable<FilterType<TStore>>) => boolean;
	isValid?: (a: Nullable<FilterType<TStore>>) => boolean;

	group?: (items: Array<EntityType<TStore>>) => Array<GroupProps<EntityType<TStore>>>;
	order?: (items: Array<EntityType<TStore>>) => Array<EntityType<TStore>>;
}

export function isEmpty<TFilters extends Searchable>(filter: Nullable<TFilters>): boolean {
	return !filter?.name && !filter?.search;
}

export function areEqual(a: Array<number>, b: Array<number>): boolean {
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; i++) {
		if (!b.includes(a[i])) return false;
	}

	return true;
}

export function isEqualClinic(a: Nullable<ClinicSelectFilter>, b: Nullable<ClinicSelectFilter>): boolean {
	return a?.search === b?.search
		&& a?.name === b?.name
		&& areEqual(a?.crematories ?? [], b?.crematories ?? [])
		&& a?.isCustomer === b?.isCustomer;
}

export function isEqualCrematory(a: Nullable<CrematorySelectFilter>, b: Nullable<CrematorySelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqual(a: Nullable<StatusSelectFilter>, b: Nullable<StatusSelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualPetBreeds(a: Nullable<PetBreedSelectFilter>, b: Nullable<PetBreedSelectFilter>): boolean {
	return a?.search === b?.search && a?.petSpecie === b?.petSpecie;
}

export function isEqualUrn(a: Nullable<UrnSelectFilter>, b: Nullable<UrnSelectFilter>): boolean {
	return a?.search === b?.search && a?.parentId === b?.parentId;
}

export function isEqualUrnCategory(a: Nullable<UrnCategoriesSelectFilter>, b: Nullable<UrnCategoriesSelectFilter>): boolean {
	return a?.search === b?.search
		&& a?.crematoryId === b?.crematoryId
		&& a?.clinicId === b?.clinicId
		&& a?.serviceType === b?.serviceType
		&& a?.priceType === b?.priceType;
}

export function isEqualInventoryParentsCategory(a: Nullable<InventoryParentsSelectFilter>, b: Nullable<InventoryParentsSelectFilter>): boolean {
	return a?.search === b?.search && areEqual(a?.category ?? [], b?.category ?? []);
}

export function isEqualRegions(a: Nullable<RegionSelectFilter>, b: Nullable<RegionSelectFilter>): boolean {
	return a?.search === b?.search && a?.country === b?.country;
}

export function isEqualProduct(a: Nullable<ProductSelectFilter>, b: Nullable<ProductSelectFilter>): boolean {
	return (
		a?.search === b?.search
		&& a?.availableForClinic === b?.availableForClinic
		&& a?.availableForCrematory === b?.availableForCrematory
		&& a?.crematoryId === b?.crematoryId
		&& a?.priceType === b?.priceType
		&& a?.serviceType === b?.serviceType
		&& areEqual(a?.category ?? [], b?.category ?? [])
	);
}

export function isEqualSpecialServices(a: Nullable<SpecialServicesSelectFilter>, b: Nullable<SpecialServicesSelectFilter>): boolean {
	return a?.search === b?.search
	&& a?.priceType === b?.priceType
	&& a?.serviceType === b?.serviceType
	&& a?.availableForClinic === b?.availableForClinic
	&& a?.availableForCrematory === b?.availableForCrematory
	&& areEqual(a?.specialServiceType ?? [], b?.specialServiceType ?? []);
}

export function isEqualPickupServices(a: Nullable<PickupServicesSelectFilter>, b: Nullable<PickupServicesSelectFilter>): boolean {
	return a?.search === b?.search
	&& a?.priceType === b?.priceType
	&& a?.serviceType === b?.serviceType
	&& a?.availableForPricing === b?.availableForPricing
	&& a?.clinicId === b?.clinicId;
}

export function isEqualInventoryItem(a: Nullable<InventoryItemFilter>, b: Nullable<InventoryItemFilter>): boolean {
	return a?.search === b?.search 	&& a?.crematoryId === b?.crematoryId && areEqual(a?.category ?? [], b?.category ?? []);
}

export function isEqualAdjustmentCode(a: Nullable<AdjustmentCodeFilter>, b: Nullable<AdjustmentCodeFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualDiscount(a: Nullable<DiscountSelectFilter>, b: Nullable<DiscountSelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualMeasuringUnit(a: Nullable<MeasuringUnitFilter>, b: Nullable<MeasuringUnitFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualCountry(a: Nullable<CountrySelectFilter>, b: Nullable<CountrySelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualLanguage(a: Nullable<LanguageSelectFilter>, b: Nullable<LanguageSelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualUser(a: Nullable<UserSelectFilter>, b: Nullable<UserSelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEqualRoute(a: Nullable<RouteSelectFilter>, b: Nullable<RouteSelectFilter>): boolean {
	return a?.search === b?.search;
}

export function isEmptyValue<T>(value: Array<T> | T | undefined | null): boolean {
	if (value === null || value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;
	if (typeof value === 'string') return value === '';
	if (typeof value === 'number') return value < 0;

	return false;
}

export function defaultOrder<T extends Partial<WithName>>(item: Array<T>): Array<T> {
	return item.sort((a: Partial<WithName>, b: Partial<WithName>) => {
		if (a.name && b.name) {
			return a.name > b.name ? 1 : -1;
		}

		if (!a.name && b.name) return -1;
		if (a.name && !b.name) return 1;

		return 0;
	});
}

export function isPickDefault<T extends WithId>(items: Array<T>): number {
	const result = items[0];

	return result.id;
}

// default filters

export const defaultFilters = {
	search: '',
};

export const defaultClinicFilters = {
	search: '',
	crematories: [],
};

export const defaultPetBreedsFilters = {
	search: '',
	petSpecie: [],
};

export const defaultUrnsFilters = {
	search: '',
	parentId: [null],
};

export const defaultUrnCategoriesFilters = {
	search: '',
	crematoryId: -1,
	clinicId: -1,
	serviceType: ServiceType.All,
	priceType: PriceType.All,
};

export const defaultRegionsFilters = {
	search: '',
	country: '',
};

export const defaultProductsFilters = {
	search: '',
	crematoryId: -1,
	availableForCrematory: false,
	availableForClinic: null,
	serviceType: ServiceType.All,
	priceType: PriceType.All,
	category: [],
	parentId: [null],
};

export const defaultInventoryItemsFilters = {
	search: '',
	crematoryId: -1,
	category: [],
};

export const defaultDiscountsFilters = {
	search: '',
	priceKind: [PriceKind.Discount],
};

export const defaultInventoryParentsFilters = {
	search: '',
	crematoryId: -1,
	parentId: [],
};

export const defaultLabelPapersFilters = {
	search: '',
	template: false,
};
