import * as React from 'react';

import Modal from 'antd/lib/modal';
import Icon from 'antd/lib/icon';
import { TFunction } from 'react-i18next';

interface DeleteConfirmation {
	modalTitle?: string;
	title: string;
	callback: () => Promise<unknown> | undefined;
	t?: TFunction;
}

export const deleteConfirmation = ({
	title, modalTitle, callback, t,
}: DeleteConfirmation): void => {
	let label = `${modalTitle} ${title}`;

	if (!modalTitle) label = !t ? `Delete ${title}?` : `${t('table.actions.delete')} ${title}?`;

	Modal.confirm({
		title: label,
		okText: !t ? 'Delete' : t('table.actions.delete'),
		okType: 'danger',
		icon: <Icon type="exclamation-circle" />,
		cancelText: !t ? 'Cancel' : t('buttons.cancel'),
		onOk() {
			return callback();
		},
	});
};
