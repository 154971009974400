import * as React from 'react';

import { Optional } from '@common/typescript/objects/Nullable';

type InputProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' |'onChange' | 'name'>
interface NumberFieldProps extends InputProps {
	onChange: (value: number) => void;
	value: Optional<number>;
	name: string;
}

function getMin(value: number | string = 0): number {
	if (typeof value === 'number') return value;

	const num = Number.parseFloat(value);
	if (Number.isNaN(num)) return 0;

	return num;
}

function isNumberFormat(value: string): boolean {
	return /^[-+]?\d*\.?\d*$/.test(value);
}

export const NumberField: React.FC<NumberFieldProps> = (props: NumberFieldProps) => {
	const [stateValue, setStateValue] = React.useState<string>(props.value?.toString() ?? '-');

	React.useEffect(() => setStateValue(props.value?.toString() ?? '-'), [props.value]);

	return (
		<input
			type="text"
			className="form-control"
			{...props}
			name={props.name}
			value={stateValue}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
				const currentValue = event.currentTarget.value.replace(',', '.');
				if (!isNumberFormat(currentValue)) return;

				const value = Number.parseFloat(currentValue);

				if (value < Number(props.min)) return;
				if (value > Number(props.max)) return;

				setStateValue(currentValue);
				if (!Number.isNaN(value)) {
					props.onChange(value);
				}
			}}
			onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
				if (stateValue === '') {
					const value = getMin(props.min);

					setStateValue(value.toString());
					props.onChange(value);
				}
				props.onBlur?.(event);
			}}
		/>
	);
};
