import React from 'react';

import { FilePreview } from '@common/react/components/Forms/Upload/FilePreview';
import { UploadFile } from '@common/react/components/Forms/Upload/UploadFile';
import { UploadFilePlaceholder } from '@common/react/components/Forms/Upload/UploadFilePlaceholder';
import { OnProgress, UploadList } from '@common/react/components/Forms/Upload/UploadList';
import { UploadProgress } from '@common/react/components/Forms/Upload/UploadProgress';
import { FileInterface } from '@common/typescript/objects/FileInterface';
import { Nullable } from '@common/typescript/objects/Nullable';

import { PetFile } from '@app/objects/PetFile';

interface UploadDocumentsProps {
	petId: number;
	items: Array<PetFile>;

	onChange: (value: Array<PetFile>) => void;
	onChangeFile: (value: Nullable<FileInterface>) => void;

	maxSizeCaption?: string;
	type?: string;
}

function isPresent<T>(item: Nullable<T>): item is T {
	return item !== null;
}

function getList(list: Array<PetFile>): Array<PetFile> {
	return list.filter((item: PetFile) => !item.deleted);
}

function remove(list: Array<PetFile>, id: number): Array<PetFile> {
	return list
		.map((item: PetFile) => {
			if (item.id !== id) return item;

			if (id <= 0) return null;

			return {
				...item,
				deleted: true,
			};
		})
		.filter(isPresent);
}

function update(list: Array<PetFile>, id: number, value: string): Array<PetFile> {
	return list
		.map((item: PetFile) => {
			if (item.id !== id) return item;

			return {
				...item,
				text: value,
			};
		});
}

let series = 0;
function getNewFile(file: FileInterface, petId: number): PetFile {
	return {
		id: --series,

		file,
		fileId: file.id,

		objectId: petId,
		object: undefined,
		text: '',
	};
}

export const UploadDocuments: React.FC<UploadDocumentsProps> = (props: UploadDocumentsProps) => {
	const {
		items,
		petId,
		onChange,
		onChangeFile,
	} = props;

	return (
		<UploadList
			list={getList(items)}
			renderItem={(item: PetFile) => (
				<div style={{ width: '256px' }}>
					<FilePreview
						file={item.file}
						onPreview={() => onChangeFile(item.file)}
						onDelete={() => onChange(remove(items, item.id))}
						download
					/>
					<textarea
						placeholder="Notes about file"
						className="form-control"
						style={{ marginTop: '8px' }}
						value={item.text}
						onChange={(event) => onChange(update(items, item.id, event.currentTarget.value))}
					/>
				</div>
			)}
			renderProgress={(progress: number) => (
				<UploadProgress
					progress={progress}
					activeBarColor="#508aeb"
				/>
			)}
			renderUpload={(onProgress: OnProgress) => (
				<UploadFile
					type={props.type}
					onUpload={(file: FileInterface) => onChange([...items, getNewFile(file, petId)])}
					onProgress={onProgress}
				>
					<UploadFilePlaceholder
						text="+ Add File"
						caption={props.maxSizeCaption}
					/>
				</UploadFile>
			)}
		/>
	);
};

UploadDocuments.defaultProps = {
	maxSizeCaption: 'File should not exceed 4 mb',
};
