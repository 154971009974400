import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ApplicationState } from '@app/store';
import { defaultLanguageCode } from '@app/internationalization/init';
import { RoleManager } from '@app/services/RoleManager';
import { useCrematory } from '@app/hooks/useCrematory';

export const InternationalizationController: React.FC<React.PropsWithChildren<unknown>> = (props: React.PropsWithChildren<unknown>) => {
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory();
	const language = user && RoleManager.for(user.role).isAdmin() ? user?.localeUserPreferences?.language?.code : crematory?.language;
	const { i18n, ready } = useTranslation('translation', { useSuspense: false });

	React.useEffect(() => {
		if (ready) {
			const code = language ?? defaultLanguageCode;
			i18n.changeLanguage(code);
		}
	}, [language, ready]);

	return <div>{props.children}</div>;
};
