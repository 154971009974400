import React from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import Table from 'antd/lib/table';
import Search from 'antd/lib/input/Search';
import { useFormikContext } from 'formik';

import { List } from '@common/typescript/objects/List';
import { isPresent, Nullable, Optional } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { ImageComponent } from '@common/react/components/UI/Image/Image';
import { AlertMessage, AlertType } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';
import { useRequest } from '@app/hooks/useRequest';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { ModalControls } from '@app/hooks/Editors/useModal';
import {
	AvailableStoreEntry,
	getPath,
	getRoot,
	StoreEntry,
	StoreEntryAttribute,
	StoreEntryAttributeValue,
	StoreEntryNode,
	toTree,
} from '@app/objects/StoreEntry';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import {
	sorterPrices,
	StoreEntriesItemList,
} from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModalComponents/StoreEntriesItemList';
import {
	ItemField,
} from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModalComponents/ItemField';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { PreselectStore } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProcessSection';
import {
	sanitizeTreeLinks,
} from '@app/components/Pages/Inventory/StoreEntries/StoreEntryEditor/StockPreview/TreeUtils';
import { calculate } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/calculations';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';
import { RoleManager } from '@app/services/RoleManager';

import noImage from '@images/no_image.jpg';

import '@app/scss/pages/pet-editor.scss';

interface StoreEntriesModalProps {
	modalControl: ModalControls;
	preselect?: PreselectStore;
	onChange: (items: Array<PetPrice>) => void;
	crematoryId?: number;
	clinicId?: number;
	serviceType: ServiceType;
	priceType?: PriceType;
}

export interface ModalFormValues extends AvailableStoreEntry {
	count: number;
	cost: Nullable<number>;
	values: Array<StoreEntryAttributeValue>;
	clientId?: string;
}

interface RequestParams {
	crematoryId?: number;
	clinicId?: number;
	priceType?: PriceType;
	serviceType: ServiceType;
	filters: {
		name: string,
	};
}

export const newStoreEntry = (item: AvailableStoreEntry): ModalFormValues => ({
	...item,
	count: 1,
	values: [],
	cost: sorterPrices(item.prices).to,
});

const getColumns = (
	entry: Nullable<StoreEntry>,
	selection: Nullable<StoreEntryNode>,
	setSelection: (value: StoreEntryNode) => void,
	role?: UserRole,
) => TableBuilder.shape<StoreEntryAttribute>()
	.addColumn({
		title: 'Attribute',
		dataIndex: 'name',
		render: (name: string, record: StoreEntryAttribute) => (
			<ControlLabel text={name} required={!RoleManager.for(role ?? UserRole.Crematory).isClinicGroup() && record.clinicCanPick} />
		),
	})
	.addColumn({
		title: 'Values',
		dataIndex: 'values',
		width: '50%',
		render: (values: Array<StoreEntryAttributeValue>) => {
			if (!entry) return null;

			const options = values.sort((a, b) => b.order - a.order).map((i) => ({ text: i.name, value: i.id }));

			let value;
			if (selection) {
				const path = getPath(entry, selection).map((node: StoreEntryNode) => node.valueId);
				value = options.find((option) => path.includes(option.value))?.value;
			}

			return (
				<form style={{ marginLeft: '-15px' }}>
					<LocalSelect
						value={value}
						onChange={(id: number) => {
							if (!entry) return;

							const node = selection ?? getRoot(entry);
							if (!node) return;

							const candidates = entry.tree.filter((item: StoreEntryNode) => item.valueId === id);
							if (!candidates.length) return;

							/* Added a new value */
							const result = candidates.find((item: StoreEntryNode) => item.parentId === node.id);
							if (result) {
								setSelection(result);

								return;
							}

							/* Changed existing value */
							const path = getPath(entry, node);
							const options = candidates.filter((c: StoreEntryNode) => path.some((n: StoreEntryNode) => n.id === c.parentId));
							if (options.length !== 1) return;

							setSelection(options[0]);
						}}
						options={options}
						deselectType=""
						filterName=""
						fieldName=""
						allowClear={false}
						className="col-sm-12"
					/>
				</form>
			);
		},
	})
	.build();

export const getNewPetPrice = (item: ModalFormValues, petId: number): PetPrice => {
	return {
		id: -1,
		clientId: v4(),
		removed: false,

		petId,
		pet: null,

		priceId: 0,
		price: null,

		count: item.count,
		done: false,
		completedCount: 0,

		value: item.cost ?? 0,
		extra: 0,
		name: item.storeEntry.name,

		editor: null,
		editorId: null,

		pickupService: null,
		pickupServiceId: null,

		batchCount: 0,
		batchPrice: 0,

		node: null,
		nodeId: null,

		note: '',
	};
};

const onModalOk = (
	petId: number,
	values: Array<PetPrice>,
	setValue: (value: Array<PetPrice>) => void,
	onClose: () => void,
	storeEntry: Nullable<ModalFormValues>,
	selection: Nullable<StoreEntryNode>,
) => {
	if (!storeEntry) return;
	if (!selection) return;

	const updatedStore = values.find((i) => i.clientId === storeEntry.clientId);

	if (updatedStore) {
		const updatedValues: Array<PetPrice> = values.map((i: PetPrice) => {
			if (i.clientId === storeEntry.clientId) {
				return {
					...i,

					node: null,
					entry: sanitizeTreeLinks(storeEntry.storeEntry),
					nodeId: selection.id,
					count: storeEntry.count,
					value: storeEntry.cost ?? 0,
				};
			}

			return i;
		});

		setValue(updatedValues);
	} else {
		const newProductPetPrice = getNewPetPrice(storeEntry, petId);
		newProductPetPrice.entry = sanitizeTreeLinks(storeEntry.storeEntry);
		newProductPetPrice.nodeId = selection.id;

		setValue([newProductPetPrice, ...values]);
	}

	onClose();
};

function isClinic(role: Optional<UserRole>): role is UserRole.ClinicManager | UserRole.ClinicUser {
	return role === UserRole.ClinicManager || role === UserRole.ClinicUser;
}

export function getData(entry: Nullable<StoreEntry>, selection: Nullable<StoreEntryNode>, role: Optional<UserRole>): Array<StoreEntryAttribute> {
	if (!entry) return [];

	let query = entry.attributes;
	const tree = toTree(entry);

	if (isClinic(role)) {
		query = query.filter((item: StoreEntryAttribute) => item.clinicCanPick);
	}

	if (tree && selection) {
		const values: Array<number> = [];
		const path = getPath(entry, selection);
		if (!path.length) return query;

		for (let i = 0; i < path.length; i++) {
			const children = path[i].children
				.filter((node: StoreEntryNode) => !node.disabled)
				.map((node: StoreEntryNode) => node.valueId)
				.filter(isPresent);
			values.push(...children);
		}

		query = query
			.map((attribute: StoreEntryAttribute): StoreEntryAttribute => ({
				...attribute,
				values: attribute.values.filter((value: StoreEntryAttributeValue) => values.includes(value.id)),
			}))
			.filter((value: StoreEntryAttribute) => value.values.length);
	} else {
		if (!entry.attributes.length) return query;
		if (!tree) return query;

		const values = tree.children
			.filter((node: StoreEntryNode) => !node.disabled)
			.map((node: StoreEntryNode) => node.valueId)
			.filter(isPresent);

		query = query
			.map((attribute: StoreEntryAttribute): StoreEntryAttribute => ({
				...attribute,
				values: attribute.values.filter((value: StoreEntryAttributeValue) => values.includes(value.id)),
			}))
			.filter((value: StoreEntryAttribute) => value.values.length);
	}

	return query;
}

export function allowAdd(entry: Optional<StoreEntry>, selection: Optional<number>): boolean {
	if (!entry) return false;
	if (!selection) return false;

	const whitelist = entry.attributes
		.filter((attribute: StoreEntryAttribute) => attribute.clinicCanPick)
		.flatMap((attribute: StoreEntryAttribute) => attribute.values)
		.map((value: StoreEntryAttributeValue) => value.id);

	const query = entry.tree
		.filter((node: StoreEntryNode) => node.parentId === selection)
		.filter((node: StoreEntryNode) => !node.disabled)
		.map((node: StoreEntryNode) => node.valueId)
		.filter((id: Nullable<number>) => id === null || whitelist.includes(id));

	return !query.length;
}

function getImage(entry: Optional<StoreEntry>, selection: Optional<StoreEntryNode>): string {
	if (!entry) return noImage;
	if (!selection) return noImage;

	const path = getPath(entry, selection).reverse();
	for (let i = 0; i < path.length; i++) {
		if (path[i].avatar) return path[i].avatar;
	}

	return noImage;
}

function getPriceWarning(entry: Nullable<ModalFormValues>, selection: Nullable<StoreEntryNode>): Nullable<string> {
	if (!selection) return null;
	if (!entry) return null;
	if (isPresent(entry.cost)) return null;

	return 'Price for selected set of parameters doesn\'t exist';
}

export const StoreEntriesUrnModal: React.FC<StoreEntriesModalProps> = (props: StoreEntriesModalProps) => {
	const { modalControl, preselect } = props;
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const { values } = useFormikContext<PetFormValues>();

	const [query, setQuery] = React.useState<string>('');
	const [offset, setOffset] = React.useState<number>(0);

	const [storeEntry, setStoreEntry] = React.useState<Nullable<ModalFormValues>>(null);
	const [selection, setSelection] = React.useState<Nullable<StoreEntryNode>>(null);
	const [list, setList] = React.useState<Array<AvailableStoreEntry>>([]);
	const shouldPickDefault = React.useRef<boolean>(true);

	const reqParams = React.useMemo(() => ({
		crematoryId: props.crematoryId,
		clinicId: props.clinicId,
		priceType: props.priceType,
		serviceType: props.serviceType,
		filters: {
			name: query,
		},
		offset,
	}), [props.crematoryId, props.clinicId, props.serviceType, props.priceType, offset, query]);

	const listRequest = useRequest<List<AvailableStoreEntry>, RequestParams>('availableStoreEntryList', undefined, { requestOnMount: false });
	const storeEntryRequest = useRequest<StoreEntry, { storeEntryId: number }>('getStoreEntry', undefined, { requestOnMount: false });

	const onLoadAttributes = (item: ModalFormValues) => {
		if (storeEntry?.id === item.id) return;

		storeEntryRequest.reload({ storeEntryId: item.id })
			.then((res) => {
				const values: Array<StoreEntryAttributeValue> = (res.attributes
					.map((i) => {
						const value = i.values.find((q) => preselect?.valuesId.includes(q.id));
						if (value) return { ...value, attributeId: i.id, attribute: i };

						return null;
					})
					.filter((w) => w) as Array<StoreEntryAttributeValue>)
					.sort((a, b) => a.order - b.order);

				const root = res.tree.find((i: StoreEntryNode) => preselect?.valuesId.includes(i.id) || preselect?.valuesId.includes(i.valueId ?? -1)) ?? getRoot(res);

				setSelection(root);
				setStoreEntry({
					id: res.id,

					avatar: item.avatar,
					originalAvatar: item.originalAvatar,

					storeEntry: res,
					prices: item.prices,

					cost: item.cost,
					clientId: preselect?.clientId,
					values: preselect ? values : [],
					count: preselect ? preselect.count : 1,
				});
			});
	};

	React.useEffect(() => {
		if (!modalControl.state) return;

		listRequest.reload(reqParams)
			.then((res) => {
				if (shouldPickDefault.current && (res.list.length === 1 || preselect)) {
					const store = preselect ? res.list.find((i) => i.id === preselect.storeId) : res.list[0];

					onLoadAttributes(newStoreEntry(store));
				}
			});
	}, [props.modalControl.state, reqParams, preselect, shouldPickDefault]);

	React.useEffect(() => {
		if (!modalControl.state) return;

		if (query !== '') {
			setList(listRequest.item?.list ?? []);
		} else {
			const data = new Set([...list, ...listRequest.item?.list ?? []]);
			setList(Array.from(data));
		}
	}, [listRequest.item?.list]);

	React.useEffect(() => {
		setList([]);
		setStoreEntry(null);
		setSelection(null);
		setQuery('');
		shouldPickDefault.current = true;
		setOffset(0);
	}, [modalControl.state]);

	React.useEffect(() => {
		setStoreEntry((value: Nullable<ModalFormValues>) => {
			if (value === null) return null;

			const price = calculate({
				entry: value.storeEntry,
				prices: value.prices,
				selection,
				count: value.count,
			});

			return {
				...value,
				cost: price?.value ?? null,
			};
		});
	}, [selection]);

	return (
		<ModalWrapper
			title="Add Store Entry"
			isOpened={modalControl.state}
			onOk={() => onModalOk(values.id, values.urns, props.onChange, modalControl.close, storeEntry, selection)}
			onCancel={modalControl.close}
			width="70%"
			modalClassName="pet-editor__store-entry__modal"
			okText={preselect ? 'Save' : 'Add'}
			okButtonProps={{
				disabled: !allowAdd(storeEntry?.storeEntry, selection?.id) || !isPresent(storeEntry?.cost),
			}}
		>
			<div className="pet-editor__store-entry__modal-container">
				<div className="pet-editor__store-entry__search">
					<Search
						allowClear
						onChange={(event) => {
							if (event.target.value === '' && event.type === 'click' && setQuery) {
								setQuery('');
							}
						}}
						placeholder="Enter a name"
						onSearch={(e: string) => {
							setQuery(e);
							setOffset(0);
							shouldPickDefault.current = false;
						}}
						style={{ padding: '0 0 30px' }}
						enterButton={<i className="fa fa-search" />}
					/>
				</div>
				<StoreEntriesItemList
					onLoadMore={() => setOffset(list.length ?? 0)}
					onSelectStore={onLoadAttributes}
					storeEntry={storeEntry}
					loading={listRequest.loading}
					total={listRequest.item?.count ?? 0}
					list={list}
				/>
				<ImageComponent
					src={getImage(storeEntry?.storeEntry, selection)}
					fallback={noImage}
					className="pet-editor__store-entry__image-wrapper"
					width="256px"
					height="256px"
				/>
				<div className="pet-editor__store-entry__values-fields">
					<Table
						columns={getColumns(storeEntry?.storeEntry ?? null, selection, setSelection, role)}
						dataSource={getData(storeEntryRequest.item, selection, role)}
						pagination={{ hideOnSinglePage: true }}
						childrenColumnName="child"
						loading={storeEntryRequest.loading}
						rowKey="id"
						className="small-padding-table"
					/>
				</div>

				<div className="pet-editor__store-entry__fields">
					<AlertMessage
						type={AlertType.Warning}
						message={getPriceWarning(storeEntry, selection)}
						style={{ whiteSpace: 'pre-line' }}
					/>
					<ItemField
						title="Count"
						fieldName="count"
						storeEntry={storeEntry}
						value={storeEntry?.count ?? 1}
						onChange={(value: number) => {
							if (!storeEntry) return;

							const price = calculate({
								entry: storeEntry.storeEntry,
								selection,
								prices: storeEntry.prices,
								count: value,
							});

							setStoreEntry({
								...storeEntry,
								count: value,
								cost: price?.value ?? null,
							});
						}}
					/>
					<ItemField
						title="Cost"
						fieldName="cost"
						storeEntry={storeEntry}
						value={storeEntry?.cost}
						onChange={(value: number) => {
							if (!storeEntry) return;

							setStoreEntry({
								...storeEntry,
								cost: value,
							});
						}}
						disabled={isClinic(role) || !isPresent(storeEntry?.cost)}
					/>
				</div>
			</div>
		</ModalWrapper>
	);
};
