import { WithId } from '@common/typescript/objects/WithId';
import { pureEnum } from '@common/typescript/utils/enums';
import { Nullable, Optional } from '@common/typescript/objects/Nullable';

import { Crematory } from '@app/objects/Crematory';
import { MeasuringUnit } from '@app/objects/MeasuringUnits';
import { toWords } from '@app/components/Utils/Utils';
import { StoreEntryNode, StoreEntryVariant } from '@app/objects/StoreEntry';

export enum ItemCategory {
	Chemical = 0,
	Urn = 1,
	UrnCategory = 2,
	Product = 3,
	ProductCategory = 4,
}

/**
 * ItemGroup - a list of ItemCategory that could have children
 * @type {ItemCategory.UrnCategory[]}
 */
const group: Array<ItemCategory> = [
	ItemCategory.UrnCategory,
	ItemCategory.ProductCategory,
];

export function isGroup(category?: Nullable<ItemCategory>): boolean {
	if (category === null || category === undefined) return false;

	return group.includes(category);
}

pureEnum(ItemCategory);

const CategoryColor: Record<ItemCategory, string> = {
	[ItemCategory.Chemical]: 'lime',
	[ItemCategory.Urn]: 'geekblue',
	[ItemCategory.UrnCategory]: 'purple',
	[ItemCategory.Product]: 'cyan',
	[ItemCategory.ProductCategory]: 'green',
};

export function getName(category: ItemCategory): string {
	return toWords(ItemCategory[category]);
}

export function getColor(item: InventoryItem): string {
	const category = item.category;
	if (category === null || category === undefined) return '';

	return CategoryColor[category];
}

export interface InventoryItem extends WithId {
	// Name of current item
	name: string;
	// Full name - including all ancestor names (full path)
	fullName: string;
	description: string;

	crematory: Nullable<Crematory>;

	measuringUnit: Nullable<MeasuringUnit>;
	measuringUnitId: number;

	quantity: number | undefined;
	lowerLimit: number | undefined;
	category?: Nullable<ItemCategory>;

	avatar: string;
	originalAvatar: string;

	isDefault: boolean;
	isSpecial: boolean;

	parent: Nullable<InventoryItem>;
	parentId: Nullable<number>;

	storeNode: Nullable<StoreEntryNode>;
	storeEntryVariants: Array<StoreEntryVariant>;
}

export interface PurchaseOrderItem extends WithId {
	orderId: number;
	purchaseOrder: PurchaseOrder | null;

	inventoryItemId: number;
	inventoryItem: InventoryItem | null;

	quantity: number;
	price: number;
	total: number;
}

export interface PurchaseOrder extends WithId {
	orderNumber: string;

	crematoryId: number;
	dateCreated: Date;
	description: string;
	total: number;
}

export const defaultItem: InventoryItem = {
	id: -1,

	avatar: '',
	originalAvatar: '',

	category: ItemCategory.Product,
	crematory: null,

	name: '',
	fullName: '',
	description: '',

	isDefault: false,
	isSpecial: false,

	quantity: 0,
	lowerLimit: 0,
	measuringUnit: null,
	measuringUnitId: -1,

	parent: null,
	parentId: null,

	storeNode: null,
	storeEntryVariants: [],
};

export function getDefaultItem(parent: Optional<InventoryItem>): InventoryItem {
	const item = { ...defaultItem };

	item.parentId = parent?.id ?? null;
	item.parent = parent ?? null;

	item.measuringUnitId = parent?.measuringUnitId ?? -1;

	return item;
}

export function onChangeCategoryFilter(value: Array<number>) {
	const ids: Array<number> = value;

	if (ids.includes(ItemCategory.Product)) ids.push(ItemCategory.ProductCategory);
	if (ids.includes(ItemCategory.Urn)) ids.push(ItemCategory.UrnCategory);

	return ids;
}

export function onFilterValue(values?: Array<number>) {
	return values?.filter((i) => i !== ItemCategory.ProductCategory && i !== ItemCategory.UrnCategory);
}

export function formatName(item: Optional<InventoryItem>): string {
	if (!item) return '-';

	return item.category === ItemCategory.Urn ? item.fullName : item.name;
}
