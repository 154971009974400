import React from 'react';

import Modal from 'antd/lib/modal/Modal';

import { ImageComponent } from '@common/react/components/UI/Image/Image';
import { Nullable } from '@common/typescript/objects/Nullable';
import { FileInterface } from '@common/typescript/objects/FileInterface';

interface ModalPreviewProps {
	file: Nullable<FileInterface>;
	onClose: () => void;
	fallback?: string;
}

export const ModalPreview: React.FC<ModalPreviewProps> = ({ file, onClose, fallback }: ModalPreviewProps) => (
	<Modal
		visible={Boolean(file)}
		footer={null}
		onCancel={() => onClose()}
		centered
	>
		<ImageComponent
			src={file?.src ?? ''}
			alt={file?.name}
			width="100%"
			fallback={fallback}
		/>
	</Modal>
);

ModalPreview.defaultProps = {
	fallback: require('@images/no_image.jpg'),
};
