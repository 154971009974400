import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import { DiffAction, DiffChange, PetPriceDiffs } from '@app/objects/History';
import { PetPrice } from '@app/objects/Pet';
import { PriceKind } from '@app/objects/Price';
import { renderChanges } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetHistoryChange';
import { getOrderSuffix } from '@app/utilities/naturalLanguage';

interface PetPriceDiffListProps {
	list: Nullable<Array<PetPriceDiffs>>;
}

export function getName(item: PetPrice): string {
	const type = item.price?.priceKind;
	let name = item.name;

	if (type === PriceKind.Engraving) {
		const order = (item.price?.order ?? 0) + 1;

		name = `Engraving ${order}${getOrderSuffix(order)} line`;
	}

	return name;
}

interface PetPriceChangesProps {
	item: PetPriceDiffs;
}

const PetPriceChanges: React.FC<PetPriceChangesProps> = (props: PetPriceChangesProps) => {
	switch (props.item.diffAction) {
	case DiffAction.Updated:
		return (
			<ul className="simple-list">
				{
					props.item.changes.map((i: DiffChange<PetPrice>) => (
						<li key={i.diffRecordId}>
							{renderChanges(i.key, i.before, i.after)}
						</li>
					))
				}
			</ul>
		);

	case DiffAction.Added:
	case DiffAction.Deleted:
	default:
		return null;
	}
};

export const PetPriceDiffList: React.FC<PetPriceDiffListProps> = (props: PetPriceDiffListProps) => {
	if (!props.list?.length) return null;

	return (
		<ul className="simple-list list-block">
			{
				props.list.map((item: PetPriceDiffs) =>
					(
						<li
							key={item.updateTransactionId}
							className="clearfix list-block"
							style={{ fontWeight: 600 }}
						>
							<div className="status-history__item-user history-item__user pull-left">
								<div>{getName(item?.object)}</div>
								<PetPriceChanges item={item} />
							</div>
							<div className="status-history__item-user history-item__user pull-right">
								{DiffAction[item.diffAction]}
							</div>
						</li>
					))
			}
		</ul>
	);
};
