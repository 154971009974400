type Predicate<T> = (item: T) => boolean;
type Source<T> = Array<T> | Query<T>;

export function isQuery<T>(source: Source<T>): source is Query<T> {
	return (source as Query<T>).any !== undefined;
}

export function isArray<T>(source: Source<T>): source is Array<T> {
	return (source as Array<T>).length !== undefined;
}

export class Query<T> {
	private readonly list: Array<T>;
	private chain: Array<Predicate<T>>;

	constructor(source: Source<T>) {
		if (isArray(source)) {
			this.list = source;
			this.chain = [];
		} else {
			this.list = source.list;
			this.chain = [...source.chain];
		}
	}

	public where(predicate: Predicate<T>): Query<T> {
		this.chain.push(predicate);

		return this;
	}

	public any(): boolean {
		return Boolean(this.collapse().length);
	}

	public all(): boolean {
		return this.collapse().length === this.list.length;
	}

	public none(): boolean {
		return this.collapse().length === 0;
	}

	public count(): number {
		return this.collapse().length;
	}

	public array(): Array<T> {
		return this.collapse();
	}

	public clone(): Query<T> {
		return new Query<T>(this);
	}

	private collapse(): Array<T> {
		const apply = (item: T): boolean =>
			this.chain.reduce((res: boolean, predicate: Predicate<T>) => predicate(item) && res, true);

		return this.list.filter(apply);
	}
}
