import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Nullable } from '@common/typescript/objects/Nullable';

import { ApplicationState } from '@app/store';
import { Location, locationName } from '@app/objects/Location';
import { bindItemsActionCreators } from '@app/store/ItemList';

import { Select } from '@app/components/UI/Select/Select';
import { Option } from '@app/components/UI/Select/Option';

type Selector<T> = (items: Array<Location>) => T;

interface LocationSelectProps {
	value?: Nullable<number>;
	onChange: (value: Nullable<number>) => void;

	clinicId?: number;
	forMyClinic?: boolean;

	disabled?: boolean | Selector<boolean>;
	preselect?: number | Selector<Nullable<number> | undefined>;

	placeholder?: string;
	title?: string;
	label?: (item: Location) => string;
}

export const LocationSelect: React.FC<LocationSelectProps> = (props: LocationSelectProps) => {
	const locations = useSelector((state: ApplicationState) => state.clinicLocations);

	const dispatch = useDispatch();
	const factory = React.useMemo(() => bindItemsActionCreators<Location>(dispatch), [dispatch]);

	React.useEffect(() => {
		factory.reqPages('clinicLocations', 'locationList', 'location', {
			objectId: props.clinicId,
			getMine: props.forMyClinic,
			count: 20,
			offset: 0,
		});
	}, [factory, props.clinicId, props.forMyClinic]);

	React.useEffect(() => {
		if (props.preselect === undefined) return;
		const preselect = (typeof props.preselect === 'function' ? props.preselect(locations.items) : props.preselect) ?? null;

		if (preselect !== (props.value ?? null)) {
			props.onChange(preselect);
		}
	}, [props.preselect, locations.items]);

	const label = props.label!;

	return (
		<Select
			value={props.value}
			onChange={props.onChange}

			disabled={typeof props.disabled === 'function' ? props.disabled(locations.items) : props.disabled}
			title={props.title}
			placeholder={props.placeholder}

			showSearch
			showClear
			loading={locations.isLoading}
		>
			<Option key={-1} value={-1} disabled>{props.placeholder}</Option>
			{
				locations.items.map((item: Location) => (
					<Option key={item.id} value={item.id}>
						{label(item)}
					</Option>
				))
			}
		</Select>
	);
};

LocationSelect.defaultProps = {
	forMyClinic: false,
	placeholder: 'Select Clinic Location',
	title: 'Clinic Location',
	label: locationName,
	disabled: false,
};
