import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Nullable } from '@common/typescript/objects/Nullable';

import { State } from '@app/objects/State';

import { addressService } from '@app/services/AddressService';

import {
	CoreSelect,
	CoreSelectProps,
} from '@app/components/UI/Inputs/CoreSelect';

import { RegionSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';

import { ApplicationState } from '@app/store';
import { SelectItem } from '@app/store/SelectList/SelectList';

type Value = number;

interface BaseProps {
	country: string;
	disabled?: boolean;
	className?: string;
	selectClassName?: string;

	pickDefault?: (entity) => Value;

	label?: string;
	placeholder?: string;

	style?: React.CSSProperties;
}

interface SingleProps extends BaseProps {
	value?: Value | undefined;
	onChange: (value: Value | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<Value>;
	onChange: (value: Array<Value> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

interface RegionRequestParams extends SelectRequestParams<Value> {
	requestOnMount: boolean;
}

const reqParams: Partial<RegionRequestParams> = {
	count: 200,
	requestOnMount: true,
};

function getName(item: State) {
	return `${item.name} (${item.code})`;
}

function isEmptyValue(value?: Value | Array<Value>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return false;
}

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		&& (isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

export const RegionSelect: React.FC<OwnProps> = (props: OwnProps) => {
	const [query, setQuery] = React.useState<string>('');
	const { t } = useTranslation('translation', { keyPrefix: 'selects.region-select.placeholder' });

	const store = useSelector<ApplicationState, SelectItem<State, RegionSelectFilter>>((state: ApplicationState) => state.selects.regions);

	React.useEffect(() => {
		if (store && props.value !== undefined) {
			const state = store.items.find((item) => item.id === props.value);

			if (state) {
				if (props.multiple) {
					props.onChange([state.id]);
				} else {
					props.onChange(state.id);
				}
			}
		}
	}, [props.value, store]);

	const details = addressService.provide(props.country);

	const filters: Nullable<RegionSelectFilter> = React.useMemo(() => ({
		name: query,
		search: query,
		country: props.country,
	}), [query, props.country]);

	const properties: CoreSelectProps<Value, 'regions', RegionRequestParams> = {
		...props,
		onSearch: setQuery,
		filters,
		placeholder: t(details.regionKey),
		reqParams,
		getName,
		isEmptyValue,
		shouldPickDefault,
		store: 'regions',
	};

	return <CoreSelect<Value, 'regions', RegionRequestParams> {...properties} />;
};
