import { request as coreRequest } from '@common/react/components/Api/Request';
import { wrapError, wrapDomain } from '@common/react/components/Api/Wrappers';

import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { BaseParams } from '@common/typescript/objects/BaseParams';

export function request<
	T,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
	>(type: string, data: BaseParams = {}, state?: TApplicationState, signal?: AbortSignal): Promise<T> {
	const task = coreRequest<T, TUser, TApplicationState>(type, data, state, signal);

	return wrapDomain(wrapError(task));
}
