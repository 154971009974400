import { generatePath } from 'react-router-dom';

import { RoutePaths } from '@app/utilities/RoutePathVars';
import { ViewMode } from '@app/components/Utils/Utils';

type CrematoryPathProps = Record<string, string | number | boolean | undefined>

export const NEW_CREMATORY_ID = -1;
export const crematoryListPath = '/crematory-list';
export const crematoryEditorPath = '/crematory-editor';
export const crematoryStoreName = 'crematory';
export const crematoriesStoreName = 'crematories';
export const getCrematoryEndpoint = 'getCrematory';
export const crematoryDeleteEndpoint = 'deleteCrematory';
export const crematorySaveEndpoint = 'updateCrematory';
export const crematoryListEndpoint = 'crematoryList';

export const getCrematoryPath = (props: CrematoryPathProps): string => generatePath(RoutePaths.admin, props);
export const getCrematoryViewPath = (id: number, tab: string): string => getCrematoryPath({ id, tab, type: ViewMode.view });
export const getCrematoryEditPath = (id: number | undefined, tab: string): string => getCrematoryPath({ id, tab, type: ViewMode.edit });
export const getNewCrematoryPath = (): string => getCrematoryPath({ id: '-1', type: ViewMode.edit, tab: 'info' });
