import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Field, FieldProps, useFormikContext } from 'formik';

import Tag from 'antd/lib/tag';
import Icon from 'antd/lib/icon';
import Select from 'antd/lib/select';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { PriceType } from '@app/objects/Price';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { UserRole } from '@app/objects/User';
import { CrematorySelect } from '@app/components/UI/Inputs/CrematorySelect';
import { CrematoryListInfo, getServiceTypePreferenceKind } from '@app/objects/Crematory';
import { PetOnHold, ServiceType } from '@app/objects/Pet';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { ApplicationState } from '@app/store';
import { LocalSelect, Option } from '@app/components/UI/Inputs/LocalSelect';
import { serviceTypes } from '@app/components/UI/Inputs/LocalSelectOptions';

import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { WhySpecial } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Displays/WhySpecial';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';
import { useCrematory } from '@app/hooks/useCrematory';

const TagStack: React.FC = () => {
	const [petId, setPetId] = React.useState<Nullable<number>>(() => null);
	const { values } = useFormikContext<PetFormValues>();

	const { onHold, isSpecial, id } = values;

	if (onHold === PetOnHold.None && !isSpecial) return null;

	return (
		<>
			<Stack
				direction={StackDirection.Vertical}
				gap={2}
				className="tag-stack"
			>
				{
					onHold !== PetOnHold.None
						? (
							<Tag color="volcano">On Hold</Tag>
						) : null
				}
				{
					isSpecial
						? (
							<Tag
								color="blue"
								style={{
									display: 'flex',
									flexDirection: 'row',
									gap: '4px',
									alignItems: 'center',
									cursor: 'pointer',
								}}
								onClick={() => setPetId(id)}
							>
								Special
								<Icon
									type="question-circle"
								/>
							</Tag>
						) : null
				}
			</Stack>
			<ModalWrapper
				title="Why is this pet 'Special'?"
				isOpened={Boolean(petId)}
				onOk={() => setPetId(null)}
				onCancel={() => setPetId(null)}
				okText="Ok"
			>
				{
					petId
						? (
							<WhySpecial
								petId={petId!}
							/>
						) : null
				}
			</ModalWrapper>
		</>
	);
};

export const TopSection: React.FC = () => {
	const { t } = useTranslation();
	const { values } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory(values.crematoryId);
	const [serviceTypeOptions, setServiceTypeOptions] = React.useState<Array<Option>>([]);

	React.useEffect(() => {
		if (!crematory || !values.crematoryId) return;

		setServiceTypeOptions(serviceTypes(crematory));
	}, [crematory, values.crematoryId]);

	return (
		<>
			<div className="row form-group is-relative">
				<TagStack />
			</div>
			<div className="row is-relative">
				<Field name="crematoryId">
					{(fieldProps: FieldProps<PetFormValues>) => {
						if (user?.role === UserRole.Crematory) return null;
						if (user?.role === UserRole.CrematoryUser) return null;

						return (
							<FormikInput
								fieldProps={fieldProps}
								title={`${t(iKey('labels.crematory'))} *`}
								containerClassName="col-sm-4 form-group"
								render={({ field, form }: FieldProps<number, PetFormValues>) => (
									<CrematorySelect
										value={field.value}
										onChange={(value?: number) =>
											form.setFieldValue(field.name as keyof PetFormValues, value, false)}
										pickDefault={(store: Array<CrematoryListInfo>) => isPickDefault(store)}
										placeholder="Crematory"
									/>
								)}
							/>
						);
					}}
				</Field>
			</div>
			<div className="row form-group">
				<Field name="serviceType">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="col-sm-4"
							title={`${t(iKey('labels.type-of-service'))} *`}
							render={({ field, form }: FieldProps<ServiceType, PetFormValues>) => (
								<LocalSelect
									onChange={(value: ServiceType) => form.setFieldValue(field.name as keyof PetFormValues, value, false)}
									value={field.value}
									options={serviceTypeOptions}
									fieldName={field.name}
									deselectType={field.name}
									filterName={field.name}
									allowClear={false}
									placeholder="Select the Service Type"
								/>
							)}
						/>
					)}
				</Field>
				<Field name="priceType">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={`${t(iKey('labels.price-type'))} *`}
							containerClassName="col-sm-4"
							render={({ field, form }: FieldProps<PriceType, PetFormValues>) => (
								<Select
									showArrow
									showSearch
									// This is required for input focus on Select focused
									showAction={['focus', 'click']}
									optionFilterProp="children"
									id="priceType"
									{...field}
									onChange={(value: PriceType) => {
										form.setFieldValue(field.name as keyof PetFormValues, value, false);
										form.setFieldValue('ownerPhone', '');
										form.setFieldValue('ownerPhone2', '');

										if (value === PriceType.Retail) form.setFieldValue('clinicId', null);
									}}
								>
									<Select.Option value={PriceType.Wholesale}>{t('enums.price-type.wholesale')}</Select.Option>
									<Select.Option value={PriceType.Retail}>{t('enums.price-type.retail')}</Select.Option>
								</Select>
							)}
						/>
					)}
				</Field>
				<Field name="internalIdNum">
					{(fieldProps: FieldProps<PetFormValues>) => {
						const values = fieldProps.form.values;
						if (values.priceType !== PriceType.Retail) return null;
						const serviceTypePreference = getServiceTypePreferenceKind(values.serviceType);
						if (!crematory?.[serviceTypePreference]?.showInternalId) return null;

						return (
							<FormikInput
								fieldProps={fieldProps}
								title={<ControlLabel text={t(iKey('labels.clinic-internal-number'))} required={crematory[serviceTypePreference]?.requireInternalId} />}
								containerClassName="form-group col-sm-4"
							/>
						);
					}}
				</Field>
			</div>
			<div className="row form-group">
				<Field name="rush">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={t(iKey('labels.rush'))}
							containerClassName="col-sm-4"
							render={({ field, form }: FieldProps<boolean, PetFormValues>) => (
								<Checkbox
									checked={field.value}
									onChange={(event: CheckboxChangeEvent) =>
										form.setFieldValue(field.name as keyof PetFormValues, event.target.checked, false)}
								>
									{t(iKey('labels.rush-details'))}
								</Checkbox>
							)}
						/>
					)}
				</Field>
				<Field name="onHold">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={t(iKey('labels.on-hold'))}
							containerClassName="col-sm-4"
							render={({ field, form }: FieldProps<PetOnHold, PetFormValues>) => (
								<Checkbox
									checked={field.value === PetOnHold.ByCrematory}
									onChange={(event: CheckboxChangeEvent) => {
										const value = event.target.checked ? PetOnHold.ByCrematory : PetOnHold.None;
										form.setFieldValue(field.name as keyof PetFormValues, value, false);
									}}
								>
									{t(iKey('labels.on-hold-details'))}
								</Checkbox>
							)}
						/>
					)}
				</Field>
			</div>
		</>
	);
};
