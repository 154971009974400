import * as React from 'react';

import clsx from 'clsx';

import '@app/scss/ui/layout/layout.scss';

export enum StackDirection {
	Vertical = 'column',
	Horizontal = 'row',
}

export interface StackProps {
	children: Array<React.ReactNode> | React.ReactNode;

	direction?: StackDirection;
	reverse?: boolean;
	gap?: number;

	className?: string;
	style?: React.CSSProperties;
}

enum LayoutBreakpoint {
	ExtraSmall = 'xs',
	Small = 'sm',
	Medium = 'md',
	Large = 'lg',
	ExtraLarge = 'xl',
}

type LayoutColumn = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type LayoutColumnSpan = LayoutColumn | Partial<Record<LayoutBreakpoint, LayoutColumn>>;

interface LayoutItemProps {
	span?: LayoutColumnSpan;
	fluid?: boolean;

	style?: React.CSSProperties;
	className?: string;
}

const perUnit: number = 8;

function getStyle(props: StackProps): React.CSSProperties {
	const style: React.CSSProperties = {};

	style.display = 'flex';
	style.gap = `${props.gap! * perUnit}px`;
	style.flexDirection = props.direction;
	style.flexWrap = 'wrap';

	if (props.reverse) {
		style.flexDirection = `${props.direction!}-reverse`;
	}

	return { ...style, ...props.style };
}

type StackComponent = React.FC<StackProps> & {
	LayoutItem: React.FC<React.PropsWithChildren<LayoutItemProps>>;
};

export const Stack: StackComponent = (props: StackProps) => (
	<div
		className={props.className}
		style={getStyle(props)}
	>
		{props.children}
	</div>
);

Stack.defaultProps = {
	direction: StackDirection.Vertical,
	gap: 1,
	reverse: false,
};

const prefix: string = 'layout-item';
function getClass(span: LayoutColumnSpan, fluid: LayoutItemProps['fluid']): string {
	if (fluid) return `${prefix}-fluid`;
	if (typeof span === 'number') return `${prefix}-xs-${span}`;

	return Object.entries(span).map(([breakpoint, span]) => `${prefix}-${breakpoint}-${span}`).join(' ');
}

Stack.LayoutItem = (props: React.PropsWithChildren<LayoutItemProps>) => (
	<div
		className={clsx(getClass(props.span!, props.fluid), props.className)}
		style={props.style}
	>
		{props.children}
	</div>
);

Stack.LayoutItem.defaultProps = {
	span: 12,
};
