import React from 'react';
import {
	FieldProps, Form, Formik, FormikHelpers,
} from 'formik';
import TextArea from 'antd/lib/input/TextArea';
import * as Yup from 'yup';

import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { OnProgress, UploadList } from '@common/react/components/Forms/Upload/UploadList';
import { FilePreview } from '@common/react/components/Forms/Upload/FilePreview';
import { UploadProgress } from '@common/react/components/Forms/Upload/UploadProgress';
import { UploadFile } from '@common/react/components/Forms/Upload/UploadFile';
import { UploadFilePlaceholder } from '@common/react/components/Forms/Upload/UploadFilePlaceholder';
import { FileInterface } from '@common/typescript/objects/FileInterface';
import { simpleStringValidator } from '@common/react/utils/validationHelpers';

import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { ModalControls } from '@app/hooks/Editors/useModal';
import { Complaint, ComplaintFile } from '@app/objects/Complaint';
import { request } from '@app/components/Api';
import { MessageType, alertMessage } from '@app/utilities/alert';

const initialValues = {
	id: -1,
	subject: '',
	details: '',
	waysToReproduce: '',
	notes: '',
	files: [],
};

const validationSchema = Yup.object().shape({
	subject: simpleStringValidator,
	details: simpleStringValidator,
});

function getNewFile(file: FileInterface) {
	return {
		id: -1,

		file,
		fileId: file.id,

		objectId: -1,
		object: undefined,
		text: '',
	};
}

interface BugReportModalProps {
	modal: ModalControls;
}

export const BugReportModal: React.FC<BugReportModalProps> = ({ modal }: BugReportModalProps) => (
	<Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		onSubmit={(values, { setSubmitting, resetForm }: FormikHelpers<Complaint>) => {
			request<Complaint>('createComplaint', values)
				.then(() => {
					alertMessage(MessageType.success, 'Success! Your form has been successfully submitted.');
					resetForm();
					modal.close();
				})
				.catch((error) => alertMessage(MessageType.error, error))
				.finally(() => setSubmitting(false));
		}}
		enableReinitialize
	>
		{(formikBag) => (
			<ModalWrapper
				title="Report a problem"
				isOpened={modal.state}
				onOk={formikBag.handleSubmit}
				okText="Send"
				onCancel={() => {
					modal.close();
					formikBag.resetForm();
				}}
				okButtonProps={{ loading: formikBag.isSubmitting }}
			>
				<Form id="bug-report-modal">
					<FormikField
						fieldName="subject"
						title="Subject*"
						inputProps={{
							placeholder: 'A brief description of the error',
						}}
					/>
					<FormikField
						fieldName="details"
						containerClassName="col-sm-12 form-group"
						title="Details*"
						render={({ field, form }) => (
							<TextArea
								rows={4}
								onChange={(event) => form.setFieldValue(field.name, event.target.value)}
								value={field.value}
								placeholder="Detailed description of the error"
							/>
						)}
					/>
					<FormikField
						fieldName="waysToReproduce"
						containerClassName="col-sm-12 form-group"
						title="Ways to Reproduce"
						render={({ field, form }) => (
							<TextArea
								rows={2}
								onChange={(event) => form.setFieldValue(field.name, event.target.value)}
								value={field.value}
								placeholder="Describe the sequence of your actions"
							/>
						)}
					/>
					<FormikField
						fieldName="notes"
						containerClassName="col-sm-12 form-group"
						title="Additional Notes"
						render={({ field, form }) => (
							<TextArea
								rows={2}
								onChange={(event) => form.setFieldValue(field.name, event.target.value)}
								value={field.value}
								placeholder="Here you can specify the ID of the pet, a link and etc"
							/>
						)}
					/>
					<FormikField
						fieldName="files"
						containerClassName="col-sm-12 form-group"
						title="Files"
						render={({ field, form }: FieldProps<Array<ComplaintFile>, Complaint>) => (
							<UploadList
								list={field.value}
								renderItem={(item) => (
									<FilePreview
										file={item.file}
										onDelete={() => form.setFieldValue(field.name, field.value.filter((i) => i.id !== item.id))}
									/>
								)}
								renderProgress={(progress: number) => (
									<UploadProgress
										progress={progress}
										activeBarColor="#508aeb"
									/>
								)}
								renderUpload={(onProgress: OnProgress) => (
									<UploadFile
										type="complaint"
										onUpload={(file: FileInterface) => form.setFieldValue(field.name, [...field.value, getNewFile(file)])}
										onProgress={onProgress}
									>
										<UploadFilePlaceholder
											text="+ Add File"
											caption="File should not exceed 4 mb"
										/>
									</UploadFile>
								)}
							/>
						)}
					/>
				</Form>
			</ModalWrapper>
		)}
	</Formik>
);
