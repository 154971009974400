import notification from 'antd/lib/notification';
import { ReactNode } from 'react';

export enum MessageType {
	success = 'success',
	error = 'error',
	warning = 'warning',
	info = 'info'
}

export function alertMessage(type: MessageType, message: ReactNode): void {
	notification[type]({
		message,
		placement: 'bottomRight',
	});
}
