import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	Field,
	FieldProps,
	useFormikContext,
} from 'formik';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { Nullable } from '@common/typescript/objects/Nullable';

import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { ClinicSelect } from '@app/components/UI/Inputs/ClinicSelect';
import { LocationSelect } from '@app/components/UI/Inputs/LocationSelect';
import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';
import { PriceType } from '@app/objects/Price';
import { getServiceTypePreferenceKind } from '@app/objects/Crematory';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';
import { useCrematory } from '@app/hooks/useCrematory';

function missingValue(value?: Nullable<number>): boolean {
	return value === null || value === undefined || value < 0;
}

export const ClinicSection: React.FC = () => {
	const { t } = useTranslation();
	const { values } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory(values.crematoryId);
	const option = getServiceTypePreferenceKind(values.serviceType);
	const showInternalId = crematory?.[option]?.showInternalId;

	return (
		<SectionContainer titleLocalizationKey="sections.clinic">
			<div className="row form-group">
				<Field name="clinicId">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="form-group col-sm-4"
							title={`${t(iKey('labels.clinic'))} *`}
							render={({ field, form }: FieldProps<number, PetFormValues>) => (
								<ClinicSelect
									value={field.value}
									onChange={(id: number | Array<number> | undefined) => {
										form.setFieldValue(field.name as keyof PetFormValues, id, false);
										form.setFieldValue('clinicLocationId', null, false);
										form.setFieldValue('services', []);

										if (form.values.priceType === PriceType.Wholesale) form.setFieldValue('ownerState', '');
									}}
									disabled={user?.role !== UserRole.Crematory && missingValue(form.values.crematoryId)}
									crematoryId={form.values.crematoryId}
									customers
									markCustomer={user?.role === UserRole.Crematory || user?.role === UserRole.CrematoryUser}
								/>
							)}
						/>
					)}
				</Field>
				<Field name="clinicLocationId">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="form-group col-sm-4"
							title={t(iKey('labels.clinic-location'))}
							render={({ field, form }: FieldProps<number, PetFormValues>) => (
								<LocationSelect
									value={field.value ?? undefined}
									onChange={(value: Nullable<number>) => form.setFieldValue('clinicLocationId', value, false)}

									clinicId={form.values.clinicId === null ? undefined : +form.values.clinicId}
								/>
							)}
						/>
					)}
				</Field>
				<Field name="internalIdNum">
					{(fieldProps: FieldProps<PetFormValues>) => {
						if (!showInternalId) return null;

						return (
							<FormikInput
								fieldProps={fieldProps}
								title={<ControlLabel text={t(iKey('labels.clinic-internal-number'))} required={crematory?.[option]?.requireInternalId} />}
								containerClassName="form-group col-sm-4"
							/>
						);
					}}
				</Field>
			</div>
		</SectionContainer>
	);
};
