import { Action, ActionCreatorsMapObject, Reducer } from 'redux';

import { BaseAppThunkAction } from '@common/react/store';
import { List } from '@common/typescript/objects/List';

import { User } from '@app/objects/User';
import { CrematoryUserCounter, CrematoryUserCounterEntity } from '@app/objects/CrematoryUserCounter';
import { ApplicationState, AppThunkAction } from '@app/store/index';
import { request } from '@app/components/Api';

interface Pagination {
	page: number;
	perPage: number;
}

export interface CrematoryUserDashboardState<T extends CrematoryUserCounterEntity> {
	isLoading: boolean;
	store: T;
	pagination: Pagination;
	counters: List<CrematoryUserCounter>;
	error: string | null;
}

function getDefaultState<T extends CrematoryUserCounterEntity>(type: T): CrematoryUserDashboardState<T> {
	return {
		isLoading: false,
		store: type,
		pagination: {
			page: 0,
			perPage: 20,
		},
		counters: {
			count: 0,
			offset: 0,
			execution: 0,
			list: [],
		},
		error: null,
	};
}

export enum TypeKeys {
	REQUESTCREMATORYUSERCOUNTERS = 'REQUESTCREMATORYUSERCOUNTERS',
	RECEIVECREMATORYUSERCOUNTERS = 'RECEIVECREMATORYUSERCOUNTERS',
	REQUESTCREMATORYUSERCOUNTERSERROR = 'REQUESTCREMATORYUSERCOUNTERSERROR',
}

interface RequestErrorAction {
	type: TypeKeys.REQUESTCREMATORYUSERCOUNTERSERROR;
	error: string;
	store: CrematoryUserCounterEntity;
}

interface RequestCountersAction {
	type: TypeKeys.REQUESTCREMATORYUSERCOUNTERS;
	store: CrematoryUserCounterEntity;
}

interface ReceiveCountersAction {
	type: TypeKeys.RECEIVECREMATORYUSERCOUNTERS;
	store: CrematoryUserCounterEntity;
	counters: List<CrematoryUserCounter>;
	pagination: Pagination;
}

type KnownPageActions = RequestCountersAction | ReceiveCountersAction | RequestErrorAction;

export interface CrematoryUserDashboardActionCreators extends ActionCreatorsMapObject {
	reqCounters: (store: CrematoryUserCounterEntity) => BaseAppThunkAction<KnownPageActions, User, ApplicationState>;
}

export function getActionCreators(store: CrematoryUserCounterEntity): CrematoryUserDashboardActionCreators {
	const key: keyof ApplicationState = store === CrematoryUserCounterEntity.Status
		? 'crematoryUserDashboardStatus'
		: 'crematoryUserDashboardServices';
	const endpoint = store === CrematoryUserCounterEntity.Status
		? 'crematoryUserCounterStatusList'
		: 'crematoryUserCounterSpecialServicesList';

	return {
		reqCounters: (page: number, perPage: number = 20): AppThunkAction<KnownPageActions> => (dispatch, getState) => {
			const state = getState()[key];

			if (state.pagination.page !== page || state.pagination.perPage !== perPage) {
				request<List<CrematoryUserCounter>>(endpoint, { count: perPage, offset: (page - 1) * perPage }, getState())
					.then((data: List<CrematoryUserCounter>) => {
						dispatch({
							type: TypeKeys.RECEIVECREMATORYUSERCOUNTERS,
							store,
							counters: data,
							pagination: {
								page,
								perPage,
							},
						});
					})
					.catch((error: string) => {
						dispatch({
							type: TypeKeys.REQUESTCREMATORYUSERCOUNTERSERROR,
							error,
							store,
						});
					});

				dispatch({ type: TypeKeys.REQUESTCREMATORYUSERCOUNTERS, store });
			}
		},
	};
}

export function getReducer<T extends CrematoryUserCounterEntity>(store: T): Reducer<CrematoryUserDashboardState<T>> {
	return (
		state: CrematoryUserDashboardState<T> | undefined,
		incomingAction: Action,
	) => {
		const result = state ?? getDefaultState(store);
		const action = incomingAction as KnownPageActions;

		if (action.store !== store) return result;

		switch (action.type) {
		case TypeKeys.REQUESTCREMATORYUSERCOUNTERS:
			return { ...result, isLoading: true };
		case TypeKeys.RECEIVECREMATORYUSERCOUNTERS:
			return {
				...result,
				isLoading: false,
				counters: action.counters,
				pagination: action.pagination,
			};
		case TypeKeys.REQUESTCREMATORYUSERCOUNTERSERROR:
			return { ...result, isLoading: false, error: action.error };

		default:
			return result;
		}
	};
}
