import * as React from 'react';

import Pagination from 'antd/lib/pagination';
import Tag from 'antd/lib/tag';

import { Loading } from '@common/react/components/UI/Loading/Loading';
import { AlertMessage } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { EmailType, MailStatus, PetEmailLogView } from '@app/objects/EmailLog';
import { usePagination } from '@app/hooks/PaginationManager/PaginationManager';
import { useRemoteData } from '@app/hooks/RemoteDataProvider/RemoteDataProvider';
import { DateView } from '@app/components/UI/Views/DateFormat/DateView';
import { TimeView } from '@app/components/UI/Views/TimeView/TimeView';

interface OldPetEmailLogProps {
	petId: number;
}

export function setStatusColor(status: MailStatus) {
	switch (status) {
	case MailStatus.Success:
		return 'green';
	case MailStatus.Fail:
		return 'red';
	case MailStatus.Unknown:
		return 'orange';

	default:
		return 'default';
	}
}

export const OldPetEmailLog: React.FC<OldPetEmailLogProps> = ({ petId }: OldPetEmailLogProps) => {
	const params = React.useMemo(() => ({
		petId,
	}), [petId]);

	const request = useRemoteData<PetEmailLogView, OldPetEmailLogProps>({
		endpoint: 'petEmailLogList',
		shouldRefetch: false,
		usePagination: () => usePagination({ perPage: 5 }),
	}, params);

	React.useEffect(() => {
		request.fetch(params);
	}, [params]);

	if (request.loading) return <Loading />;
	if (request.error) return <AlertMessage message={request.error} />;

	return (
		<>
			<ul className="status-history history-list">
				{request.items && request.items.length > 0
					? request.items.map((item: PetEmailLogView) => (
						<li className="status-history__item history-item" key={item.id}>
							<div className="clearfix">
								<div className="status-history__item-name history-item__name pull-left">
									<DateView date={item.dateTime} /> <TimeView time={item.dateTime} />
								</div>
								<Tag
									className="status-history__item-name history-item__user pull-right"
									color={setStatusColor(item.status)}
									style={{ margin: 0 }}
								>
									{MailStatus[item.status]}
								</Tag>
							</div>
							<div className="clearfix">
								<div className="status-history__item-name history-item__user pull-left">
									Email type: {EmailType[item.type]}
								</div>
							</div>
						</li>
					))
					: <div className="text-center"><i>History is empty</i></div>}
			</ul>
			<Pagination
				total={request.pagination.total}
				hideOnSinglePage
				pageSize={request.pagination.perPage}
				onChange={(value: number) => request.onPageChange(value - 1)}
				current={request.pagination.page + 1}
				style={{ textAlign: 'center' }}
				size="small"
			/>
		</>
	);
};
