import { Action, ActionCreatorsMapObject, Reducer } from 'redux';

import { BaseAppThunkAction } from '@common/react/store';

import { User } from '@app/objects/User';
import { request } from '@app/components/Api';
import { ApplicationState } from '@app/store';
import { UserPreferences } from '@app/objects/UserPreferences';
import { PetListPreferences } from '@app/objects/UserPreferences/PetListReportPreferences';
import { DropReportPreferences } from '@app/objects/UserPreferences/DropOffReportsPreferences';
import { ClinicInvoiceReportPreferences } from '@app/objects/UserPreferences/ClinicInvoiceReportPreferences';

export interface UserPreferencesRecord<T extends UserPreferences> {
	item: T | null;
	loading: boolean;
	error: string | null;
}

export interface UserPreferencesStore {
	petListPreferences: UserPreferencesRecord<PetListPreferences>;
	dropOffReportPreferences: UserPreferencesRecord<DropReportPreferences>;
	clinicInvoiceReportPreferences: UserPreferencesRecord<ClinicInvoiceReportPreferences>;
}

function getDefaultPreferences<T extends UserPreferences>(): UserPreferencesRecord<T> {
	return {
		item: null,
		loading: false,
		error: null,
	};
}

const defaultStore: UserPreferencesStore = {
	petListPreferences: getDefaultPreferences<PetListPreferences>(),
	dropOffReportPreferences: getDefaultPreferences<DropReportPreferences>(),
	clinicInvoiceReportPreferences: getDefaultPreferences<ClinicInvoiceReportPreferences>(),
};

export enum ActionKeys {
	REQUESTUSERPREFERENCES = 'REQUESTUSERPREFERENCES',
	SETUSERPREFERENCES = 'SETUSERPREFERENCES',
}

interface RequestUserPreferencesAction extends Action<ActionKeys.REQUESTUSERPREFERENCES> {
	key: keyof UserPreferencesStore;
	type: ActionKeys.REQUESTUSERPREFERENCES;
}

interface SetUserPreferencesAction extends Action<ActionKeys.SETUSERPREFERENCES> {
	key: keyof UserPreferencesStore;
	type: ActionKeys.SETUSERPREFERENCES;
	state: UserPreferencesRecord<UserPreferences>;
}

interface EndpointTuple {
	get: string;
	save: string;
}

const endpoints: Record<keyof UserPreferencesStore, EndpointTuple> = {
	petListPreferences: {
		get: 'getPetListReportPreferences',
		save: 'updatePetListReportPreferences',
	},
	dropOffReportPreferences: {
		get: 'getDropReportPreferences',
		save: 'setDropReportPreferences',
	},
	clinicInvoiceReportPreferences: {
		get: 'getClinicInvoiceReportPreferences',
		save: 'setClinicInvoiceReportPreferences',
	},
};

export type KnownPreferencesActions = RequestUserPreferencesAction | SetUserPreferencesAction;

type ActionResult = Promise<UserPreferences> | void;
type ThunkAction<T extends ActionResult> = BaseAppThunkAction<KnownPreferencesActions, User, ApplicationState, T>

export interface UserPreferencesActionCreators extends ActionCreatorsMapObject<ThunkAction<void>> {
	load: (key: keyof UserPreferencesStore) => ThunkAction<void>;
	set: <T extends UserPreferences>(key: keyof UserPreferencesStore, preferences: Partial<UserPreferencesRecord<T>>) => ThunkAction<void>;
	save: <T extends UserPreferences>(key: keyof UserPreferencesStore, item: Partial<T>) => ThunkAction<void>;
}

export interface CrematoryPreferencesMappedActionCreators extends ActionCreatorsMapObject<ActionResult> {
	load: (key: keyof UserPreferencesStore) => void;
	set: <T extends UserPreferences>(key: keyof UserPreferencesStore, preferences: Partial<UserPreferencesRecord<T>>) => void;
	save: <T extends UserPreferences>(key: keyof UserPreferencesStore, item: Partial<T>) => void;
}

export function getUserPreferencesActionCreators(): UserPreferencesActionCreators {
	function load<T extends UserPreferences>(key: keyof UserPreferencesStore) {
		return (dispatch, getState): void => {
			const state = getState().userPreferences[key];

			if (!state.item && !state.loading) {
				request<T>(endpoints[key].get)
					/* eslint-disable-next-line */
					.then((item: T) => dispatch(context.set<T>(key, { loading: false, item, error: null })))
					// eslint-disable-next-line
					.catch((error: string) => dispatch(context.set<T>(key, { loading: false, item: null, error })));

				dispatch({
					key,
					type: ActionKeys.REQUESTUSERPREFERENCES,
				});
			}
		};
	}

	function save<T extends UserPreferences>(key: keyof UserPreferencesStore, item: Partial<T>) {
		return (dispatch, getState): void => {
			const current = getState().userPreferences[key].item;

			request<T, Partial<T>>(endpoints[key].save, item)
				// eslint-disable-next-line
				.then((item: T) => dispatch(context.set<T>(key, { loading: false, item, error: null })))
				// Rollback on error
				// eslint-disable-next-line
				.catch((error: string) => dispatch(context.set<T>(key, { loading: false, item: current ? { ...current } : null, error })));
		};
	}

	function set<T extends UserPreferences>(key: keyof UserPreferencesStore, preferences: Partial<UserPreferencesRecord<T>>) {
		return (dispatch): void => {
			dispatch({
				key,
				type: ActionKeys.SETUSERPREFERENCES,
				state: preferences,
			});
		};
	}

	const context: UserPreferencesActionCreators = { load, save, set };

	return context;
}

export function getUserPreferencesReducer(): Reducer<UserPreferencesStore, KnownPreferencesActions> {
	return (state: UserPreferencesStore = defaultStore, action: KnownPreferencesActions) => {
		switch (action.type) {
		case ActionKeys.REQUESTUSERPREFERENCES: {
			const newState = { ...state };
			const castAction = action as RequestUserPreferencesAction;
			newState[castAction.key].loading = true;

			return newState;
		}

		case ActionKeys.SETUSERPREFERENCES: {
			const newState = { ...state };
			const castAction = action as SetUserPreferencesAction;

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState[castAction.key] = castAction.state;

			return newState;
		}

		default:
			return state;
		}
	};
}
