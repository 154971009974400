import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, FieldProps } from 'formik';
import { v4 } from 'uuid';

import { Nullable } from '@common/typescript/objects/Nullable';

import { PetPrice, ServiceType } from '@app/objects/Pet';

import {
	SpecialServiceActions,
	SpecialServiceList,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import {
	PriceType,
	Price,
} from '@app/objects/Price';
import { useRequest } from '@app/hooks/useRequest';
import { ApplicationState } from '@app/store';

interface SpecialServiceSectionProps {
	prices: Array<Price>;
}

function getActions({ field, form }: FieldProps<Array<PetPrice>, PetFormValues>): SpecialServiceActions {
	function setValue(value: Array<PetPrice>) {
		form.setFieldValue(field.name as keyof PetFormValues, value, false);
	}

	return {
		add: (items: Array<PetPrice>) => {
			const values = [...field.value, ...items];

			setValue(values);
		},
		update: (clientId: string, value: Partial<PetPrice>) => {
			const values = field.value.map((q: PetPrice) => {
				if (q.clientId === clientId) {
					return {
						...q,
						...value,
					};
				}

				return q;
			});

			setValue(values);
		},
		delete: (clientId: string) => {
			const values = field.value.map((q: PetPrice) => {
				if (q.clientId === clientId && !(q.done || q.completedCount > 0)) {
					return {
						...q,
						removed: true,
					};
				}

				return q;
			}).filter((q: PetPrice) => !(q.removed && q.id < 0));

			setValue(values);
		},
	};
}

interface PetData {
	crematoryId: number;
	priceType: PriceType;
	serviceType: ServiceType;
	clinicId?: Nullable<number>;
}

interface ServiceProps {
	pet: PetData;
	pendingOnly: boolean;
	defaultOnly: boolean;
}

interface ProviderProps {
	formik: FieldProps<Array<PetPrice>, PetFormValues>;
	onLoad: (value: boolean) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const DefaultServicesProvider: React.VFC<ProviderProps> = ({ formik, onLoad }: ProviderProps) => {
	const { form } = formik;
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const noActiveCrematory = form.values.id > 0 ? form.initialValues.crematoryId : -1;
	const isActiveCrematory = !activeCrematoryId ? noActiveCrematory : activeCrematoryId;
	const availableServices = useRequest<Array<Price>, ServiceProps>('availableSpecialServiceList', undefined, { requestOnMount: false });
	const prevCrematoryId = React.useRef<number>(isActiveCrematory);
	const prevClinicId = React.useRef<Nullable<number> | undefined>(form.values.clinicId);
	const prevServiceType = React.useRef<Nullable<ServiceType>>(form.values.id > 0 ? form.initialValues.serviceType : null);
	const prevPriceType = React.useRef<Nullable<PriceType>>(form.values.id > 0 ? form.initialValues.priceType : null);
	const equal = form.values.crematoryId !== prevCrematoryId.current
		|| form.values.clinicId !== prevClinicId.current
		|| form.values.serviceType !== prevServiceType.current
		|| form.values.priceType !== prevPriceType.current;

	React.useEffect(() => {
		const cur = {
			serviceType: form.values.serviceType,
			priceType: form.values.priceType,
			crematoryId: form.values.crematoryId,
			clinicId: form.values.clinicId,
		};

		if (equal) {
			prevCrematoryId.current = form.values.crematoryId;
			prevServiceType.current = form.values.serviceType;
			prevPriceType.current = form.values.priceType;
			prevClinicId.current = form.values.clinicId;

			availableServices.reload({
				pet: cur,
				pendingOnly: form.values.id > 0,
				defaultOnly: true,
			});
		}
	}, [form.values.crematoryId, form.values.clinicId, form.values.serviceType, form.values.priceType]);

	React.useEffect(() => {
		if (availableServices.item !== null) {
			const services = availableServices.item.map((item: Price) => {
				const count = item.batchCount || 1;
				const extra = Math.max(count - item.batchCount, 0) * item.value;

				return {
					id: -1,
					clientId: v4(),
					deleted: false,

					priceId: item.id,
					price: item,

					pet: null,
					petId: form.values.id,

					count,
					completedCount: 0,
					done: false,

					value: item.batchPrice + extra,
					extra,

					name: item.specialService?.name ?? 'Special Service',
					editor: null,
					editorId: null,
				};
			});
			const old = (form.values.services ?? []).filter((s: PetPrice) => s.id > 0).map((q: PetPrice) => ({ ...q, removed: true }));

			form.setFieldValue('services', [...services, ...old], false);
		}
	}, [availableServices.item]);
	React.useEffect(() => onLoad(availableServices.loading), [availableServices.loading]);

	return null;
};

export const SpecialServiceSection: React.FC<SpecialServiceSectionProps> = ({ prices }: SpecialServiceSectionProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState<boolean>(false);

	return (
		<>
			<div className="form-title">
				<h4>{t(iKey('sections.special-services'))}</h4>
			</div>
			<div className="form-group">
				<Field name="services">
					{(props: FieldProps<Array<PetPrice>, PetFormValues>) => {
						const actions: SpecialServiceActions = getActions(props);

						return (
							<>
								<DefaultServicesProvider formik={props} onLoad={setLoading} />
								<SpecialServiceList
									actions={actions}
									loading={loading}
									prices={prices}
								/>
							</>
						);
					}}
				</Field>
			</div>
		</>
	);
};
