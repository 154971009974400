import * as React from 'react';
import { useSelector } from 'react-redux';

import Alert from 'antd/lib/alert';

import { Formik, FormikProps } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';
import Button from '@common/react/components/Forms/Button';

import { Pet, PetPrice } from '@app/objects/Pet';
import { CurrentStatus } from '@app/objects/CurrentStatus';
import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';

import { StatusComponent } from '@app/components/UI/Status/StatusComponent';
import { SpecialServicesList } from '@app/components/UI/Status/SpecialServicesList';
import { request } from '@app/components/Api';
import { StatusChangeComponent, StatusResponse } from '@app/components/UI/Status/StatusChangeComponent';
import { PromoteDirection, PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';

import { alertMessage, MessageType } from '@app/utilities/alert';
import { getNewServicesArray, getCurrentServices, restorePrices } from '@app/utilities/services';
import { ModalControls, useModal } from '@app/hooks/Editors/useModal';
import { ForceStatusForm } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/ForceStatusForm';
import { HistoryChangeList } from '@app/components/Pages/PetEditor/HistoryChangeList';
import { activePermission, Permission } from '@app/objects/Permission';

interface ComponentProps {
	pet: Pet;
	onChangeStatus: (response: CurrentStatus) => void;
	onRefresh: (petId: number, services: Array<PetPrice>) => void;
	disabled?: boolean;
}

interface CurrentStatusComponentProps {
	pet: Pet,
	onChangeStatus: (response: CurrentStatus) => void,
	isConfirm?: boolean,
	modal: ModalControls,
	disabled?: boolean,
}

interface ServiceComponentProps {
	pet: Pet;
	onRefresh: (petId: number, services: Array<PetPrice>) => void;
	services: Array<PetPrice>;
}

interface FormValues {
	services: Array<PetPrice>;
}

const CurrentStatusComponent: React.FC<CurrentStatusComponentProps> = (props: CurrentStatusComponentProps) => {
	const {
		pet, onChangeStatus, isConfirm, modal, disabled,
	} = props;
	const { currentStatus } = pet;

	const login = useSelector((state: ApplicationState) => state.login);
	const withControls = login.user?.role === UserRole.Admin || login.user?.role === UserRole.Crematory;
	const withNext = Boolean(currentStatus?.nextStatusId);
	const withPrevious = Boolean(currentStatus?.previousStatusId);
	const canChangeStatus = activePermission(login.user, Permission.ChangeStatus);

	return (
		<div className="form-group">
			<div className="site-subheadline">
				<h2>Current status</h2>
			</div>

			<StatusChangeComponent>
				{(promote) => (
					<StatusComponent
						pet={{
							id: pet.id,
							status: pet.currentStatus?.status?.status?.name ?? 'Unknown',
							date: pet.currentStatus?.time,
						}}
						onClick={(direction: PromoteDirection) => {
							promote.request(direction, pet.id, { confirmChanges: isConfirm })
								.then((response: StatusResponse) => {
									if (response.currentStatus && (response.code === PromoteResponseCode.Success
										|| pet.currentStatus?.statusId !== response.currentStatus?.statusId)) {
										onChangeStatus(response.currentStatus);
									} else {
										alertMessage(MessageType.warning, response.message);
									}
								})
								.catch((error: string) => alertMessage(MessageType.error, error));
						}}
						loading={promote.isLoading}
						withControls={withControls && canChangeStatus}
						withNext={withNext}
						withPrevious={withPrevious}
						disabled={disabled || !isConfirm}
						action={login.user?.role === UserRole.Admin && canChangeStatus
							? (
								<i
									className="fa fa-pencil btn-icon"
									style={{ marginLeft: 10, cursor: 'pointer' }}
									title="Edit"
									onClick={!disabled ? () => modal.open() : undefined}
								/>
							) : null}
					/>
				)}
			</StatusChangeComponent>
		</div>
	);
};

const ServiceComponent = (props: ServiceComponentProps) => {
	const { pet, services, onRefresh } = props;
	const [error, setError] = React.useState<Nullable<string>>(() => null);

	return (
		<Formik
			initialValues={{ services }}
			onSubmit={(values: FormValues, { setSubmitting }) => {
				const allArray = getNewServicesArray(services, values);
				request<Array<PetPrice>>('updateServices', { services: allArray.changed })
					.then((items: Array<PetPrice>) => {
						onRefresh(pet.id, restorePrices(services, allArray.unchanged.concat(items)));
					})
					.catch(setError)
					.finally(() => setSubmitting(false));
			}}
			enableReinitialize
		>
			{(formik: FormikProps<FormValues>) => (
				<>
					<SpecialServicesList
						services={formik.values.services}
						onChange={(service: PetPrice) => {
							const newValues = formik.values.services.map((item: PetPrice) => {
								if (item.id === service.id) return service;

								return item;
							});
							formik.setFieldValue('services', newValues);
						}}
					/>
					<div className="form-group">
						{
							error ? (
								<Alert
									type="error"
									message={error}
									closable
									onClose={(event: React.MouseEvent) => event.stopPropagation()}
								/>
							) : null
						}
					</div>
					<div className="text-right" style={{ marginTop: '8px', paddingTop: '8px', borderTop: '1px solid #e8e8e8' }}>
						<Button
							type="submit"
							className="btn btn-sm btn-primary"
							onClick={() => formik.submitForm()}
							isLoading={formik.isSubmitting}
						>
							Save
						</Button>
					</div>
				</>
			)}
		</Formik>
	);
};

export const OldPetStatusHistory: React.FC<ComponentProps> = ({
	pet, onRefresh, onChangeStatus, disabled,
}) => {
	const modal = useModal(false);
	const { currentStatus } = pet;

	const [isConfirm, setIsConfirm] = React.useState<boolean | undefined>(true);

	const services = getCurrentServices(pet);

	return (
		<div>
			{
				currentStatus
					? (
						<CurrentStatusComponent
							pet={pet}
							disabled={disabled}
							onChangeStatus={onChangeStatus}
							isConfirm={isConfirm}
							modal={modal}
						/>
					) : null
			}
			{
				services.length
					? (
						<ServiceComponent
							pet={pet}
							services={services}
							onRefresh={onRefresh}
						/>
					) : null
			}
			<div style={{ margin: '20px 0' }}>
				<HistoryChangeList
					item={pet}
					onChange={(isCheck: boolean, isItems: boolean) => {
						setIsConfirm((isCheck && isItems) || !isItems);
					}}
				/>
			</div>

			{modal.state ? (<ForceStatusForm pet={pet} modal={modal} onChangeStatus={onChangeStatus} />) : null }
		</div>
	);
};
