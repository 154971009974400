import * as React from 'react';

import List from 'antd/lib/list';
import Search from 'antd/lib/input/Search';
import Popover from 'antd/lib/popover';
import Avatar from 'antd/lib/avatar';
import Empty from 'antd/lib/empty';
import Pagination from 'antd/lib/pagination/Pagination';
import Radio from 'antd/lib/radio';
import { PaginationConfig } from 'antd/lib/pagination';

import { Loading } from '@common/react/components/UI/Loading/Loading';

import { GroupProps } from '@app/components/UI/Inputs/CoreSelect';

export interface SelectableItem {
	id: number;
	name: string;
	description?: React.ReactNode;
	avatar?: string;
}

function isSelectableItem(item: SelectableItem | GroupProps<SelectableItem>): item is SelectableItem {
	return typeof (item as SelectableItem).id === 'number';
}

interface SelectableListProps {
	items: Array<SelectableItem> | Array<GroupProps<SelectableItem>>;
	isLoading: boolean;
	fetchMore?: () => void;
	total?: number;

	value: number;
	onChange: (value: number) => void;
	pagination?: PaginationConfig;

	withSelect?: boolean;
	withSingleDefault?: boolean; // Pick the only option by default
	reset?: () => void;
	setQuery?: (query: string) => void;
	placeholder?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const SelectableRadioList: React.FC<SelectableListProps> = (props: SelectableListProps) => {
	const {
		items, isLoading, fetchMore,
		total, value, onChange, reset,
		withSelect, withSingleDefault, setQuery,
		placeholder, pagination,
	} = props;

	const withGroups = items.length && !isSelectableItem(items[0]);

	React.useEffect(() => {
		if (withSingleDefault && items.length === 1 && !withGroups) {
			const item: SelectableItem = items[0] as SelectableItem;
			onChange(item.id);
		}
	}, [items]);

	const renderItem = (item: SelectableItem) => {
		const isSelected = value === item.id;
		const toggle = () => onChange(item.id);

		return (
			<List.Item
				className={isSelected ? 'ant-list-item_selected' : undefined}
				onClick={toggle}
				actions={[
					<div className="text-right" key={item.id}>
						<Radio
							checked={isSelected}
							onClick={toggle}
						/>
					</div>,
				]}
			>
				<List.Item.Meta
					style={{ alignItems: 'center' }}
					avatar={item.avatar
						? (
							<Popover
								trigger="hover"
								content={(
									<img
										src={item.avatar}
										alt={item.name}
										height="150"
										width="150"
									/>
								)}
							>
								<Avatar src={item.avatar} />
							</Popover>
						)
						: null}
					title={item.name}
					description={item.description}
				/>
			</List.Item>
		);
	};

	const loadMore = React.useMemo(() => {
		if (!fetchMore) return null;
		if (total === undefined || total <= items.length) return null;

		return (
			<div className="text-center load-more-action">
				<button
					type="button"
					title="Load more statuses"
					className="btn btn-sm btn-primary btn-outline"
					onClick={fetchMore}
				>
					Load More
				</button>
			</div>
		);
	}, [items, total, fetchMore]);

	if (isLoading) return <Loading />;

	return (
		<>
			{withSelect && (
				<Search
					allowClear
					onChange={(event) => {
						if (event.target.value === '' && event.type === 'click' && setQuery) {
							setQuery('');
						}
					}}
					placeholder={placeholder || ''}
					onSearch={(e: string) => {
						if (reset && setQuery) {
							reset();
							setQuery(e);
						}
					}}
					style={{ margin: '20px 0 10px', width: '100%' }}
					enterButton={<i className="fa fa-search" />}
					className="selectable-list-search"
					onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : '')}
				/>
			)}

			{
				withGroups
					? (
						<>
							{
								(items as Array<GroupProps<SelectableItem>>)
									.filter((group: GroupProps<SelectableItem>) => group.options.length)
									.map((group: GroupProps<SelectableItem>) => (
										<List
											dataSource={group.options}
											header={<span className="field-heading">{group.title}</span>}
											loading={isLoading}
											rowKey="id"
											className="selectable-items-list"
											key={group.title}
											split
											renderItem={renderItem}
										/>
									))
							}
							{
								!(items as Array<GroupProps<SelectableItem>>)
									.reduce((res: number, cur: GroupProps<SelectableItem>) => res + cur.options.length, 0)
									? (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
									: null
							}
							<Pagination
								total={pagination?.total}
								hideOnSinglePage
								pageSize={pagination?.pageSize}
								onChange={pagination?.onChange}
								current={pagination?.current}
								style={{ textAlign: 'center' }}
								size="small"
							/>
						</>
					)
					: (
						<List
							dataSource={items as Array<SelectableItem>}
							loading={isLoading}
							rowKey="id"
							className="selectable-items-list"
							split
							renderItem={renderItem}
							loadMore={loadMore}
						/>
					)
			}
		</>
	);
};
