import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';

import { ClickableType, getClickable, getInteraction } from '@app/components/UI/Cards/Card';
import { CrematoryListInfo } from '@app/objects/Crematory';

import '@app/scss/header.scss';
import { phone, letter } from '@app/images/icons/index';
import { InfoHeader } from '@app/components/UI/Header/HeaderRoleDetails/InfoHeader';
import { ApplicationState } from '@app/store';
import { getActionCreators } from '@app/store/SelectList/ListActions';

const actions = getActionCreators('crematories', { endpoint: 'crematoryList' });

export const ClinicInfoHeader: React.FC = () => {
	const dispatch = useDispatch();
	const factory = React.useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const crematories = useSelector((state: ApplicationState) => state.selects.crematories);
	let activeCrematory = crematories.items.find((item: CrematoryListInfo) => item.id === activeCrematoryId);

	if (crematories.items.length === 1 && activeCrematoryId) activeCrematory = crematories.items[0];

	React.useEffect(() => {
		if (factory) {
			factory.request(null, { preselect: activeCrematoryId ? [activeCrematoryId] : undefined });
		}
	}, [factory]);

	if (crematories.pagination.total === 0) return null;

	return (
		<div className="customer-service-list__clinic">
			<div className="customer-service-card__clinic">
				<ul>
					<li className={clsx('list-first-child col-md-3', crematories.items.length && 'list-first-child_border')}>
						<InfoHeader />
					</li>
					{activeCrematory && (
						<div className="list-group-header">
							<li>
								<a
									href={getInteraction(ClickableType.Telephone, activeCrematory.phone)?.href}
									target="_blank"
									rel="noopener noreferrer"
									title={activeCrematory.phone}
								>
									<img src={phone} alt="Phone" />
								</a>
								<span
									className="text-value"
								>
									{getClickable(ClickableType.Telephone, activeCrematory.phone)}
								</span>
							</li>
							<li>
								<a
									href={getInteraction(ClickableType.Email, activeCrematory.email)?.href}
									target="_blank"
									rel="noopener noreferrer"
									title={activeCrematory.email}
								>
									<img src={letter} alt="E-mail" />
								</a>
								<span className="text-value">{getClickable(ClickableType.Email, activeCrematory.email)}</span>
							</li>
						</div>
					)}
				</ul>
			</div>
		</div>
	);
};
