import * as React from 'react';
import { Helmet } from 'react-helmet';

interface TitleProps {
	title?: string;
}

export const Title: React.FC<TitleProps> = (props: TitleProps) => {
	if (props.title) return <Helmet><title>{props.title}</title></Helmet>;

	return null;
};
