import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import { useRequest } from '@app/hooks/useRequest';

import { CurrentStatus } from '@app/objects/CurrentStatus';
import { PromoteDirection, PromoteResponseCode } from '@app/components/UI/Status/PetStatusButton';

export interface StatusResponse {
	currentStatus: Nullable<CurrentStatus>;

	code: PromoteResponseCode;
	message: string;
}

interface RequestParams {
	petId: number;
	promoteBack: boolean;
	version: number;
	confirmChanges?: boolean;
	forceStatusId?: number;
}

export interface PromoteParams {
	confirmChanges?: boolean;
	forceStatusId?: number;
	sendEmail?: boolean;
}

type PromoteRequest = (direction: PromoteDirection, petId: number, params?: PromoteParams) => Promise<StatusResponse>;

export interface PromoteObject {
	request: PromoteRequest;
	isLoading?: PromoteDirection;
}

interface OwnProps {
	children: (request: PromoteObject) => React.ReactElement;
}

export const StatusChangeComponent = (props: OwnProps): React.ReactElement => {
	const [isLoading, setLoading] = React.useState<PromoteDirection | undefined>(undefined);
	const promRequest = useRequest<StatusResponse, RequestParams>('promoteStatus', undefined, { requestOnMount: false });

	const request = (direction: PromoteDirection, petId: number, params?: PromoteParams) => {
		setLoading(direction);

		const promise = promRequest.reload({
			petId,
			promoteBack: direction === PromoteDirection.PD_PREVIOUS,
			version: 2,
			...params,
		});

		promise.finally(() => setLoading(undefined));

		return promise;
	};

	const promote: PromoteObject = {
		request,
		isLoading,
	};

	return props.children(promote);
};
