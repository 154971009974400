import * as React from 'react';
import { useSelector } from 'react-redux';
import '@app/scss/ui/cards/tabs.scss';

import Tabs from 'antd/lib/tabs';

import TabsWithUrl from '@common/react/components/UI/TabsWithUrl/TabsWithUrl';
import { loadable, loadableDelay, params } from '@common/react/loadable/loadableSettings';

import { Pet, PetSource } from '@app/objects/Pet';
import {
	OldPetInventoryUsageTab,
	OldPetImportNotesTab,
	OldPetHistoryChangeTab,
	OldPetStatusLogTab,
	OldPetEmailLogTab,
} from '@app/components/Pages/PetEditor/OldPetEditorTabs';
import { ApplicationState } from '@app/store';

const LoadableCommentTree = loadable(() => loadableDelay(import('@common/react/components/Forms/Comment/CommentTree')), params);

const { TabPane } = Tabs;

interface ComponentProps {
	pet: Pet;
}

export const OldPetEditorTabs: React.FC<ComponentProps> = (props: ComponentProps): JSX.Element => {
	const { pet } = props;
	const crematoryFeatures = useSelector((state: ApplicationState) => state.crematoryFeatures.item);

	return (
		<div className="card">
			<div className="card__body">
				<TabsWithUrl
					defaultKey="notes"
					tabsUrlKey="tab"
					className="ant-tabs-space"
				>
					<TabPane
						tab="Notes"
						key="notes"
						className="card-tab"
					>
						<LoadableCommentTree
							objectType="pet"
							objectId={pet.id}
							withReply={false}
							withoutCancelButton
						/>
					</TabPane>
					<TabPane
						tab="Status Log"
						key="status"
						className="card-tab"
					>
						<OldPetStatusLogTab pet={pet} />
					</TabPane>
					<TabPane
						tab="Email Log"
						key="email"
						className="card-tab"
					>
						<OldPetEmailLogTab petId={pet.id} />
					</TabPane>
					<TabPane
						tab="Change History"
						key="history"
						className="card-tab"
					>
						<OldPetHistoryChangeTab pet={pet} />
					</TabPane>
					<TabPane
						tab="Inventory Usage"
						key="inventory"
						className="card-tab"
					>
						<OldPetInventoryUsageTab pet={pet} />
					</TabPane>
					{crematoryFeatures?.enableApiImport && (
						<TabPane
							tab="Import Notes"
							key="import-notes"
							disabled={pet.petSource !== PetSource.External}
							className="card-tab"
						>
							<OldPetImportNotesTab pet={pet} />
						</TabPane>
					)}
				</TabsWithUrl>
			</div>
		</div>
	);
};
