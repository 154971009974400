import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';

import clsx from 'clsx';

import { transmute } from '@common/react/objects/BaseUser';

import { Logout } from '@app/components/UI/Buttons/Logout';

import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';
import { BugReportModal } from '@app/components/UI/Header/BugReportModal';
import { useModal } from '@app/hooks/Editors/useModal';

interface MenuActionDropdownProps {
	transmuted: boolean;
	isMenuOpen: boolean;
	setMenuOpen: (value: boolean) => void;
	className?: string;
}

export const MenuActionDropdown: React.FC<MenuActionDropdownProps> = ({
	transmuted, isMenuOpen, setMenuOpen, className,
}) => {
	const modal = useModal(false);
	const history = useHistory();
	const userRole = useSelector((state: ApplicationState) => state.login.user?.role);

	return (
		<>
			<Dropdown
				trigger={['click']}
				visible={isMenuOpen}
				onVisibleChange={setMenuOpen}
				overlay={(
					<Menu className={clsx(
						'site-header__dropdown',
						(userRole === UserRole.ClinicUser || userRole === UserRole.ClinicManager) && 'site-header__dropdown--clinic',
					)}
					>
						{transmuted ? (
							<Menu.Item>
								<button
									type="button"
									className="btn btn-transparent"
									title="Login Back"
									onClick={() => transmute(() => {
										history.push('/user-list');
										window.location.reload();
									})}
								>
									<i className="fa fa-arrow-left" aria-hidden="true" />
									<span>Back</span>
								</button>
							</Menu.Item>
						)
							: null}
						<Menu.Item>
							<button
								type="button"
								className="btn btn-transparent"
								onClick={() => history.replace('/profile')}
							>
								<i className="fa fa-user-o" aria-hidden="true" />
								<span>Profile</span>
							</button>
						</Menu.Item>
						<Menu.Item>
							<button
								type="button"
								className="btn btn-transparent"
								onClick={() => {
									modal.open();
									setMenuOpen(false);
								}}
							>
								<i className="fa fa-bell-o action_danger" aria-hidden="true" />
								<span>Report a problem</span>
							</button>
						</Menu.Item>
						<Menu.Item className="logout-menu-item">
							<Logout clearState />
						</Menu.Item>
						<Menu.Item />

					</Menu>
				)}
			>
				<button
					type="button"
					className={className}
					onClick={() => setMenuOpen(!isMenuOpen)}
				>
					<span className="visually-hidden">open menu</span>
					<i className={`fa ${isMenuOpen ? 'fa-angle-up' : 'fa-angle-down'}`} />
				</button>
			</Dropdown>
			<BugReportModal modal={modal} />
		</>
	);
};
