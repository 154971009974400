import * as React from 'react';

import { PetUrn } from '@app/objects/Pet';
import { DiffAction, DiffChange, PetUrnDiffs } from '@app/objects/History';
import { renderChanges } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetHistoryChange';

interface PetUrnDiffListProps {
	list: Array<PetUrnDiffs>;
}

function getPetUrnDiffs(item: PetUrnDiffs): React.ReactNode {
	switch (item.diffAction) {
	case DiffAction.Updated:
		return (
			<ul className="simple-list">
				{item.changes.map((i: DiffChange<PetUrn>) => (
					<li key={i.diffRecordId}>
						{renderChanges(i.key, i.before, i.after)}
					</li>
				))}
			</ul>
		);

	case DiffAction.Added:
	case DiffAction.Deleted:
	default:
		return null;
	}
}

export const PetUrnDiffList: React.FC<PetUrnDiffListProps> = (props: PetUrnDiffListProps) => {
	return (
		<ul className="simple-list list-block">
			{props.list.map((item: PetUrnDiffs) => (
				<li
					key={item.updateTransactionId}
					className="clearfix list-block"
					style={{ fontWeight: 600 }}
				>
					<div className="status-history__item-user history-item__user pull-left">
						<div>{item.object.category?.name ?? 'Unknown urn'}</div>
						{getPetUrnDiffs(item)}
					</div>
					<div className="status-history__item-user history-item__user pull-right">
						{DiffAction[item.diffAction]}
					</div>
				</li>
			))}
		</ul>
	);
};
