import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@common/react/components/Forms/Autocomplete/Autocomplete';

import { addressService } from '@app/services/AddressService';
import { PostalCode } from '@app/objects/PostalCode';

interface ComponentProps {
	value: string;
	onSelect: (value: string, option: PostalCode) => void;
	onChange?: (value: string) => void;
	disabled?: boolean;
	country: string;
}

export const PostalCodeAutocomplete: React.SFC<ComponentProps> = (props: ComponentProps) => {
	const { t } = useTranslation('translation', { keyPrefix: 'selects.postal-code-autocomplete.placeholder' });
	const details = addressService.provide(props.country);

	return (
		<Autocomplete<PostalCode>
			type="zipCodeList"
			renderTitle="zip"
			params={{ count: 1000, country: props.country }}

			value={props.value}
			onSelect={(value: string, option) => props.onSelect(value, option.props.item)}
			onChange={props.onChange}

			disabled={!details.withMail || props.disabled}
			placeholder={t(details.mailKey)}
		/>
	);
};
