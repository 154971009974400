import { v4 } from 'uuid';

import { Nullable } from '@common/typescript/objects/Nullable';
import { isPresent } from '@common/typescript/objects/WithRemoved';

import { PetEngraving, PetPrice } from '@app/objects/Pet';
import { Price, PriceKind } from '@app/objects/Price';
import { KeyPriceParamsContainer, ProductContainer } from '@app/components/Utils/Prices/Helpers';
import { PriceFilter } from '@app/services/pricing/filters/PriceFilter';
import { DiscountService } from '@app/services/pricing/DiscountService';
import { IPriceStack } from '@app/services/pricing/IPriceStack';

function sum(list: Array<PetPrice>): number {
	return list.reduce((acc: number, item: PetPrice) => acc + item.value, 0);
}

/**
 * This PriceStack is constructed based on Raw Data
 * This is used for creating new pets or editing existing ones (when prices are overridden)
 */
export class CalculatedPriceStack implements IPriceStack {
	private readonly _base: Nullable<PetPrice> = null;
	private readonly _engravings: Array<PetPrice> = [];
	private readonly _services: Array<PetPrice> = [];
	private readonly _urns: Array<PetPrice> = [];
	private readonly _products: Array<PetPrice> = [];
	private readonly _discount: Nullable<PetPrice> = null;
	private readonly _delivery: Nullable<PetPrice> = null;
	private readonly _rush: Nullable<PetPrice> = null;

	private readonly _serviceTaxPercentage: number = 0;
	private readonly _productTaxPercentage: number = 0;

	private getBase(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		const price = new PriceFilter(params).getBase(prices);
		if (!price) return null;

		return {
			id: -1,
			clientId: v4(),
			name: 'Base Price',

			value: price.value,
			extra: price.extra * Math.max((params.weight - price.to), 0),

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: price.batchPrice,
			batchCount: price.batchCount,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	private getEngraving(prices: Array<Price>, params: KeyPriceParamsContainer): Array<PetPrice> {
		const source = prices
			.filter((item: Price) => item.priceKind === PriceKind.Engraving)
			.sort((a: Price, b: Price) => (a.order ?? 0) - (b.order ?? 0));
		const texts = params.engraving
			.filter(isPresent)
			.sort((a: PetEngraving, b: PetEngraving) => a.order - b.order);
		const result: Array<PetPrice> = [];

		const map = new Map<number, Price>();
		source.filter((item: Price) => item.clinicId === null)
			.forEach((item: Price) => {
				if (typeof (item.order) !== 'number') return;

				map.set(item.order, item);
			});
		source.filter((item: Price) => item.clinicId !== null)
			.forEach((item: Price) => {
				if (typeof item.order !== 'number') return;

				map.set(item.order, item);
			});

		const list = [...map.values()].sort((a: Price, b: Price) => (a.order ?? 0) - (b.order ?? 0));
		const count = Math.min(list.length, texts.length);

		for (let i = 0; i < count; i++) {
			const reference = list[i];
			result.push({
				id: -1,
				clientId: v4(),
				name: 'Engraving',

				value: reference.value,
				extra: 0,

				count: 0,
				completedCount: 0,
				done: false,

				batchPrice: 0,
				batchCount: 0,

				price: reference,
				priceId: reference.id,

				pet: null,
				petId: params.id,

				editor: null,
				editorId: null,

				pickupService: null,
				pickupServiceId: null,

				node: null,
				nodeId: null,

				note: '',
			});
		}

		return result;
	}

	// check this method
	private getProducts(prices: Array<Price>, params: KeyPriceParamsContainer, type: PriceKind): Array<PetPrice> {
		const list = new PriceFilter(params).getProduct(prices, type);
		const key = type === PriceKind.UrnPrice ? 'urns' : 'products';

		return list.map<Nullable<PetPrice>>((item: Price) => {
			if (item.priceKind === type && item.priceKind === PriceKind.ProductPrice && item.inventoryItemId === null && !params.urns.length) {
				return {
					id: -1,
					clientId: v4(),
					name: 'No Item',

					value: item.value,
					extra: 0,

					count: 1,
					completedCount: 0,
					done: false,

					batchPrice: 0,
					batchCount: 0,

					price: item,
					priceId: item.id,

					pet: null,
					petId: params.id,

					storeEntryPick: null,

					editor: null,
					editorId: null,

					pickupService: null,
					pickupServiceId: null,

					node: null,
					nodeId: null,

					note: '',
				};
			}
			if (item.priceKind === type && item.priceKind === PriceKind.UrnPrice && item.inventoryItemId === null && !params.urns.length) {
				params.urns.forEach((u) => {
					return {
						id: -1,
						clientId: v4(),
						name: 'No Item',

						value: u.value,
						extra: 0,

						count: 1,
						completedCount: 0,
						done: false,

						batchPrice: 0,
						batchCount: 0,

						price: item,
						priceId: item.id,

						pet: null,
						petId: params.id,

						editor: null,
						editorId: null,

						pickupService: null,
						pickupServiceId: null,

						node: null,
						nodeId: null,

						note: '',
					};
				});
			}

			const source = params[key].find((q: ProductContainer) => q.categoryId === item.inventoryItemId);
			if (!source) return null;

			const extra = Math.max(source.count - item.batchCount, 0) * item.value;

			return {
				id: -1,
				clientId: v4(),
				name: item.inventoryItem?.name ?? 'Unknown item',

				value: source.value,
				extra,

				count: source.count,
				completedCount: 0,
				done: false,

				batchPrice: item.batchPrice,
				batchCount: item.batchCount,

				price: item,
				priceId: item.id,

				pet: null,
				petId: params.id,

				editor: null,
				editorId: null,

				pickupService: null,
				pickupServiceId: null,

				node: null,
				nodeId: null,

				note: '',
			};
		})
			.filter((item: Nullable<PetPrice>) => item !== null) as Array<PetPrice>;
	}

	private getDiscount(prices: Array<Price>, params: KeyPriceParamsContainer): [Nullable<PetPrice>, number, number, number] {
		const price = new PriceFilter(params).getDiscount(prices);
		if (!price) return [null, this.serviceTotal, sum(this.urns), sum(this.products)];

		const manager = DiscountService.from({
			id: -1,
			clientId: v4(),
			name: price.name,

			value: 0,
			extra: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: 0,
			batchCount: 0,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		});

		const service = manager.applySingle(sum(this.services), PriceKind.SpecialServicePrice)
			+ manager.applySingle((this.base?.value ?? 0) + (this.base?.extra ?? 0), PriceKind.BasePrice);
		const product = manager.applySingle(sum(this.products), PriceKind.ProductPrice);
		const urn = manager.applySingle(sum(this.urns), PriceKind.UrnPrice);
		const [_service, _urn, _product] = manager.applyTotal(service, urn, product);

		return [manager.get(), _service, _urn, _product];
	}

	private getDelivery(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		const price = new PriceFilter(params).getDelivery(prices);
		if (!price) return price;

		return {
			id: -1,
			clientId: v4(),
			name: 'Delivery',

			value: price.value,
			extra: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: 0,
			batchCount: 0,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	private getRush(prices: Array<Price>, params: KeyPriceParamsContainer): Nullable<PetPrice> {
		if (!params.rush) return null;

		const price = new PriceFilter(params).getRush(prices);
		if (!price) return price;

		return {
			id: -1,
			clientId: v4(),
			name: 'Rush',

			value: price.value,
			extra: 0,

			count: 0,
			completedCount: 0,
			done: false,

			batchPrice: 0,
			batchCount: 0,

			price,
			priceId: price.id,

			pet: null,
			petId: params.id,

			editor: null,
			editorId: null,

			pickupService: null,
			pickupServiceId: null,

			node: null,
			nodeId: null,

			note: '',
		};
	}

	public constructor(prices: Array<Price>, services: Array<PetPrice>, products: Array<PetPrice>, params: KeyPriceParamsContainer) {
		this._base = this.getBase(prices, params);
		this._engravings = this.getEngraving(prices, params);
		this._services = services.filter((item: PetPrice) =>
			(item.price?.priceKind === PriceKind.SpecialServicePrice || item.price?.priceKind === PriceKind.PickupPrice) && !item.removed);
		this._urns = params.urns.filter((item: PetPrice) => !item.removed);
		this._products = products.filter((item: PetPrice) => item.price?.priceKind === PriceKind.ProductPrice && !item.removed);
		const [discount] = this.getDiscount(prices, params);
		this._discount = discount;
		this._delivery = this.getDelivery(prices, params);
		this._rush = this.getRush(prices, params);
		this._serviceTaxPercentage = params.summary.serviceTaxPercentage / 100;
		this._productTaxPercentage = params.summary.productTaxPercentage / 100;
	}

	public get base(): Nullable<PetPrice> {
		return this._base;
	}

	public get engravings(): Array<PetPrice> {
		return this._engravings;
	}

	public get services(): Array<PetPrice> {
		return this._services;
	}

	public get products(): Array<PetPrice> {
		return this._products;
	}

	public get urns(): Array<PetPrice> {
		return this._urns;
	}

	public get productTotal(): number {
		const products = sum(this.products);
		const urns = sum(this.urns);

		return products + urns;
	}

	public get productTaxPercentage(): number {
		return this._productTaxPercentage;
	}

	public get productTaxTotal(): number {
		const tax = this.productTaxPercentage * this.productTotal;

		return Math.max(tax, 0);
	}

	public get serviceTotal(): number {
		return (this.base?.value ?? 0) + (this.base?.extra ?? 0) + sum(this.engravings) + sum(this.services) + (this.rush?.value ?? 0);
	}

	public get serviceTaxPercentage(): number {
		return this._serviceTaxPercentage;
	}

	public get serviceTaxTotal(): number {
		const tax = this.serviceTaxPercentage * (this.serviceTotal + (this.delivery?.value ?? 0));

		return Math.max(tax, 0);
	}

	public get discount(): Nullable<PetPrice> {
		return this._discount;
	}

	public get delivery(): Nullable<PetPrice> {
		return this._delivery;
	}

	public get rush(): Nullable<PetPrice> {
		return this._rush;
	}

	public get subtotal(): number {
		return this.serviceTotal + this.productTotal - (this.discount?.value ?? 0) + (this.delivery?.value ?? 0);
	}

	public get taxTotal(): number {
		return this.serviceTaxTotal + this.productTaxTotal;
	}

	public get total(): number {
		return this.subtotal + this.taxTotal;
	}
}
