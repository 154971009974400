import {
	Action,
	bindActionCreators,
	Dispatch,
	Reducer,
} from 'redux';

import {
	getActionCreators,
	getReducer,
	IActionCreators,
	IMappedActionCreators,
	ItemState, KnownPageAction,
} from '@common/react/store/Item';
import { WithId } from '@common/typescript/objects/WithId';

import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store/index';

export type ItemActionCreators<T extends WithId> = IActionCreators<T, User, ApplicationState>;
export type MappedItemActionCreators<T extends WithId> = IMappedActionCreators<T, User, ApplicationState>;
export type KnownItemActions<T extends WithId> = KnownPageAction<T, User, ApplicationState>;

export function getItemActionCreators<T extends WithId>(): ItemActionCreators<T> {
	return getActionCreators<T, User, ApplicationState>();
}

export function bindItemActionCreators<T extends WithId>(dispatch: Dispatch<Action>): MappedItemActionCreators<T> {
	return bindActionCreators<ItemActionCreators<T>, MappedItemActionCreators<T>>(getItemActionCreators<T>(), dispatch);
}

export function getItemReducer<T extends WithId>(storageName: keyof ApplicationState): Reducer<ItemState<T>, Action> {
	return getReducer<T, User, ApplicationState>(storageName) as Reducer<ItemState<T>, Action>;
}
