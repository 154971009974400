import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import { Price, PriceKind, PriceType } from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';
import { DiscountSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { CoreSelect } from '@app/components/UI/Inputs/CoreSelect';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';

interface BaseSelectProps {
	crematoryId?: number;
	priceType: PriceType;
	serviceType: ServiceType;

	pickDefault?: (entity: Array<Price>) => number;
	isDefault?: boolean;

	disabled?: boolean;
	disabledOptions?: (item: Price) => boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;

	onSelect?: (id: number) => void;
	onDeselect?: (value: number) => void;
	allowClear?: boolean;

	label?: string;
	placeholder?: string;

	localOptions?: Array<Price>;
}

interface SingleProps extends BaseSelectProps {
	value?: number;
	onChange: (value?: number) => void;
	multiple?: false;
}

interface MultipleProps extends BaseSelectProps {
	value?: Array<number>;
	onChange: (value?: Array<number>) => void;
	multiple: true;
}

type DiscountProps = SingleProps | MultipleProps;

interface RequestParams extends SelectRequestParams<number> {
	crematoryId: number;
	clinicId: Nullable<number>;
	priceType: PriceType;
	serviceType: ServiceType;
}

const emptyValue = -1;

function isEmptyValue(value?: number | Array<number>, localOptions?: Array<Price>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;
	if (localOptions) {
		const isEmpty = localOptions.find((item) => item.id === emptyValue);

		return Boolean(!isEmpty);
	}

	return value === emptyValue;
}

export const DiscountSelect: React.FC<DiscountProps> = (props: DiscountProps) => {
	const [query, setQuery] = React.useState<string>(() => '');
	const ref = React.useRef<number>(0);

	const filters: DiscountSelectFilter = React.useMemo(() => ({
		search: query,
		name: query,
		priceKind: [PriceKind.Discount],
		crematoryId: props.crematoryId,
	}), [props.crematoryId, query]);

	const reqParams: Partial<RequestParams> = React.useMemo(() => ({
		clinicId: null,
		priceType: props.priceType,
		serviceType: props.serviceType,
	}), [props.priceType, props.serviceType]);

	React.useEffect(() => {
		if (ref.current > 0) {
			props.onChange(undefined);
		}

		ref.current++;
	}, [props.crematoryId, props.priceType, props.serviceType]);

	const properties = {
		...props,
		onSearch: setQuery,
		filters,
		reqParams,
		shouldPickDefault: () => false,
		isEmptyValue: (value) => isEmptyValue(value, props.localOptions),
		getDisabledOption: props.disabledOptions,
	};

	return <CoreSelect store="discounts" {...properties} />;
};
