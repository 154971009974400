import { Action } from 'redux';

import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';

import {
	IListAction,
	IListReceiveAction,
	IListReceiveMoreAction,
	IListRequestAction,
	IListRequestErrorAction,
	IListUpdateAction,
	isListAction, TypeKeys,
} from '@app/store/SelectList/ListActions';
import { ClinicSelectInfo } from '@app/objects/Clinic';
import {
	AdjustmentCodeFilter,
	ClinicSelectFilter,
	CountrySelectFilter,
	CrematorySelectFilter,
	DiscountSelectFilter,
	InventoryItemFilter,
	LabelPaperSelectFilter,
	InventoryParentsSelectFilter,
	LanguageSelectFilter,
	MeasuringUnitFilter,
	PetBreedSelectFilter,
	ProductSelectFilter,
	RegionSelectFilter,
	RouteSelectFilter,
	SpecialServicesSelectFilter,
	StatusSelectFilter,
	UrnCategoriesSelectFilter,
	UrnSelectFilter,
	UserSelectFilter,
	MachineSelectFilter,
	Searchable,
	StoreEntrySelectFilter,
	PickupServicesSelectFilter,
} from '@app/store/SelectList/SelectsInterfaces';
import { Status } from '@app/objects/Status';
import { PetBreed } from '@app/objects/Pet';
import { InventoryItem } from '@app/objects/Inventory';
import { State } from '@app/objects/State';
import { SpecialService } from '@app/objects/SpecialService';
import { IAdjustmentCode } from '@app/objects/Adjustment';
import { MeasuringUnit } from '@app/objects/MeasuringUnits';
import {
	StoreType,
	RecordType,
} from '@app/store/SelectList/UtilityTypes';
import { Price } from '@app/objects/Price';
import { Country } from '@app/objects/Country';
import { Language } from '@app/objects/Language';
import { CrematoryListInfo, CrematoryMachine } from '@app/objects/Crematory';
import { UserListInfo } from '@app/objects/User';
import { Route } from '@app/objects/Route';
import { LabelPaper } from '@app/objects/LabelPaper';
import { StoreEntry } from '@app/objects/StoreEntry';
import { PickupService } from '@app/objects/PickupService';

export interface SelectItem<T extends WithId, TFilters = null> {
	items: Array<T>;
	isLoading: boolean;
	pagination: {
		total: number;
	};
	filters: Nullable<TFilters>;
	error: Nullable<string>;
	requestId: number;
	params: Nullable<Record<string, unknown>>
}

export type SelectItemState<T extends WithId, TFilters = null> = Record<Exclude<string, keyof SelectItem<T, TFilters>>, SelectItem<T, TFilters>>;
export const GeneralKey = 'GENERAL_KEY';
export const RootKey = 'ROOT_KEY';

type ReducedSelectItem = Omit<SelectItem<WithId, unknown>, 'filters' | 'items'> & { items: Array<WithId> };
type ReducedSelectState = Record<Exclude<string, keyof ReducedSelectItem>, ReducedSelectItem>;

export interface SelectList {
	clinics: SelectItem<ClinicSelectInfo, ClinicSelectFilter>,
	crematories: SelectItem<CrematoryListInfo, CrematorySelectFilter>,
	status: SelectItem<Status, StatusSelectFilter>,
	petBreeds: SelectItem<PetBreed, PetBreedSelectFilter>,
	urns: SelectItemState<InventoryItem, UrnSelectFilter>,
	urnCategories: SelectItem<InventoryItem, UrnCategoriesSelectFilter>,
	regions: SelectItem<State, RegionSelectFilter>,
	specialServices: SelectItem<SpecialService, SpecialServicesSelectFilter>,
	pickupServices: SelectItem<PickupService, PickupServicesSelectFilter>
	pickupServicePrices: SelectItem<Price, PickupServicesSelectFilter>,
	products: SelectItemState<InventoryItem, ProductSelectFilter>,
	inventoryItems: SelectItem<InventoryItem, InventoryItemFilter>,
	adjustmentCodes: SelectItem<IAdjustmentCode, AdjustmentCodeFilter>,
	countries: SelectItem<Country, CountrySelectFilter>,
	discounts: SelectItem<Price, DiscountSelectFilter>,
	measuringUnits: SelectItem<MeasuringUnit, MeasuringUnitFilter>,
	languages: SelectItem<Language, LanguageSelectFilter>,
	users: SelectItem<UserListInfo, UserSelectFilter>,
	routes: SelectItem<Route, RouteSelectFilter>,
	labelPapers: SelectItem<LabelPaper, LabelPaperSelectFilter>,
	inventoryParents: SelectItem<InventoryItem, InventoryParentsSelectFilter>,
	machines: SelectItem<CrematoryMachine, MachineSelectFilter>,
	storeEntries: SelectItem<StoreEntry, StoreEntrySelectFilter>,
}

function getInitial<T extends WithId, TFilters = null>(): SelectItem<T, TFilters> {
	return {
		isLoading: false,
		items: [],
		pagination: {
			total: 0,
		},
		filters: null,
		error: null,
		requestId: 0,
		params: null,
	};
}

function getStateInitial<T extends WithId, TFilters = null>(): SelectItemState<T, TFilters> {
	return {
		[GeneralKey]: getInitial<T, TFilters>(),
	};
}

export function isSelectListState(state: ReducedSelectItem | ReducedSelectState): state is ReducedSelectState {
	return state.items === undefined;
}

export function getSelectItemState<
	TStore extends keyof SelectList
>(root: StoreType<TStore>, key: string = GeneralKey): RecordType<TStore> {
	const state = isSelectListState(root) ? root[key] : root;
	if (state) return state as RecordType<TStore>;

	return getInitial() as RecordType<TStore>;
}

export const initialList: SelectList = {
	clinics: getInitial(),
	crematories: getInitial(),
	status: getInitial(),
	petBreeds: getInitial(),
	urns: getStateInitial(),
	urnCategories: getInitial(),
	regions: getInitial(),
	specialServices: getInitial(),
	pickupServices: getInitial(),
	pickupServicePrices: getInitial(),
	products: getStateInitial(),
	inventoryItems: getInitial(),
	adjustmentCodes: getInitial(),
	countries: getInitial(),
	discounts: getInitial(),
	measuringUnits: getInitial(),
	languages: getInitial(),
	users: getInitial(),
	routes: getInitial(),
	labelPapers: getInitial(),
	inventoryParents: getInitial(),
	machines: getInitial(),
	storeEntries: getInitial(),
};

export function selectListReducer<T extends WithId, TFilters = null>(state: SelectItem<T, TFilters>, action: IListAction): SelectItem<T, TFilters> {
	switch (action.type) {
	case TypeKeys.REQUESTITEMS: {
		const { payload }: IListRequestAction<TFilters> = action as IListRequestAction<TFilters>;

		return {
			...state,
			isLoading: true,
			error: null,
			filters: payload.filters,
			requestId: payload.requestId,
			params: payload.params,
		};
	}
	case TypeKeys.RECEIVEITEMS: {
		const { payload }: IListReceiveAction<T> = action as IListReceiveAction<T>;

		if (state.requestId > payload.requestId) return state;

		return {
			...state,
			isLoading: false,
			items: payload.items,
			pagination: { total: payload.total },
			requestId: payload.requestId,
		};
	}
	case TypeKeys.REQUESTMOREITEMS:
		return {
			...state,
			isLoading: true,
		};
	case TypeKeys.RECEIVEMOREITEMS: {
		const { payload }: IListReceiveMoreAction<T> = action as IListReceiveMoreAction<T>;

		return {
			...state,
			items: state.items.concat(payload.items),
			isLoading: false,
			pagination: {
				total: payload.total,
			},
		};
	}
	case TypeKeys.RECEIVEERROR: {
		const { payload }: IListRequestErrorAction = action as IListRequestErrorAction;

		return {
			...state,
			isLoading: false,
			error: payload.error,
		};
	}
	case TypeKeys.UPDATEITEM: {
		const { payload }: IListUpdateAction<T> = action as IListUpdateAction<T>;
		let isNewItem = false;

		const items = state.items.map((item: T) => {
			if (item.id === payload.id) {
				return {
					...item,
					...payload,
				};
			}
			isNewItem = true;

			return item;
		});

		const result: Array<T> = isNewItem ? [...items, payload as T] : items;
		isNewItem = false;

		return {
			...state,
			items: result,
		};
	}

	default:
		return state;
	}
}

export function selectListStateReducer<
	T extends WithId,
	TFilters = null
>(state: SelectItemState<T, TFilters>, action: IListAction) {
	const key = action.key ?? GeneralKey;
	const oldState = state[key];
	const newState = selectListReducer(state[key] ?? getInitial(), action);

	if (oldState === newState) return state;

	return { ...state, [key]: selectListReducer(state[key] ?? getInitial(), action) };
}

export const listStoreReducer = (store: SelectList | undefined, action: Action): SelectList => {
	if (store === undefined) return initialList;

	if (!isListAction(action)) return store;

	const state = store[action.store];
	const newState = isSelectListState(state)
		? selectListStateReducer(state, action)
		: selectListReducer(state as SelectItem<WithId, Searchable>, action);

	if (action.type === TypeKeys.RESETSTORE) return { ...initialList, [action.store]: newState };

	if (state === newState) return store;

	return {
		...store,
		[action.store]: newState,
	};
};
