import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Field, FieldProps } from 'formik';
import Select from 'antd/lib/select';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import Datepicker from '@common/react/components/Forms/Datepicker/Datepicker';

import {
	getDateByTerms, Invoice, InvoiceStatus, InvoiceTerms,
} from '@app/objects/Invoice';
import { ClinicProps, FormValues } from '@app/components/Pages/Clinics/ClinicTabs/Invoices/InvoiceEditor/InvoiceEditorProps';
import { ClinicSelectInfo } from '@app/objects/Clinic';
import { ClinicSelect } from '@app/components/UI/Inputs/ClinicSelect';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { ApplicationState } from '@app/store';
import { useUserLocalPreferences } from '@app/hooks/useUserLocalPreferenses';

const { Option } = Select;

const selectKey = 'helpers.select';

const onTermsChange = (invoiceTerm: number, fieldProps: FieldProps<FormValues>) => {
	fieldProps.form.setFieldValue('terms', invoiceTerm);
	fieldProps.form.setFieldValue('dueDate', getDateByTerms(invoiceTerm as InvoiceTerms, +fieldProps.form.values.time));
};

export const CreationDateField: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translation');
	const { dateFormat } = useUserLocalPreferences();

	return (
		<Field name="time">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title={t('table.columns.date')}
					containerClassName="col-sm-4 form-group"
					render={({ field, form }: FieldProps<number, FormValues>) => (
						<Datepicker
							value={field.value}
							format={dateFormat}
							antdProps={{
								disabled: form.values.id > 0,
							}}
							onChange={(value) => form.setFieldValue(field.name, value)}
						/>
					)}
				/>
			)}
		</Field>
	);
};

export const ClinicField: React.FC<ClinicProps> = ({ clinic, crematoryId, isModeCreateInvoice }): JSX.Element => {
	const { t } = useTranslation('translation');
	const clinics = useSelector((state: ApplicationState) => state.selects.clinics.items);

	return (
		<div className="col-sm-4">
			<Field name="clinicId">
				{(fieldProps: FieldProps<FormValues>) => (
					<FormikInput
						fieldProps={fieldProps}

						containerClassName="form-group"
						title={`${t('table.columns.clinic')}*`}
						render={({ field, form }: FieldProps<number, FormValues>) => (
							<ClinicSelect
								value={field.value}
								onChange={(id?: number) => {
									const currentClinic = isModeCreateInvoice ? clinics.find((i) => i.id === id) : clinic;
									form.setFieldValue(field.name as keyof FormValues, id, false);
									form.setFieldValue('clinic', currentClinic);
									form.setFieldValue('pets', []);
								}}
								pickDefault={(store: Array<ClinicSelectInfo>) => isPickDefault(store)}
								allowClear={false}
								crematoryId={crematoryId}
								customers
								disabled={!!clinic}
								placeholder={`${t(selectKey)} ${t('table.columns.clinic')}`}
							/>
						)}
					/>
				)}
			</Field>
		</div>
	);
};

export const SelectTermsField: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translation');

	return (
		<Field name="terms">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title={t('table.columns.terms')}
					containerClassName="col-sm-4 form-group"
					render={({ field, form }: FieldProps<InvoiceTerms, FormValues>) => (
						<Select
							showSearch
							optionFilterProp="children"
							onChange={(invoiceTerm: number) => onTermsChange(invoiceTerm, fieldProps)}
							value={field.value}
							disabled={form.initialValues.status === InvoiceStatus.Paid}
						>
							<Option disabled value={-1} key={0}>{`${t(selectKey)} ${t('table.columns.terms')}`}</Option>
							<Option value={InvoiceTerms.DueOnReceipt}>{t('enums.terms.due-on-receipt')}</Option>
							<Option value={InvoiceTerms.Net15}>{t('enums.terms.net-15')}</Option>
							<Option value={InvoiceTerms.Net30}>{t('enums.terms.net-30')}</Option>
							<Option value={InvoiceTerms.Custom}>{t('enums.terms.custom')}</Option>
						</Select>
					)}
				/>
			)}
		</Field>
	);
};

export const DueDateField: React.FC = (): JSX.Element => {
	const { t } = useTranslation('translation');
	const { dateFormat } = useUserLocalPreferences();

	return (
		<Field name="dueDate">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title={t('table.columns.due-date')}
					containerClassName="col-sm-4 form-group"
					render={({ field, form }) => (
						<Datepicker
							value={field.value}
							format={dateFormat}
							onChange={(date) => form.setFieldValue(field.name, date)}
							antdProps={{
								placeholder: t('table.columns.due-date'),
								disabled: form.values.terms !== InvoiceTerms.Custom,
							}}
						/>
					)}
				/>
			)}
		</Field>
	);
};

export const SelectStatusField: React.FC<{item: Invoice}> = ({ item }): JSX.Element => {
	const { t } = useTranslation('translation');

	return (
		<Field name="status">
			{(fieldProps: FieldProps<FormValues>) => (
				<FormikInput
					fieldProps={fieldProps}
					title={t('table.columns.status')}
					containerClassName="col-sm-4 form-group"
					render={({ form, field }: FieldProps<InvoiceStatus, FormValues>) => (
						<Select
							onChange={(e:InvoiceStatus) => form.setFieldValue(field.name, e)}
							value={field.value}
							disabled={item.status === InvoiceStatus.Paid}
						>
							<Option disabled value={-1} key={0}>{`${t(selectKey)} ${t('table.columns.status')}`}</Option>
							<Option value={InvoiceStatus.Open}>{t('enums.invoice-status.open')}</Option>
							<Option value={InvoiceStatus.Paid}>{t('enums.invoice-status.paid')}</Option>
						</Select>
					)}
				/>
			)}
		</Field>
	);
};

export const PdfField = ({
	isChecked, isDisable = false, onChange, title,
}): JSX.Element => {
	if (!isChecked)onChange(isChecked);

	return (
		<Checkbox
			checked={isChecked}
			onChange={(value: CheckboxChangeEvent) => onChange(value.target.checked)}
			disabled={isDisable}
		>
			{title}
		</Checkbox>
	);
};
