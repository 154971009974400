import * as React from 'react';

import * as moment from 'moment';
import {DatePickerProps, RangePickerProps} from 'antd/lib/date-picker/interface';
import DatePicker  from 'antd/lib/date-picker';


const { RangePicker } = DatePicker;

const disabledAfterToday = (currentDate: moment.Moment | null) => currentDate ? currentDate.isAfter(moment(), 'day') : false;

const disabledBeforeToday = (currentDate: moment.Moment | null) => currentDate ? currentDate.isBefore(moment(), 'day') : false;

export const disabledFrom = (from: number | null, withoutToday?: boolean) => (currentDate?: moment.Moment) => (
	currentDate ? (!withoutToday && currentDate.isBefore(moment(), 'day') || (from !== null ? currentDate.isBefore(moment(from), 'day') : false)) : false
);

export const disabledTo = (to: number | null, withoutToday?: boolean) => (currentDate?: moment.Moment) => (
	currentDate ? (!withoutToday && currentDate.isAfter(moment(), 'day') || (to !== null ? currentDate.isAfter(moment(to), 'day') : false)) : false
);

interface ComponentProps {
	format?: string;
	disabledDate?: (currentDate: moment.Moment | null) => boolean;
	maxDateToday?: boolean;
	minDateToday?: boolean;
	locale?: any;
	dateRender?: (current: moment.Moment, today: moment.Moment) => React.ReactNode;
}

export interface DatepickerProps extends ComponentProps {
	value: number | null;
	onChange: (date: number | null) => void;
	antdProps?: DatePickerProps;
}

interface RangepickerProps extends ComponentProps {
	value:  [number, number] | null;
	onChange: (date: [number, number] | null) => void;
	antdProps?: RangePickerProps;
}

const Datepicker: React.FC<DatepickerProps> =
	({ value, onChange, format = 'MM/DD/YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {}, dateRender}) => {
		// tslint:disable-next-line:no-parameter-reassignment
		if (maxDateToday) disabledDate = disabledAfterToday;

		// tslint:disable-next-line:no-parameter-reassignment
		if (minDateToday) disabledDate = disabledBeforeToday;

		const defaultValue = value !== null && value !== undefined ? moment(value) : undefined;

		const innerOnChange = (date: moment.Moment | null) => {
			onChange(date === null ? null : +date);
		};

		return <div className="datepicker-component">
			<DatePicker
				{...antdProps}
				format={antdProps && antdProps.showTime ? 'MM/DD/YYYY HH:mm' : format}
				onChange={innerOnChange}
				disabledDate={disabledDate}
				locale={locale}
				value={defaultValue}
				getCalendarContainer={antdProps.getCalendarContainer}
				dateRender={dateRender}
			/>
		</div>;
	};

export const Rangepicker: React.FC<RangepickerProps> =
	({value, onChange, format = 'MM/DD/YYYY', maxDateToday, minDateToday, disabledDate, locale, antdProps = {}}) => {
		const innerOnChange = (date: any) => {
			onChange(date.length === 2 ? [+date[0], +date[1]] : null);
		};

		// tslint:disable-next-line:no-parameter-reassignment
		if (maxDateToday) disabledDate = disabledAfterToday;

		// tslint:disable-next-line:no-parameter-reassignment
		if (minDateToday) disabledDate = disabledBeforeToday;

		let defaultValue;

		if (value !== null && value[0] !== null && value[0] !== undefined && value[1] !== null && value[1] !== undefined ) {
			defaultValue = [moment(value[0] as number), moment(value[1] as number)];
		} else {
			defaultValue = [undefined, undefined];
		}

		return <div className="datepicker-component">
			<RangePicker
				{...antdProps}
				format={antdProps && antdProps.showTime ? 'MM/DD/YYYY HH:mm' : format}
				onChange={innerOnChange}
				disabledDate={disabledDate}
				locale={locale}
				defaultValue={defaultValue}
				getCalendarContainer={antdProps.getCalendarContainer}
			/>
		</div>;
	};

export default Datepicker;
