// enums.ts
/**
 * ## Enums.ts ##
 * This file contains some utility functions for utilities
 * @packageDocumentation
 */

/**
 * pureEnum - makes TS enum 'pure' one: TS enums have both string and numeric values; 'Pure' enum only has string keys and numeric values.
 * TypeScript doesn't support enum decorators
 * So they are defined and used as pure functions
 * @typeParam T - enum type
 * @param enumeration - enum instance
 * @returns T
 */
export function pureEnum<T extends Record<string, unknown>>(enumeration: T): T {
	Object.keys(enumeration)
		.filter((key: string) => !Number.isNaN(Number(key)))
		.forEach((key) => {
			const descriptor = Object.getOwnPropertyDescriptor(enumeration, key);

			if (descriptor) {
				descriptor.enumerable = false;
				Object.defineProperty(enumeration, key, descriptor);
			}
		});

	return enumeration;
}
