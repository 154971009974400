import {HubConnection} from '@aspnet/signalr';

let hub: HubConnection | null = null;

export const getSignalR = (): Promise<HubConnection> => {
	if (hub) { 
		return Promise.resolve(hub);
	} 
	
	return import('@aspnet/signalr' /* webpackChunkName: "signalR" */).then((signalR) => {
		hub = new signalR.HubConnectionBuilder()
			.withUrl('/notificationHub')
			.build();

		hub.start().catch(err => console.error(err));
		
		return Promise.resolve(hub);
	}).catch((e) => {
		console.error(e);
		return Promise.reject(e);
	});
};