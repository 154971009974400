import * as React from 'react';

import CoreSelect, { OptionProps } from 'antd/lib/select';

import {
	FilterOptionHook,
	SelectMode,
	SelectProps,
	SelectSize,
} from '@app/components/UI/Select/SelectProps';

type BaseOptionType = number | string;
type AntdFilterOptionHook = (query: string, option: React.ReactElement<OptionProps>) => boolean;

function toAntdMode(mode?: SelectMode): 'default' | 'multiple' {
	switch (mode) {
	case SelectMode.Single:
		return 'default';

	case SelectMode.Multilple:
		return 'multiple';

	default:
		return 'default';
	}
}

function toAntdSize(size?: SelectSize): 'large' | 'default' | 'small' {
	switch (size) {
	case SelectSize.Large:
		return 'large';

	case SelectSize.Small:
		return 'small';

	default:
		return 'default';
	}
}

function toAntdFilterOption(hook?: boolean | FilterOptionHook): boolean | AntdFilterOptionHook {
	if (typeof hook === 'undefined') return false;
	if (typeof hook === 'boolean') return hook as boolean;

	return (query: string, option: React.ReactElement<OptionProps>): boolean => {
		const key = option.props.value;
		if (typeof key !== 'number') return false;

		return hook(query, key);
	};
}

const showActions = ['click', 'focus'];

export const Select = <T extends BaseOptionType>(props: SelectProps<T>): React.ReactElement<SelectProps<T>> => {
	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<CoreSelect<T>
			placeholder={props.placeholder}
			title={props.title}
			mode={toAntdMode(props.mode)}
			disabled={props.disabled}
			loading={props.loading}
			allowClear={props.showClear}
			showSearch={props.showSearch}
			showAction={showActions}

			className={props.className}
			style={props.style}
			size={toAntdSize(props.size)}

			value={props.value}
			onChange={props.onChange}

			onSelect={props.onSelect}
			onDeselect={props.onDeselect}
			onSearch={props.onSearch}
			onBlur={props.onBlur}
			onFocus={props.onFocus}
			onPopupScroll={props.onPopupScroll}
			onInputKeyDown={props.onInputKeyDown}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}

			filterOption={toAntdFilterOption(props.filterOption)}

			suffixIcon={props.suffixIcon}
			removeIcon={props.removeIcon}
			clearIcon={props.clearIcon}
			menuItemSelectedIcon={props.menuItemSelectedIcon}
		>
			{props.children}
		</CoreSelect>
	);
};
