import { Nullable } from '@common/typescript/objects/Nullable';

import { WithAddress } from '@app/objects/WithAddress';

export function getStateName(from: WithAddress): Nullable<string> {
	if (!from.state) return null;
	if (typeof from.state === 'string') return from.state;

	return from.state.name;
}

export function formatAddress(entity: WithAddress): string {
	const list: Array<string> = [];

	list.push(entity.streetAddress);

	if (entity.streetAddress2) {
		list.push(entity.streetAddress2);
	}

	list.push(entity.city);

	const state = getStateName(entity);
	if (state) {
		list.push(state);
	}

	list.push(entity.zip);

	return list.join(', ');
}
