import * as React from 'react';

import CommonContext from '@common/react/components/ServiceContainer';

interface OwnProps {
	message: string | null;
	children?: JSX.Element;
}

interface InjectedProps {
	display: (message: string) => void;
}

type ComponentProps = OwnProps & InjectedProps;

const MessageComponent: React.FC<ComponentProps> = (props: ComponentProps) => {
	const {display, message, children} = props;
	
	React.useEffect(() => {
		if (message) {
			display(message);
		}
	}, [message]);
	
	return children || null;
};

export const ErrorMessageComponent: React.FC<OwnProps> = (props: OwnProps) => {
	const {message, children} = props;
	
	return (
		<CommonContext.Consumer>
			{
				container => 
					<MessageComponent display={container.messages.error} message={message}>
						{children}
					</MessageComponent>
			}
		</CommonContext.Consumer>
	);
};

export const SuccessMessageComponent: React.FC<OwnProps> = (props: OwnProps) => {
	const {message, children} = props;
	
	return (
		<CommonContext.Consumer>
			{
				container =>
					<MessageComponent display={container.messages.success} message={message}>
						{children}
					</MessageComponent>
			}
		</CommonContext.Consumer>
	);
};
