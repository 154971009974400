// eslint-disable-next-line max-classes-per-file
import { connect, ConnectedComponent } from 'react-redux';
import * as React from 'react';
import { Dispatch } from 'redux';

import { ItemsState } from '@common/react/store/ItemList';
import {
	ItemsPage as BaseItemsPage,
	ExtendableItemsPage as BaseExtendableItemsPage,
	ItemsPageReduxProps as BaseItemsPageReduxProps,
	ItemsPageReduxActions as BaseItemsPageReduxActions, ItemsPageRouteProps, PageProps,
} from '@common/react/components/Pages/ItemsPage';
import {
	EditableItemsPageState,
	ExtendableEditableItemsPage,
	EditableItemsPage as BaseEditableItemsPage,
} from '@common/react/components/Pages/EditableItemsPage';
import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { bindItemsActionCreators } from '@app/store/ItemList';

import { deleteConfirmation } from '@app/components/UI/Modal/DeleteConfirmation';
import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { alertMessage, MessageType } from '@app/utilities/alert';

export abstract class ExtendableItemsPage<TEntity extends WithId, TPropsExtension, TState = Record<string, unknown>>
	extends BaseExtendableItemsPage<TEntity, TPropsExtension, TState, User, ApplicationState> {
	protected deletePath: Nullable<string> = null;

	handleDelete(event: React.MouseEvent<HTMLButtonElement>, item: TEntity, onCompleted?: () => void): void {
		event.preventDefault();

		deleteConfirmation({
			title: 'item',
			callback: () => {
				return this.props.actions.removeItem(
					this.store,
					this.path,
					this.deletePath ?? this.type,
					{ ...item, ...{ children: null, files: null } },
				)
					.then(() => onCompleted?.())
					.catch((error: string) => alertMessage(MessageType.error, error));
			},
		});
	}
}

export abstract class EditableItemsPage<
	T extends WithId,
	TState extends EditableItemsPageState<T> = EditableItemsPageState<T>
	> extends BaseEditableItemsPage<T, TState, User, ApplicationState> {}

export abstract class ItemsPage<
	TEntity extends WithId,
	TState extends Record<string, unknown> = Record<string, unknown>
> extends BaseItemsPage<TEntity, TState, User, ApplicationState> {}

export { ExtendableEditableItemsPage };

export type ItemsPageReduxProps<T extends WithId> = BaseItemsPageReduxProps<T>;
export type ItemsPageReduxActions<T extends WithId> = BaseItemsPageReduxActions<T, User, ApplicationState>;
export type ItemsPageProps<T extends WithId> = PageProps<T, User, ApplicationState>;

export function itemsPageMapDispatchToProps<T extends WithId>(dispatch: Dispatch): ItemsPageReduxActions<T> {
	return {
		actions: bindItemsActionCreators<T>(dispatch),
	};
}

type ConnectedType<T extends WithId> = ConnectedComponent<React.ComponentType<ItemsPageProps<T>>, ItemsPageProps<T>>;
export function connectItemsPage<T extends WithId>(
	storeName: keyof ApplicationState,
	itemsPage: React.ComponentType<ItemsPageProps<T>>,
): ConnectedType<T> {
	return connect<ItemsPageReduxProps<T>, ItemsPageReduxActions<T>, ItemsPageRouteProps, ApplicationState>(
		(store: ApplicationState) => ({ items: store[storeName] as unknown as ItemsState<T> }),
		itemsPageMapDispatchToProps,
	)(itemsPage);
}
