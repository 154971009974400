import * as React from 'react';

import { FileInterface } from '@common/typescript/objects/FileInterface';
import { ImageComponent } from '@common/react/components/UI/Image/Image';
import clsx from 'clsx';

interface FilePreviewProps {
	file: FileInterface;
	fallback?: string;

	onDelete?: () => void;
	onPreview?: () => void;

	/**
	 * Allow downloading item by clicking on filename
	 */
	download?: boolean;
}

export const FilePreview: React.FC<FilePreviewProps> = (props: FilePreviewProps) => (
	<div className="file-preview">
		<div className="file-preview__image">
			<ImageComponent
				src={props.file.thumb}
				alt={props.file.name}
				fallback={props.fallback}
			/>
			<div className="file-preview__actions">
				<button
					type="button"
					className="btn btn-transparent text-primary"
					style={{ display: props.onPreview ? undefined : 'none' }}
					onClick={props.onPreview}
					title="Preview file"
				>
					<i className="fa fa-eye" />
				</button>
				<button
					type="button"
					className="btn btn-transparent text-danger"
					style={{ display: props.onDelete ? undefined : 'none' }}
					onClick={props.onDelete}
					title="Remove file"
				>
					<i className="fa fa-trash" />
				</button>
			</div>
		</div>
		<a
			href={props.file.src}
			download={props.download}
			className={clsx('file-preview__caption', !props.download && 'file-preview__caption--disabled')}
			title={props.file.name}
		>
			<i className="fa fa-paperclip" /><span>{props.file.name}</span>
		</a>
	</div>
);

FilePreview.defaultProps = {
	fallback: require('@images/no_image.jpg'),
};
