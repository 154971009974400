import { request } from '@common/react/components/Api';

export type { BaseUser, BaseUserWithAvatar, UserRole } from '@common/typescript/objects/BaseUser';

type Callback = () => void;

function defaultCallback() {
	window.location.reload();
}

export function transmute(callback: Callback = defaultCallback): Promise<void> {
	return request('transmutation', {
		id: 0,
		transmutation: false,
	})
		.then(callback);
}
