import * as React from 'react';
import { Provider } from 'react-redux';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import { ReducersMapObject } from 'redux';

import { loadableReady } from '@loadable/component';

import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import configureStore from '@common/react/configureStore';
import { IBootManager } from '@common/react/services/interfaces/IBootManager';
import { IStoreProvider } from '@common/react/services/interfaces/IStoreProvider';
import { IHistoryProvider } from '@common/react/services/interfaces/IHistoryProvider';

declare global {
	interface Window {
		initialReduxState: BaseApplicationState<BaseUser>;
	}
}

/**
 * This is a better approach to initial boot
 * Override this class to customize boot behaviour
 */
export abstract class BootManager<
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
> implements IBootManager {
	protected abstract readonly _storeProvider: IStoreProvider<TUser, TApplicationState>;
	protected abstract readonly _historyProvider: IHistoryProvider;

	protected abstract _routes: React.ReactNode;
	protected abstract _reducers: ReducersMapObject;

	/**
	 * boot()
	 * This is the public API. Should be called to launch the app. (Call from ./ClientApp/boot-client.tsx)
	 */
	public boot(): void {
		this.preBoot();
		this.inject();
	}

	/**
	 * render()
	 * This is where base services and layout are prepared
	 * It is a good place to inject Redux, Sentry, ErrorBoundary, i18next and so on.
	 * @protected
	 */
	protected render(): React.ReactElement {
		const store = this._storeProvider.store;
		const history = this._historyProvider.history;

		return (
			<AppContainer>
				<Provider store={store}>
					<Router history={history}>
						{this._routes}
					</Router>
				</Provider>
			</AppContainer>
		);
	}

	/**
	 * preBoot()                                                                                           s
	 * It is called right before injecting markup into DOM
	 * This is a good place to call initialization procedures - setup store, sentry, i18next and so on
	 * @protected
	 */
	protected preBoot(): void {
		const initialState = window.initialReduxState as TApplicationState;
		this._storeProvider.store = configureStore(this._historyProvider.history, this._reducers, initialState);
	}

	/**
	 * inject()
	 * Injection itself. I don't think this should ever be changed.
	 * @protected
	 */
	protected inject(): void {
		loadableReady(() => {
			this.beforeInject();
			const Application = this.render.bind(this);

			ReactDOM.hydrate(
				<Application />,
				document.getElementById('react-app'),
				this.afterInject,
			);
		});
	}

	// eslint-disable-next-line
	protected beforeInject(): void { }
	// eslint-disable-next-line
	protected afterInject(): void { }
}
