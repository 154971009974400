import * as React from 'react';

import {FormikErrors} from 'formik';

interface ComponentProps {
	isValid: boolean;
	submitCount: number;
	errors: FormikErrors<any>;
	formId: string;
}

const ScrollToError: React.FC<ComponentProps> = ({isValid, submitCount, errors, formId}) => {
	React.useEffect(() => {
		const firstInput = document.querySelector(`#${formId} .has-error, #${formId} .validation-message`);

		if (firstInput && firstInput.parentElement) {
			firstInput.parentElement.scrollIntoView({block: 'center'});
		}
	}, [isValid, submitCount, errors]);
	
	return null;
};

export default ScrollToError;