import * as React from 'react';
import { connect } from 'react-redux';
import {
	generatePath, Link, RouteComponentProps, withRouter,
} from 'react-router-dom';

import clsx from 'clsx';

import { member } from '@common/react/utils/decorators';

import {
	getMatchedKey,
	getSelectedKeys,
	MenuList,
	menuList,
} from '@app/components/UI/SidePanel/MenuItems';
import * as LoginState from '@app/store/Login';
import { ApplicationState } from '@app/store';
import { UserComponent } from '@app/components/UI/Header/UserComponent';
import { detailsList, DetailsList } from '@app/components/UI/Header/HeaderRoleDetails/DetailsList';
import { MenuItem } from '@app/objects/MenuItem';

import '@app/scss/ui/side-panel.scss';
import '@app/scss/transitions/fades.scss';

interface OwnProps {
	togglePanel: (state?: boolean) => void;
}

type HeaderProps = OwnProps & LoginState.LoginState & RouteComponentProps;

const params = { page: 0 };

export const dashboardTitle = (
	<>
		<div className="side-panel__clinic-dashboard-icon" />
		<div className="icon-margin" style={{ marginLeft: 5, padding: 0, display: 'inline-block' }}>Dashboard</div>
	</>
);

export enum HeaderEventType {
	Resize = 0,
}

class ClinicHeaderInner extends React.Component<HeaderProps> {
	private readonly menus: MenuList;
	private readonly details: DetailsList;

	constructor(props: HeaderProps) {
		super(props);

		this.menus = menuList;
		this.details = detailsList;
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	@member
	private togglePanel(): void {
		this.props.togglePanel();
	}

	render() {
		const { user } = this.props;
		if (!user) return null;

		const Details = this.details[user.role];

		return (
			<>
				<div className="clinic-header__top-line">
					<div className="clinic-header__left-wrap">
						{Details && <Details />}
					</div>

					<div style={{ display: 'flex' }}>
						<div className="clinic-header__user">
							<UserComponent compact />
						</div>
						<div className="side-panel-control__clinic side-panel-control_icon" onClick={this.togglePanel}>
							<i className="fa fa-bars" />
						</div>
					</div>
				</div>

				<div className="clinic-header__bottom-line">
					<div className="site-header__menu-items clinic-header__tabs">
						{this.menus[user.role].map((item: MenuItem) => {
							const selectedKeys = getSelectedKeys(this.props.location.pathname);
							const itemPathKey = getMatchedKey(item.path);

							const linkClassName = clsx('clinic-header__link', selectedKeys.includes(itemPathKey) && 'clinic-header__link--active');

							const path = generatePath(item.path, params);

							return (
								<Link
									to={path}
									className={linkClassName}
									key={path}
								>
									<span className="clinic-tab-title">{item.name}</span>
								</Link>
							);
						})}
					</div>
				</div>
			</>
		);
	}
}

export const ClinicHeader = withRouter(connect<LoginState.LoginState, RouteComponentProps, OwnProps, ApplicationState>(
	(state: ApplicationState) => state.login,
)(ClinicHeaderInner));
