import { User } from '@app/objects/User';

export enum Permission {
	CreatePet = 1,
	UpdatePet = 2,
	DeletePet = 3,

	ChangeStatus = 4,
	CompleteService = 5,

	ManageInvoices = 6,
	Quickbooks = 7,
	ManageUsers = 8,
}

export function activePermission(user: User | null, permission: Permission): boolean {
	return Boolean(user?.permissions.find((i) => i.permission === permission));
}
