import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { ImageComponent } from '@common/react/components/UI/Image/Image';

import { Crematory, CrematoryListInfo } from '@app/objects/Crematory';
import {
	isPickDefault,
	isEqualCrematory,
} from '@app/store/SelectList/SelectsInterfaces';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { request } from '@app/components/Api';
import { CrematorySelect } from '@app/components/UI/Inputs/CrematorySelect';
import { ApplicationState } from '@app/store';
import { bindLoginActionCreators } from '@app/store/Login';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { noop } from '@app/services/debug';

interface SingleCrematoryProps {
	crematory: CrematoryListInfo;
}

const SingleCrematory = (props: SingleCrematoryProps) => (
	<div
		className="col-md-12 user-crematory-select user-crematory-select--single"
	>
		<ImageComponent src={props.crematory.avatar} alt={props.crematory.name} className="crematory-logo__img" />
		<span className="icon-margin">{`${props.crematory.name}`}</span>
	</div>
);

const actions = getActionCreators('crematories', { endpoint: 'crematoryList', equal: isEqualCrematory });

export const InfoHeader = () => {
	const dispatch = useDispatch();
	const factory = bindLoginActionCreators(dispatch);
	const reqSelect = React.useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
	const crematories = useSelector((state: ApplicationState) => state.selects.crematories);
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const prev = React.useRef<number | undefined>(undefined);

	React.useEffect(() => {
		if (activeCrematoryId !== prev.current) {
			reqSelect.refresh(null, { preselect: activeCrematoryId ? [activeCrematoryId] : undefined });
		}
	}, [activeCrematoryId]);

	if (crematories.items.length === 1 && activeCrematoryId) return <SingleCrematory crematory={crematories.items[0]} />;

	return (
		<div className="row col-md-12 user-crematory-select">
			<CrematorySelect
				value={activeCrematoryId ?? undefined}
				onChange={noop}
				onSelect={(value?: number) => {
					request<Crematory>('selectCrematory', { crematoryId: value })
						.then(() => {
							prev.current = value;
							factory.updateUser({ activeCrematoryId: value });

							// clean redux
							bindActionCreators(getActionCreators('crematories', { endpoint: '' }), dispatch).resetStore();
						})
						.catch((error: string) => alertMessage(MessageType.error, `Error. ${error}`));
				}}
				placeholder="Select Crematory"
				pickDefault={(store: Array<Crematory>) => isPickDefault(store)}
				allowClear={false}
				withLogo
			/>
		</div>
	);
};
