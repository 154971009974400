import * as React from 'react';
import clsx from 'clsx';

import { ImageComponent } from '@common/react/components/UI/Image/Image';

import noImage from '@images/no_image.jpg';
import '@app/scss/ui/urn-preview.scss';

interface PreviewProps {
	image?: string;
	onClose: () => void;
}

export const Preview: React.FC<PreviewProps> = (props: PreviewProps) => {
	React.useEffect(() => {
		document.body.classList.toggle('no-scroll', Boolean(props.image));
	}, [props.image]);

	return (
		<div className={clsx('urn-preview', props.image && 'urn-active')} onClick={props.onClose}>
			<div className="urn-preview__container">
				<ImageComponent
					src={props.image ?? ''}
					fallback={noImage}
				/>
				<button
					type="button"
					className="action-container"
					onClick={props.onClose}
				>
					<i className="fa fa-times action_link fa-2x" />
				</button>
			</div>
		</div>
	);
};
