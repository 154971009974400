import * as React from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import { ImageComponent } from '@common/react/components/UI/Image/Image';
import { Loading } from '@common/react/components/UI/Loading/Loading';

import {
	getUserName, User, UserRole, userRoleName,
} from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { LoginState } from '@app/store/Login';
import { MenuActionDropdown } from '@app/components/UI/Header/MenuActionsDropdown';

import '@app/scss/ui/blocks/user-block.scss';

interface UserComponentProps {
	compact?: boolean;
	light?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export const UserComponent: React.FC<UserComponentProps> = (props: UserComponentProps) => {
	const { user, transmuted } = useSelector<ApplicationState, LoginState>((state: ApplicationState) => state.login);
	const [menu, setMenu] = React.useState<boolean>(() => false);
	const [curUser, setCurUser] = React.useState<User | null>(null);

	React.useEffect(() => {
		if (user) setCurUser(user);
	}, [user]);

	if (!curUser) return <Loading />;

	return (
		<div
			className={clsx(
				'user-block',
				props.className,
				props.compact && 'user-block_compact',
				props.light && 'user-block_light',
			)}
			style={props.style}
		>
			<ImageComponent
				className="user-block__avatar"
				src={curUser.avatar}
				alt={`${curUser.firstName} ${curUser.lastName}`}
			/>
			<div className="user-block__user-info">
				<span className={clsx(
					'user-block__user-info__name',
					(curUser.role === UserRole.ClinicUser || curUser.role === UserRole.ClinicManager) && 'name__clinic',
				)}
				>
					{getUserName(user)}
				</span>
				<span
					className={clsx(
						'user-block__user-info__role',
						(curUser.role === UserRole.ClinicUser || curUser.role === UserRole.ClinicManager) && 'role__clinic',
					)}
				>
					{userRoleName(curUser.role)}
				</span>
			</div>
			<MenuActionDropdown
				transmuted={transmuted}
				isMenuOpen={menu}
				setMenuOpen={setMenu}
				className="user-block__action"
			/>
		</div>
	);
};
