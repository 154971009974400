import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { Form, Formik, FormikProps } from 'formik';
import { v4 } from 'uuid';

import { member } from '@common/react/utils/decorators';
import { List } from '@common/typescript/objects/List';
import ScrollToError from '@common/react/components/Forms/ScrollToError';
import { ItemState } from '@common/react/store/Item';
import * as PagesState from '@common/react/store/ItemList';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { ItemEditorDefaultState } from '@common/react/components/Pages/ItemEditor';
import { Nullable } from '@common/typescript/objects/Nullable';
import { getShallowChanges, pickDifference } from '@common/typescript/utils/entity';

import { ApplicationState } from '@app/store';
import { Actions, dispatchToProps, ExtendableItemEditor } from '@app/components/Pages/ItemEditor';
import { request } from '@app/components/Api';
import {
	DeliveryType,
	getDefaultPet,
	Pet,
	PetInvoiceSummary,
	PetListInfo,
	PetOnHold,
	PetPrice,
	ServiceType,
	WeightSource,
} from '@app/objects/Pet';
import { Clinic, ClinicListInfo, ClinicSelectInfo } from '@app/objects/Clinic';
import { Price, PriceKind, PriceType } from '@app/objects/Price';
import {
	CrematoryListInfo,
	CrematoryServiceTypePreferences,
	getDefaultCrematoryServiceTypePreferences,
	getServiceTypePreferenceKind,
} from '@app/objects/Crematory';
import { CurrentStatus } from '@app/objects/CurrentStatus';
import { User, UserRole } from '@app/objects/User';
import { Location } from '@app/objects/Location';
import { Status } from '@app/objects/Status';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { OldPetStatusHistory } from '@app/components/Pages/PetEditor/OldPetStatusHistory';
import { Carrier } from '@app/objects/Carriers';
import {
	CreatePetMessage,
	PetFormValues,
	ReturnValues,
	UpdatePetMessage,
} from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { petValidationSchema } from '@app/components/Pages/PetEditor/OldPetEditor/schemas';
import { toCreateMessage, toUpdateMessage, updateSpecialServiceKit } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import {
	SpecialServiceSection,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceSection';
import { OwnerInfoSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/OwnerInfoSection';
import { DeliverySection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/DeliverySection';
import { PetInfoSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/PetInfoSection';
import { EditorHeader } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/EditorHeader';
import { TopSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/TopSection';
import { ClinicSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ClinicSection';
import { ProcessSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProcessSection';
import { ProductsSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProductsSection';
import { TrackChange } from '@app/smart components/Tracker/TrackChange';

import { PricingManager, Values } from '@app/services/pricing/PricingManager';
import { OldPetEditorTabs } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetEditorTabs';
import { getWeight } from '@app/components/Utils/Prices/Helpers';
import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { ExistingPriceStack } from '@app/services/pricing/ExistingPriceStack';
import { DocumentsSection } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/DocumentsSection/DocumentsSection';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { activePermission, Permission } from '@app/objects/Permission';
import { toUpperCase } from '@app/components/Utils/Utils';

interface ReduxProps {
	clinics: PagesState.ItemsState<ClinicListInfo>;
	crematories: PagesState.ItemsState<CrematoryListInfo>;
	pets: PagesState.ItemsState<PetListInfo>;
	locations: PagesState.ItemsState<Location>;
	user: User | null;
	carriers: PagesState.ItemsState<Carrier>;
	crematoryList: Array<CrematoryListInfo>;
	clinicsList: Array<ClinicSelectInfo>;
}

interface EditorState extends ItemEditorDefaultState {
	prices: Array<Price>;
	servicesPrice: Array<Price>;
	disableHistory: boolean;
	serviceTypePreferences: CrematoryServiceTypePreferences;
}

function toValues(values: PetFormValues, source: WeightSource): Values {
	const urns: Array<PetPrice> = values.urns ?? [];
	const products: Array<PetPrice> = values.products ?? [];
	const summary: PetInvoiceSummary = values.invoiceSummary ?? {
		servicesTotal: 0,
		productsTotal: 0,
		deliveryTotal: 0,
		subTotal: 0,
		serviceTax: 0,
		serviceTaxPercentage: 0,
		productTax: 0,
		productTaxPercentage: 0,
		total: 0,
	};

	return {
		id: values.id,
		engraving: values.engraving ?? [],
		services: values.services,
		recalculatePrice: values.recalculatePrice,

		weight: getWeight(values, source),
		rush: values.rush,

		crematoryId: values.crematoryId,
		clinicId: values.clinicId,

		priceType: values.priceType,
		serviceType: values.serviceType,

		petSpecieId: values.petSpecieId,
		deliveryType: values.deliveryType,
		urns,
		products,
		discountId: values.discountId,

		summary,
	};
}

class PetEditorInner extends ExtendableItemEditor<Pet, ReduxProps, EditorState, PetFormValues> {
	public type: string = 'pet';
	public path: string = 'getPet';
	public store: keyof ApplicationState = 'pet';
	public saveItemPath: string = 'createPet';
	public updateItemPath: string = 'updatePet';

	public backPath: string = generatePath(RoutePaths.pets, { tab: 'pet-list' });
	public itemsStore: keyof ApplicationState = 'pets';
	public itemsPath: string = 'petList';

	public defaultItem: Pet = getDefaultPet();

	public additionalParams: Record<string, boolean> = {
		withInvoice: true,
		withCurrentStatus: true,
		withServices: true,
		withUrns: true,
		withBatch: true,
		withFiles: true,

		// for title info
		withPetBreed: true,
		withPetSpecie: true,
		withPetGender: true,

		// for list
		withClinic: true,
	};

	constructor(props) {
		super(props);

		this.state = {
			...this.state,
			prices: [],
			servicesPrice: [],
			disableHistory: false,
			serviceTypePreferences: getDefaultCrematoryServiceTypePreferences(),
		};
	}

	componentDidMount() {
		super.componentDidMount();
		const itemPathOrId = this.props.match ? +this.props.match.params.id : -1;
		// this need for recalculated price if we changed something on pet list for the same pet
		if (this.props.item && this.props.item?.outOfDate) {
			this.props.itemActions.loadItem(this.store, this.path, itemPathOrId, this.defaultItem, this.additionalParams, () => true)
				.then(() => this.props.itemActions.updateItem(this.store, { id: itemPathOrId, outOfDate: false }));
		}
		this.props.pagesActions.reqPages<Status>('statuses', 'statusList', 'status', { page: 1, text: '', count: 50 });
		this.props.pagesActions.reqPages<CrematoryListInfo>('crematories', 'crematoryList', 'crematory', { page: 1, text: '', count: 10 })
			.then(() => {
				const item = this.props.item ?? this.defaultItem;
				const activeCrematoryId = this.props.user?.activeCrematoryId ?? this.props.crematories.items[0].id;
				if (itemPathOrId < 0 && activeCrematoryId > 0) {
					this.loadPrices({
						crematoryId: activeCrematoryId,
						priceType: item.priceType,
						serviceType: item.serviceType,
						clinicId: item.clinicId,
					});

					if (activeCrematoryId) this.loadCrematoryDependencies(activeCrematoryId);
				}
			});
		this.props.pagesActions.reqPages<Clinic>('clinics', 'clinicList', 'clinic', {
			page: 1,
			text: '',
			count: 1000,
			column: [{
				caption: 'name',
				direction: 1,
			}],
		});

		this.props.pagesActions.reqPages<Carrier>('carriers', 'carrierList', 'carrier', {
			page: 1,
			text: '',
			count: 1000,
			column: [{
				caption: 'name',
				direction: 1,
			}],
		});
	}

	public componentDidUpdate(prevProps) {
		super.componentDidUpdate(prevProps);

		if (this.props.item === prevProps.item) return;

		const services = this.props.item?.services ?? [];
		const shouldUpdate = services.reduce((res: boolean, cur: PetPrice) => res || !cur.clientId, false);
		if (shouldUpdate) {
			services.forEach((item: PetPrice) => {
				// eslint-disable-next-line no-param-reassign
				item.clientId = v4();
			});
		}
	}

	@member
	loadPrices(params: {
		crematoryId: number,
		priceType: PriceType,
		serviceType: ServiceType,
		clinicId: number | null,
	}) {
		request<List<Price>>('priceList', {
			crematoryId: params.crematoryId,
			clinicId: [params.clinicId, null],
			priceType: params.priceType || PriceType.Wholesale,
			serviceType: params.serviceType,
			withSpecialService: true,
			withInventoryItem: true,
			count: 500,
			// eslint-disable-next-line sonarjs/cognitive-complexity
		}).then((response) => {
			const { list } = response;
			const prices = list.filter((price: Price) => !price.specialServiceId)
				.sort((a: Price, b: Price) => a.to - b.to);
			const servicesPrice = list.filter((price: Price) => price.specialServiceId)
				.sort((a: Price, b: Price) => a.to - b.to);
			const isClinicSet = params.clinicId !== null && params.clinicId > 0;

			let loadedServices = list.filter((price: Price) => price.specialServiceId !== null && price.specialServiceId > 0
				&& (isClinicSet ? (price.clinicId === +(params.clinicId as number) || price.clinicId === null) : price.clinicId === null));

			this.setState(() => ({
				prices,
				servicesPrice,
			}));

			if (isClinicSet) {
				// reduce special services overwritten by clinic
				const clinicServices = loadedServices.filter((item) => item.clinicId === +(params.clinicId as number));

				loadedServices = loadedServices.filter((item) => {
					const foundInClinic = clinicServices.find((service: Price) =>
						service.specialServiceId === item.specialServiceId && service.serviceType === item.serviceType);

					if (item.clinicId) {
						return true;
					}

					return !foundInClinic;
				});
			}
		});
	}

	@member
	reloadPrices() {
		const form = this.form.current;
		if (!form) return;

		const values = form.values;
		request<List<Price>>('priceList', {
			crematoryId: values.crematoryId,
			clinicId: [values.clinicId, null],
			priceType: values.priceType || PriceType.Wholesale,
			serviceType: values.serviceType,
			withSpecialService: true,
			withInventoryItem: true,
			count: 500,
			// eslint-disable-next-line sonarjs/cognitive-complexity
		}).then((response) => {
			const prices = response.list.filter((price: Price) => !price.specialServiceId)
				.sort((a: Price, b: Price) => a.to - b.to);
			const servicesPrice = response.list.filter((price: Price) => price.specialServiceId)
				.sort((a: Price, b: Price) => a.to - b.to);

			this.setState(() => ({
				prices,
				servicesPrice,
			}));
		});
	}

	@member
	setDefaultCarrier() {
		const form = this.form.current;
		if (!form) return;

		const values = form.values;
		if (values.crematoryId <= 0) return;

		const carriers = this.props.carriers;
		const crematory = this.props.crematories.items.find((item: CrematoryListInfo) => item.id === values.crematoryId);
		if (!crematory) return;

		if (crematory && crematory.defaultCarrier) {
			this.form.current?.setFieldValue('carrierId', crematory.defaultCarrier.id);
		} else if (carriers.items.length === 1) {
			this.form.current?.setFieldValue('carrierId', carriers.items[0].id);
		} else {
			this.form.current?.setFieldValue('carrierId', null);
		}
	}

	@member
	setDefaultDeliveryType() {
		const form = this.form.current;
		if (!form) return;

		const values = form.values;
		const setValue = (value: DeliveryType) => form.setFieldValue('deliveryType', value, false);

		if (values.crematoryId > -1) {
			const crematory = this.props.crematories.items.find((item) => item.id === values.crematoryId);
			if (!crematory) return;

			const crematoryServiceType = getServiceTypePreferenceKind(values.serviceType);

			let deliveryType = crematory.private.defaultRetailDeliveryType;

			if (values.priceType === PriceType.Retail) deliveryType = crematory?.[crematoryServiceType]?.defaultRetailDeliveryType;
			if (values.priceType === PriceType.Wholesale) {
				const clinic = this.props.clinicsList.find((i) => i.id === values.clinicId);
				const option = `default${toUpperCase(crematoryServiceType)}DeliveryType`;

				deliveryType = clinic ? clinic?.[option] : deliveryType;
			}

			setValue(deliveryType);
		}
	}

	@member
	setServiceTypePreferences() {
		const form = this.form.current;
		if (!form) return;

		const values = form.values;

		const option = getServiceTypePreferenceKind(values.serviceType);

		const crematoryId = this.props.user?.role === UserRole.Admin ? values.crematoryId : this.props.user?.activeCrematoryId;
		const crematory = this.props.crematories.items.find((i) => i.id === crematoryId);

		this.setState({ serviceTypePreferences: crematory?.[option] });
	}

	@member
	onChangeLoadPrices() {
		const form = this.form.current;
		if (!form) return;

		const petExists = form.values.id > 0;
		if (petExists) {
			this.setState({ disableHistory: !this.state.disableHistory });
		}

		this.loadPricesBySelect();
	}

	@member
	loadPricesBySelect(priceKind?: PriceKind) {
		const form = this.form.current;
		if (form === null) return;

		const values = form.values;
		const params = {
			crematoryId: values.crematoryId,
			clinicId: values.clinicId,

			priceType: values.priceType,
			serviceType: values.serviceType,
		};

		const isPriceAlreadyLoaded = this.state.prices.length;

		if (priceKind === PriceKind.SpecialServicePrice) {
			const originServices = form.initialValues.services?.filter((item: PetPrice) => item.price?.priceKind === priceKind).length;
			const updateServices = values.services?.filter((item: PetPrice) => item.price?.priceKind === priceKind).length;

			if (updateServices && originServices && (originServices <= updateServices) && isPriceAlreadyLoaded) return;
		}

		if (priceKind === PriceKind.UrnPrice) {
			const originUrns = form.initialValues.urns.length;
			const updateUrns = values.urns.length;

			if (originUrns <= updateUrns && isPriceAlreadyLoaded) return;
		}

		if (priceKind === PriceKind.ProductPrice) {
			const originServices = form.initialValues.services?.filter((item: PetPrice) => item.price?.priceKind === priceKind).length;
			const updateServices = values.products.length;

			if (updateServices && originServices && (originServices <= updateServices) && isPriceAlreadyLoaded) return;
		}

		this.loadPrices(params);
	}

	@member
	onChangeStatus(status: CurrentStatus) {
		this.props.itemActions.updateItem<Pet>('pet', {
			currentStatus: status,
		});
	}

	@member
	onUpdatePet(petId: number, services: Array<PetPrice>) {
		if (this.props.item?.services) {
			let needUpdateKit = false;
			const result: Array<PetPrice> = [...this.props.item.services];

			services.forEach((item: PetPrice) => {
				const id = result.findIndex((q: PetPrice) => q.id === item.id);
				if (id >= 0) {
					result[id] = item;
				} else {
					result.push(item);
				}

				if (item.price?.specialService?.specialServiceType === SpecialServiceType.KitService) needUpdateKit = true;
			});

			this.updateItem({
				services: needUpdateKit ? updateSpecialServiceKit(result) : result,
			});

			if (this.props.pets.items.length > 0) {
				this.props.pagesActions.refreshPages('pets', 'petList', {
					page: 1,
					withPetBreed: true,
					withSpecie: true,
					withClinic: true,
				});
			}
		}
	}

	@member
	private getNewValues(values: PetFormValues, isWholesale: boolean): ReturnValues {
		const clinicLocationId = values.clinicLocationId && +values.clinicLocationId > 0 ? +values.clinicLocationId : null;
		const clinicId = values.clinicId ? +values.clinicId : null;
		const services = [...values.services ?? [], ...values.products, ...values.urns ?? []];
		const carrierId = values.carrierId || null;

		return {
			...this.props.item,
			...values,

			clinicId: isWholesale && clinicId ? clinicId : null,
			clinicLocationId: isWholesale ? clinicLocationId : null,
			deliveryClinicId: +values.deliveryType === DeliveryType.Clinic && values.deliveryClinicId
			&& +values.deliveryClinicId > 0 ? +values.deliveryClinicId : null,
			clinic: undefined,
			deliveryClinic: null,
			clinicLocation: undefined,
			services,
			carrierId,
			discountId: values.discountId ?? null,
		};
	}

	@member
	private getOldServices(): Array<PetPrice> {
		const item = this.props.item ?? this.defaultItem;

		if (item.services && item.services.length > 0) {
			return item.services
				.filter((service: PetPrice) => service.price && service.price.specialServiceId)
				.map((service: PetPrice) => ({ ...service, removed: true }));
		}

		return [];
	}

	/**
	 * Create a new pet
	 * @param {CreatePetMessage} message - data to create pet based on
	 * @private
	 */
	@member
	private createPet(message: CreatePetMessage) {
		this.setState(() => ({
			isLoading: true,
			error: null,
		}));

		request<Pet, CreatePetMessage>(this.saveItemPath, message)
			.then((item: Pet) => {
				this.setState(() => ({ success: true }));
				const path = generatePath(RoutePaths.petEditor, { id: item.id, type: 'edit' });

				this.props.history.replace(path);
				this.props.itemActions.loadItem(this.store, this.path, item.id, this.defaultItem, this.additionalParams, () => true);
				this.props.pagesActions.addItem(this.itemsStore, item);
				this.hideSuccess();
			})
			.catch((error: string) => this.setState(() => ({ error })))
			.finally(() => this.setState(() => ({ isLoading: false })));
	}

	/**
	 * Update some or all pet fields
	 * @param {UpdatePetMessage} message
	 * @private
	 */
	@member
	private updatePet(message: Partial<UpdatePetMessage>) {
		this.setState(() => ({
			isLoading: true,
			error: null,
		}));

		request<Pet, Partial<UpdatePetMessage>>(this.updateItemPath, { ...message, ...this.additionalParams })
			.then((item: Pet) => {
				this.setState(() => ({ success: true }));

				this.props.itemActions.updateItem(this.store, item);
				this.props.pagesActions.updateItem(this.itemsStore, item);

				super.returnToList();
			})
			.catch((error: string) => this.setState(() => ({ error })))
			.finally(() => this.setState(() => ({ isLoading: false })));
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	handleSubmit(values: PetFormValues) {
		const item = this.props.item ?? this.defaultItem;
		const isWholesale = +values.priceType !== PriceType.Retail;
		const role = this.props.user?.role;

		const newValues = this.getNewValues(values, isWholesale);

		if (item.id > 0) {
			const newServices = newValues.services.filter((q: PetPrice) => q.id === -1)
				.filter((q: PetPrice) => q.price?.specialServiceId || q.price?.priceKind === PriceKind.PickupPrice);
			const updatedServices = newValues.services.filter((q: PetPrice) => q.id > 0)
				.filter((q: PetPrice) => (q.price?.specialServiceId && q.price?.priceKind === PriceKind.SpecialServicePrice) || q.price?.priceKind === PriceKind.PickupPrice)
				.filter((q1: PetPrice) => {
					const value = (item.services ?? []).find((q2: PetPrice) => q2.id === q1.id);

					return value.removed !== q1.removed || value.count !== q1.count || value.value !== q1.value;
				});

			newValues.services = [...newServices, ...updatedServices];
		} else {
			newValues.services = (values.services ?? []).filter((item: PetPrice) => !item.removed);
		}

		const { calculatedPrice } = this;

		const prices = (item.services || []).filter((service) => service.price && !service.price.specialServiceId);
		const price = prices[0];

		if (calculatedPrice.base) {
			newValues.services = this.getCalculatedPriceServices(newValues);
		} else if (price) {
			newValues.services.push({ ...price, removed: true });
		}

		if (item.id > 0) {
			const initial = toUpdateMessage(this.getInitialValues(this.props.item ?? this.defaultItem), role);
			const updated = toUpdateMessage(newValues, role);

			const message: Partial<UpdatePetMessage> = getShallowChanges(initial, updated);
			message.updateServices = pickDifference(initial.updateServices, updated.updateServices);

			this.updatePet(message);
		} else {
			const message: CreatePetMessage = toCreateMessage(newValues);

			this.createPet(message);
		}
	}

	@member
	private getCalculatedPriceServices(newValues): Array<PetPrice> {
		const { calculatedPrice } = this;
		const price = calculatedPrice?.base;

		if (price) return [...newValues.services, price];

		return [];
	}

	@member
	private getCalculatedPrice(form: FormikProps<PetFormValues>): IPriceStack {
		const values = form.values;
		const initial = form.initialValues;
		const manager = new PricingManager();

		const oldCrematory = this.getCrematory(initial);
		const newCrematory = this.getCrematory(values);

		if (newCrematory) {
			const current = toValues(values, newCrematory.weightSource);
			const original = toValues(
				initial,
				oldCrematory ? oldCrematory.weightSource : newCrematory.weightSource,
			);

			current.summary.productTaxPercentage = values.priceType === PriceType.Retail ? newCrematory.retailProductTax : newCrematory.wholesaleProductTax;
			current.summary.serviceTaxPercentage = values.priceType === PriceType.Retail ? newCrematory.retailServiceTax : newCrematory.wholesaleServiceTax;

			return manager.getPrice(current, original, this.state.prices);
		}

		return new ExistingPriceStack([]);
	}

	get calculatedPrice(): IPriceStack {
		const form = this.form.current;

		if (form) return this.getCalculatedPrice(form);

		return new ExistingPriceStack([]);
	}

	@member
	private getCrematory(item: Nullable<{ crematoryId: number }>): Nullable<CrematoryListInfo> {
		const crematories = this.props.crematories.items;

		if (this.props.user?.role === UserRole.Crematory || this.props.user?.role === UserRole.CrematoryUser) {
			const id = this.props.user?.activeCrematoryId;
			if (id === null || id <= 0) return null;

			return crematories.find((q: CrematoryListInfo) => q.id === id) ?? null;
		}

		if (!item) return null;

		if (item.crematoryId > 0) {
			return crematories.find((q: CrematoryListInfo) => q.id === item.crematoryId) ?? null;
		}

		return crematories[0] ? crematories[0] : null;
	}

	@member
	private loadCrematoryDependencies(id: number) {
		if (id <= 0) return;

		const crematory = this.props.crematories.items.find((item) => item.id === id);
		if (!crematory) return;

		this.setDefaultCarrier();
		this.setDefaultDeliveryType();

		const form = this.form.current;
		form?.setFieldValue('preferences', { country: crematory.country }, false);
	}

	// eslint-disable-next-line
	private getInitialValues(item: Pet): PetFormValues {
		const crematory = this.getCrematory(item);
		const crematoryId = crematory ? crematory.id : item.crematoryId;
		const services = item.services ?? [];
		const urns = services.filter((i) => i.price?.priceKind === PriceKind.UrnPrice) ?? [];
		const products = services.filter((i) => i.price?.priceKind === PriceKind.ProductPrice) ?? [];

		if (services.length) {
			services.forEach((q: PetPrice) => {
				// eslint-disable-next-line no-param-reassign
				q.clientId = v4();
			});
		}

		urns.forEach((urn: PetPrice) => {
			// eslint-disable-next-line no-param-reassign
			urn.entry = urn.node?.entry;
		});

		return {
			...item,

			deliveryAddress: item.deliveryAddress ?? '',
			deliveryAddress2: item.deliveryAddress2 ?? '',
			deliveryCity: item.deliveryCity ?? '',
			deliveryZip: item.deliveryZip ?? '',
			deliveryState: item.deliveryState ?? null,
			deliveryStateId: item.deliveryStateId ?? null,
			specialInstructions: item.specialInstructions ?? '',
			batchMachineLocation: item.batchMachineLocation ?? '',
			trackingNumber: item.trackingNumber ?? '',
			phone: item.phone ?? '',
			email: item.email ?? '',
			clinicName: item.clinicName ?? '',
			name: item.name ?? '',
			color: item.color ?? '',

			vet: item.vet ?? '',
			internalIdNum: item.internalIdNum ?? '',
			onHold: item.onHold ?? PetOnHold.None,

			ownerFirstName: item.ownerFirstName ?? '',
			ownerLastName: item.ownerLastName ?? '',
			ownerPhone: item.ownerPhone ?? '',
			ownerPhone2: item.ownerPhone2 ?? '',
			ownerEmail: item.ownerEmail ?? '',
			ownerAddress: item.ownerAddress ?? '',
			ownerAddress2: item.ownerAddress2 ?? '',
			ownerCity: item.ownerCity ?? '',
			ownerState: item.ownerState ?? null,
			ownerStateId: item.ownerStateId ?? null,
			ownerZip: item.ownerZip ?? '',
			ownerNotes: item.ownerNotes ?? '',

			crematoryId,
			services,
			urns,
			products,

			engraving: item.engraving || [{
				id: -1, order: 0, name: item.name, removed: false,
			}],
			preferences: crematory ? { country: crematory.country } : null,

			recalculatePrice: false,
			carrierId: item.carrierId ?? 0,

			notes: item.notes ?? [],
			loadingUrns: false,
			discountId: item.discountId ?? -1,
		};
	}

	handlerBack() {
		const state: Nullable<{prevPath: string}> = this.props.location.state as Nullable<{ prevPath: string }>;
		if (state && state.prevPath) {
			this.props.history.goBack();
		} else {
			this.props.history.push(this.backPath);
		}
	}

	@member
	private handleWeightChange() {
		const form = this.form.current;
		const values = form?.values;

		if (values) {
			const oldCrematory = this.getCrematory(this.props.item);
			const newCrematory = this.getCrematory(values);

			const oldSource = oldCrematory?.weightSource ?? WeightSource.ActualWeight;
			const newSource = newCrematory?.weightSource ?? WeightSource.ActualWeight;

			const oldWeight = this.props.item ? getWeight(this.props.item, oldSource) : 0;
			const newWeight = getWeight(values, newSource);
			const hasChanged = oldWeight !== newWeight;

			if (hasChanged && this.state.prices.length === 0) {
				this.reloadPrices();
			}
		}
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	public render() {
		const item = this.props.item ?? this.defaultItem;

		if (this.handleCheckItem()) {
			const schema = petValidationSchema(this.state.serviceTypePreferences);
			const userCanCreatePet = item.id < 0 && activePermission(this.props.user, Permission.CreatePet);
			const userCanUpdatePet = item.id > 0 && activePermission(this.props.user, Permission.UpdatePet);

			return (
				<div className="pet-editor-page no-scroll">
					<Formik
						enableReinitialize
						initialValues={this.getInitialValues(item)}
						onSubmit={this.handleSubmit}
						validationSchema={schema}
						innerRef={this.form}
					>
						{(formikBag: FormikProps<PetFormValues>) => (
							<Form
								id="pet-form"
							>
								<EditorHeader
									item={item}
									calculatedPrice={this.getCalculatedPrice(formikBag)}
									onRecalculate={() => {
										formikBag.setFieldValue('recalculatePrice', true, false);
										this.reloadPrices();
									}}
									formValues={formikBag.values}
								/>
								<Stack
									direction={StackDirection.Horizontal}
									gap={2}
									className="pet-editor-page__content scroll-container"
								>
									<Stack.LayoutItem fluid>
										<div className="card">
											<div className="card__body">
												<TrackChange
													track={formikBag.values.crematoryId}
													onChange={(id: number) => {
														if (id <= 0) return;

														this.onChangeLoadPrices();
														this.loadCrematoryDependencies(id);
														this.setServiceTypePreferences();
													}}
												/>
												<TrackChange
													track={formikBag.values.priceType}
													onChange={() => {
														this.setDefaultDeliveryType();
														this.onChangeLoadPrices();
													}}
												/>
												<TrackChange
													track={formikBag.values.serviceType}
													onChange={(value: ServiceType) => {
														this.setDefaultDeliveryType();
														this.onChangeLoadPrices();
														this.setServiceTypePreferences();

														if (value === ServiceType.Communal) {
															formikBag.setFieldValue('engraving', []);
															formikBag.setFieldValue('urns', []);
														}
													}}
												/>
												<TrackChange
													track={formikBag.values.clinicId}
													onChange={() => {
														this.onChangeLoadPrices();

														if (formikBag.values.priceType === PriceType.Wholesale) this.setDefaultDeliveryType();
													}}
												/>
												<TrackChange
													track={formikBag.values.deliveryType}
													onChange={(value: DeliveryType) => {
														const crematoryId = this.props.user?.role !== UserRole.Crematory
															? formikBag.values.crematoryId : this.props.user.activeCrematoryId;

														if (value === DeliveryType.Mail && crematoryId > 0) {
															this.setDefaultCarrier();
														}
													}}
												/>
												<TrackChange
													track={formikBag.values.actualWeight}
													onChange={this.handleWeightChange}
												/>
												<TrackChange
													track={formikBag.values.reportedWeight}
													onChange={this.handleWeightChange}
												/>
												<TrackChange
													track={formikBag.values.urns}
													onChange={() => this.loadPricesBySelect(PriceKind.UrnPrice)}
												/>
												<TrackChange
													track={formikBag.values.services}
													onChange={() => this.loadPricesBySelect(PriceKind.SpecialServicePrice)}
												/>
												<TrackChange
													track={formikBag.values.discountId}
													onChange={() => this.loadPricesBySelect()}
												/>
												<TrackChange
													track={formikBag.values.products}
													onChange={() => this.loadPricesBySelect(PriceKind.ProductPrice)}
												/>
												<ScrollToError {...formikBag} formId="pet-form" />

												<TopSection />
												<PetInfoSection />
												{formikBag.values.priceType !== PriceType.Retail && <ClinicSection />}
												<SpecialServiceSection prices={this.state.servicesPrice} />
												<OwnerInfoSection
													country={formikBag.values.preferences?.country}
													calculatedPrice={this.calculatedPrice}
												/>
												<DeliverySection
													country={formikBag.values.preferences?.country}
													prices={this.state.prices}
												/>
												{(formikBag.values.serviceType === ServiceType.Private
														|| formikBag.values.serviceType === ServiceType.SemiPrivate || formikBag.values.batchAction)
													&& <ProcessSection />}
												<ProductsSection prices={this.state.prices} onLoadPrice={this.loadPrices} />
												<DocumentsSection />
												{(userCanCreatePet || userCanUpdatePet) && this.buttons()}
												{this.messages()}
											</div>
										</div>
									</Stack.LayoutItem>
									{
										item.id > 0 && (
											<Stack.LayoutItem span={4}>
												<Stack direction={StackDirection.Vertical}>
													{
														item.onHold === PetOnHold.None && (
															<div className="card">
																<div className="card__body">
																	<OldPetStatusHistory
																		pet={item}
																		onChangeStatus={this.onChangeStatus}
																		disabled={this.state.disableHistory}
																		onRefresh={this.onUpdatePet}
																	/>
																</div>
															</div>
														)
													}
													<OldPetEditorTabs pet={item} />
												</Stack>
											</Stack.LayoutItem>
										)
									}
								</Stack>
							</Form>
						)}
					</Formik>
				</div>
			);
		}

		return <Loading />;
	}
}

export const OldPetEditor = connect<ItemState<Pet> & ReduxProps, Actions<Pet>, unknown, ApplicationState>(
	(state: ApplicationState) => ({
		...state.pet,
		clinics: state.clinics,
		crematories: state.crematories,
		pets: state.pets,
		user: state.login.user,
		locations: state.clinicLocations,
		carriers: state.carriers,
		crematoryList: state.selects.crematories.items,
		clinicsList: state.selects.clinics.items,
	}),
	dispatchToProps,
)(PetEditorInner);
