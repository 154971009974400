import { FormValuesNode } from '@app/components/Pages/Inventory/StoreEntries/StoreEntryEditor/StoreEntryEditor';
import { StoreEntry, StoreEntryNode } from '@app/objects/StoreEntry';

export function getPath(node: FormValuesNode, tree: Array<FormValuesNode>): Array<FormValuesNode> {
	const list: Array<FormValuesNode> = [];

	let current = node;
	while (current) {
		list.push(current);
		current = tree.find((item: FormValuesNode) => item.stamp === current.parentStamp);
	}

	return list;
}

export function getName(path: Array<FormValuesNode>): string {
	const segments = path.map((item: FormValuesNode) => item.name);
	if (segments.length <= 1) return segments.reverse().join(' ');

	const entity = segments.pop()!;
	const reversed = segments.reverse();
	reversed.push(entity);

	return reversed.join(' ');
}

export function getFullName(path: Array<FormValuesNode>): string {
	return path
		.map((item: FormValuesNode) => item.name)
		.reverse()
		.join(' / ');
}

export function flatten(root: FormValuesNode): Array<FormValuesNode> {
	const result: Array<FormValuesNode> = [];
	const stack: Array<FormValuesNode> = [root];

	while (stack.length) {
		const item = stack.pop();
		if (!item) return result;

		result.push(item);
		item.children.forEach((child: FormValuesNode) => stack.push(child));
	}

	return result;
}

export function remove(list: Array<FormValuesNode>, root: FormValuesNode): Array<FormValuesNode> {
	const queue: Array<FormValuesNode> = [root];
	const ids: Array<string> = [];

	while (queue.length) {
		const node = queue.pop()!;
		ids.push(node.stamp);
		queue.unshift(...node.children);
	}

	return list.map((item: FormValuesNode) => {
		if (!ids.includes(item.stamp)) return item;

		return { ...item, disabled: true };
	});
}

export function restore(list: Array<FormValuesNode>, root: FormValuesNode): Array<FormValuesNode> {
	const queue: Array<FormValuesNode> = [root];
	const ids: Array<string> = [];

	while (queue.length) {
		const node = queue.pop()!;
		ids.push(node.stamp);
		queue.unshift(...node.children);
	}

	return list.map((item: FormValuesNode) => {
		if (!ids.includes(item.stamp)) return item;

		return { ...item, disabled: false };
	});
}

export function sanitizeNodeLinks(node: StoreEntryNode): StoreEntryNode {
	return {
		...node,
		parent: null,
		children: [],
	};
}

export function toggle(list: Array<FormValuesNode>, root: FormValuesNode, removed: boolean): Array<FormValuesNode> {
	return removed ? remove(list, root) : restore(list, root);
}

export function sanitizeTreeLinks(entry: StoreEntry): StoreEntry {
	const result = {
		...entry,
		tree: entry.tree.map(sanitizeNodeLinks),
	};

	for (let i = 0; i < result.tree.length; i++) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		result.tree[i].entry = null;
		result.tree[i].value = null;
	}

	return result;
}

export function getParent(list: Array<FormValuesNode>, item: FormValuesNode) {
	return list.find((i) => i?.stamp === item?.parentStamp);
}
