import { TFunction } from 'react-i18next';

import { DueDate, InvoiceStatus } from '@app/objects/Invoice';
import { UserRole } from '@app/objects/User';
import {
	HasIssues,
	PetOnHold,
	PetSource,
	ServiceType,
	WeightSource,
} from '@app/objects/Pet';
import {
	DiscountType,
	PriceType,
	getPriceKindName,
	PriceKind,
} from '@app/objects/Price';
import { TriggerAction, TriggerEvent } from '@app/objects/TriggerStatus';
import { DemoRequestStatus, TypeOfCrematory } from '@app/objects/DemoRequest';
import { AdjustmentAction, AdjustmentValueType, InvoiceAdjustmentType } from '@app/objects/Adjustment';
import { FunctionsGroup, StatusFunction, statusOrderFunctionDescriptions } from '@app/objects/StatusOrderFunction';
import { BatchActionType } from '@app/objects/BatchAction';
import {
	CrematoryListInfo, CrematoryType, WeightUnits, getDefaultCrematory,
} from '@app/objects/Crematory';
import { ItemCategory } from '@app/objects/Inventory';
import { LengthUnit, Orientation } from '@app/objects/LabelPaper';
import { AttachedRouteClinic } from '@app/objects/Route';
import { QuickbooksItemType } from '@app/objects/Quickbooks';
import { Option } from '@app/components/UI/Inputs/LocalSelect';

export const userRoles = [{
	text: 'Admin',
	value: `${UserRole.Admin}`,
},
{
	text: 'Clinic User',
	value: `${UserRole.ClinicUser}`,
},
{
	text: 'Clinic Manager',
	value: `${UserRole.ClinicManager}`,
},
{
	text: 'Crematory',
	value: `${UserRole.Crematory}`,
},
{
	text: 'Crematory User',
	value: `${UserRole.CrematoryUser}`,
}];

export const crematoryUserRoles = [
	{
		text: 'Crematory',
		value: `${UserRole.Crematory}`,
	},
	{
		text: 'Crematory User',
		value: `${UserRole.CrematoryUser}`,
	},
	{
		text: 'Clinic User',
		value: `${UserRole.ClinicUser}`,
	},
	{
		text: 'Clinic Manager',
		value: `${UserRole.ClinicManager}`,
	},
];

const adminServiceTypeOptions: Record<Exclude<ServiceType, ServiceType.All>, Option> = {
	[ServiceType.Communal]: { text: 'Communal', value: ServiceType.Communal.toString() },
	[ServiceType.SemiPrivate]: { text: 'Semiprivate', value: ServiceType.SemiPrivate.toString() },
	[ServiceType.Private]: { text: 'Private', value: ServiceType.Private.toString() },
	[ServiceType.Storage]: { text: 'Storage', value: ServiceType.Storage.toString() },
};

export const adminServiceTypes = [
	adminServiceTypeOptions[ServiceType.Communal],
	adminServiceTypeOptions[ServiceType.SemiPrivate],
	adminServiceTypeOptions[ServiceType.Private],
	adminServiceTypeOptions[ServiceType.Storage],
];

export const serviceTypes = (crematory?: CrematoryListInfo): Array<Option> => {
	const types: Array<Option> = [];

	if (crematory?.communal.useServiceType) types.push({ text: 'Communal', value: `${ServiceType.Communal}` });

	if (crematory?.semiPrivate.useServiceType) types.push({ text: 'Semiprivate', value: ServiceType.SemiPrivate.toString() });

	if (crematory?.private.useServiceType) types.push({ text: 'Private', value: `${ServiceType.Private}` });

	if (crematory?.storage.useServiceType) types.push({ text: 'Storage', value: ServiceType.Storage.toString() });

	return types;
};

export const serviceTypesWithAll = [
	{
		text: 'All',
		value: `${ServiceType.All}`,
	},
	{
		text: 'Communal',
		value: `${ServiceType.Communal}`,
	},
	{
		text: 'Semiprivate',
		value: `${ServiceType.SemiPrivate}`,
	},
	{
		text: 'Private',
		value: `${ServiceType.Private}`,
	},
	{
		text: 'Storage',
		value: `${ServiceType.Storage}`,
	},
];

export const priceType = (t) => ([
	{
		text: t('enums.price-type.wholesale'),
		value: `${PriceType.Wholesale}`,
	},
	{
		text: t('enums.price-type.retail'),
		value: `${PriceType.Retail}`,
	},
]);

export const priceTypeWithAll = (t) => ([
	{
		text: t('enums.price-type.all'),
		value: `${PriceType.All}`,
	},
	{
		text: t('enums.price-type.wholesale'),
		value: `${PriceType.Wholesale}`,
	},
	{
		text: t('enums.price-type.retail'),
		value: `${PriceType.Retail}`,
	},
]);

export const flagStatus = [
	{
		text: 'Active',
		value: 1,
	},
	{
		text: 'Disabled',
		value: 0,
	}];

export const invoiceStatus = [
	{
		text: 'Open',
		value: InvoiceStatus.Open,
	},
	{
		text: 'Paid',
		value: InvoiceStatus.Paid,
	}];

export const petSource = [
	{
		text: 'Internal',
		value: PetSource.Internal,
	},
	{
		text: 'External',
		value: PetSource.External,
	},
];

export const hasIssues = [
	{
		text: 'Without issues',
		value: HasIssues.WithoutIssues,
	},
	{
		text: 'With issues',
		value: HasIssues.WithIssues,
	},
];

export const weightSource = [
	{
		text: 'Reported weight',
		value: WeightSource.ReportedWeight,
	},
	{
		text: 'Actual weight',
		value: WeightSource.ActualWeight,
	},
];

export const alwaysRaiseModal = [
	{
		text: 'Once',
		value: Number(false),
	},
	{
		text: 'Always',
		value: Number(true),
	},
];

export const triggerAction = [
	{
		text: 'Actual Weight Popup',
		value: TriggerAction.ActualWeightPopup,
	},
	{
		text: 'Urn Popup',
		value: TriggerAction.UrnPopup,
	},
	{
		text: 'Product Popup',
		value: TriggerAction.ProductPopup,
	},
	{
		text: 'Tracking Popup',
		value: TriggerAction.TrackingPopup,
	},
	{
		text: 'Internal Id Popup',
		value: TriggerAction.InternalIdPopup,
	},
	{
		text: 'Ashes Weight Popup',
		value: TriggerAction.AshesWeightPopup,
	},
];

export const triggerEvent = [
	{
		text: 'On will enter',
		value: TriggerEvent.OnWillEnter,
	},
	{
		text: 'On will leave',
		value: TriggerEvent.OnWillLeave,
	},
];

export const rush = [
	{
		text: 'Rush',
		value: Number(true),
	},
	{
		text: 'Non-rush',
		value: Number(false),
	},
];

export const customer = [
	{
		text: 'Customer',
		value: Number(true),
	},
	{
		text: 'Non-customer',
		value: Number(false),
	},
];

export const attachedClinic = [
	{
		text: 'Attached',
		value: AttachedRouteClinic.Attached,
	},
	{
		text: 'All',
		value: AttachedRouteClinic.All,
	},
];

export const onHoldAdmin = [
	{
		text: 'By Crematory',
		value: PetOnHold.ByCrematory,
	},
	{
		text: 'By Clinic',
		value: PetOnHold.ByClinic,
	},
	{
		text: 'Not On Hold',
		value: PetOnHold.None,
	},
];

export const onHoldCrematory = [
	{
		text: 'On Hold',
		value: PetOnHold.ByCrematory,
	},
	{
		text: 'Not On Hold',
		value: PetOnHold.None,
	},
];

export const special = [
	{
		text: 'Special',
		value: Number(true),
	},
	{
		text: 'Non-special',
		value: Number(false),
	},
];

export const typeOfCrematory = [
	{
		text: 'Aquamation',
		value: TypeOfCrematory.Aquamation,
	},
	{
		text: 'Fire',
		value: TypeOfCrematory.Fire,
	},
	{
		text: 'Both',
		value: TypeOfCrematory.Both,
	},
];

export const demoRequestStatus = [
	{
		text: 'Requested',
		value: DemoRequestStatus.Requested,
	},
	{
		text: 'Scheduled',
		value: DemoRequestStatus.Scheduled,
	},
	{
		text: 'Cancelled',
		value: DemoRequestStatus.Cancelled,
	},
	{
		text: 'Completed',
		value: DemoRequestStatus.Completed,
	},
];

export const dueDate = [
	{
		text: 'Net15',
		value: DueDate.Net15,
	},
	{
		text: 'Net30',
		value: DueDate.Net30,
	},
];

export const adjustmentAction = [
	{
		text: 'Subtraction',
		value: AdjustmentAction.Subtraction,
	},
	{
		text: 'Addition',
		value: AdjustmentAction.Addition,
	},
];

export const discountUnit = [
	{
		text: 'Value',
		value: DiscountType.Value,
	},
	{
		text: 'Percentage',
		value: DiscountType.Percentage,
	},
];

export interface StatusFunctionMeta {
	text: string;
	value: StatusFunction,
	group: FunctionsGroup,
	description: string;
}

export const statusFunction: Record<StatusFunction, StatusFunctionMeta> = {
	[StatusFunction.PickupReport]: {
		text: 'Pickup Report',
		value: StatusFunction.PickupReport,
		group: FunctionsGroup.Reports,
		description: statusOrderFunctionDescriptions[StatusFunction.PickupReport],
	},
	[StatusFunction.BatchAction]: {
		text: 'Batch Action',
		value: StatusFunction.BatchAction,
		group: FunctionsGroup.SystemActions,
		description: statusOrderFunctionDescriptions[StatusFunction.BatchAction],
	},
	[StatusFunction.DropOffReport]: {
		text: 'Dropoff Report',
		value: StatusFunction.DropOffReport,
		group: FunctionsGroup.Reports,
		description: statusOrderFunctionDescriptions[StatusFunction.DropOffReport],
	},
	[StatusFunction.MarkAtClinic]: {
		text: 'Mark At Clinic',
		value: StatusFunction.MarkAtClinic,
		group: FunctionsGroup.Clinic,
		description: statusOrderFunctionDescriptions[StatusFunction.MarkAtClinic],
	},
	[StatusFunction.MarkAtCrematory]: {
		text: 'Mark At Crematory',
		value: StatusFunction.MarkAtCrematory,
		group: FunctionsGroup.Clinic,
		description: statusOrderFunctionDescriptions[StatusFunction.MarkAtCrematory],
	},
	[StatusFunction.MarkDelivered]: {
		text: 'Mark Delivered',
		value: StatusFunction.MarkDelivered,
		group: FunctionsGroup.Clinic,
		description: statusOrderFunctionDescriptions[StatusFunction.MarkDelivered],
	},
	[StatusFunction.RequireManualChange]: {
		text: 'Require Manual Change',
		value: StatusFunction.RequireManualChange,
		group: FunctionsGroup.SystemActions,
		description: statusOrderFunctionDescriptions[StatusFunction.RequireManualChange],
	},
	[StatusFunction.InvoiceDate]: {
		text: 'Invoice Date',
		value: StatusFunction.InvoiceDate,
		group: FunctionsGroup.SystemActions,
		description: statusOrderFunctionDescriptions[StatusFunction.InvoiceDate],
	},
	[StatusFunction.CoordinateBatch]: {
		text: 'Coordinate Batch',
		value: StatusFunction.CoordinateBatch,
		group: FunctionsGroup.SystemActions,
		description: statusOrderFunctionDescriptions[StatusFunction.CoordinateBatch],
	},
	[StatusFunction.TurnAroundDelivered]: {
		text: 'Turnaround - Delivered',
		value: StatusFunction.TurnAroundDelivered,
		group: FunctionsGroup.Reports,
		description: statusOrderFunctionDescriptions[StatusFunction.TurnAroundDelivered],
	},
	[StatusFunction.TurnAroundPickup]: {
		text: 'Turnaround - Ready for Pickup',
		value: StatusFunction.TurnAroundPickup,
		group: FunctionsGroup.Reports,
		description: statusOrderFunctionDescriptions[StatusFunction.TurnAroundPickup],
	},
};

export const discountLimit = [
	{
		text: 'Products and Services',
		value: -1,
	}, {
		text: getPriceKindName(PriceKind.BasePrice),
		value: PriceKind.BasePrice,
	}, {
		text: getPriceKindName(PriceKind.SpecialServicePrice),
		value: PriceKind.SpecialServicePrice,
	}, {
		text: getPriceKindName(PriceKind.UrnPrice),
		value: PriceKind.UrnPrice,
	},
];

export const priceKindOptions = [
	{
		text: getPriceKindName(PriceKind.BasePrice),
		value: PriceKind.BasePrice,
	},
	{
		text: getPriceKindName(PriceKind.SpecialServicePrice),
		value: PriceKind.SpecialServicePrice,
	},
	{
		text: getPriceKindName(PriceKind.PickupPrice),
		value: PriceKind.PickupPrice,
	},
	{
		text: getPriceKindName(PriceKind.DeliveryPrice),
		value: PriceKind.DeliveryPrice,
	},
	{
		text: getPriceKindName(PriceKind.Discount),
		value: PriceKind.Discount,
	},
	{
		text: getPriceKindName(PriceKind.Engraving),
		value: PriceKind.Engraving,
	},
	{
		text: getPriceKindName(PriceKind.ProductPrice),
		value: PriceKind.ProductPrice,
	},
	{
		text: getPriceKindName(PriceKind.UrnPrice),
		value: PriceKind.UrnPrice,
	},
	{
		text: getPriceKindName(PriceKind.RushFee),
		value: PriceKind.RushFee,
	},
];

export const priceKindClinicOptions = [
	{
		text: getPriceKindName(PriceKind.BasePrice),
		value: PriceKind.BasePrice,
	},
	{
		text: getPriceKindName(PriceKind.SpecialServicePrice),
		value: PriceKind.SpecialServicePrice,
	},
	{
		text: getPriceKindName(PriceKind.PickupPrice),
		value: PriceKind.PickupPrice,
	},
	{
		text: getPriceKindName(PriceKind.DeliveryPrice),
		value: PriceKind.DeliveryPrice,
	},
	{
		text: getPriceKindName(PriceKind.Engraving),
		value: PriceKind.Engraving,
	},
	{
		text: getPriceKindName(PriceKind.ProductPrice),
		value: PriceKind.ProductPrice,
	},
	{
		text: getPriceKindName(PriceKind.UrnPrice),
		value: PriceKind.UrnPrice,
	},
	{
		text: getPriceKindName(PriceKind.RushFee),
		value: PriceKind.RushFee,
	},
];

export const invoiceAdjustmentType = (t: TFunction) => [
	{
		text: t('enums.adjustment.product'),
		value: InvoiceAdjustmentType.Product,
	},
	{
		text: t('enums.adjustment.service'),
		value: InvoiceAdjustmentType.Service,
	},
];

export const adjustmentValueType = [
	{
		text: 'Absolute',
		value: AdjustmentValueType.Absolute,
	},
	{
		text: 'Relative',
		value: AdjustmentValueType.Relative,
	},
];

export const batchTypes = (crematory?: CrematoryListInfo, withAll?: boolean) => {
	const item = crematory ?? getDefaultCrematory();
	const { communal, semiPrivate } = item;
	const types: Array<Option> = [];

	if (withAll) types.push({ text: 'All', value: BatchActionType.Both });
	if (communal.useServiceType) types.push({ text: 'Communal', value: BatchActionType.Communal });
	if (semiPrivate.useServiceType) types.push({ text: 'Semiprivate', value: BatchActionType.Semiprivate });
	if (item.private.useServiceType) types.push({ text: 'Private', value: BatchActionType.Private });

	return types;
};

export const weightUnits = (t: TFunction) => [
	{
		text: t('enums.weight-units.pounds'),
		value: WeightUnits.Pounds,
	},
	{
		text: t('enums.weight-units.kilograms'),
		value: WeightUnits.Kilograms,
	},
];

export const inventoryCategory = [
	{
		text: 'Chemical',
		value: ItemCategory.Chemical,
	},
	{
		text: 'Product',
		value: ItemCategory.Product,
	},
	{
		text: 'Urn',
		value: ItemCategory.Urn,
	},
];

export const inventoryAllCategory = [
	{
		text: 'Chemical',
		value: ItemCategory.Chemical,
	},
	{
		text: 'Product',
		value: ItemCategory.Product,
	},
	{
		text: 'Product Categories',
		value: ItemCategory.ProductCategory,
	},
	{
		text: 'Urn',
		value: ItemCategory.Urn,
	},
	{
		text: 'Urn Categories',
		value: ItemCategory.UrnCategory,
	},
];

export const labelPaperOrientation = [
	{
		text: 'Landscape',
		value: Orientation.Landscape,
	},
	{
		text: 'Portrait',
		value: Orientation.Portrait,
	},
];

export const labelPaperUnit = [
	{
		text: 'Millimeter',
		value: LengthUnit.Millimeter,
	},
	{
		text: 'Centimeter',
		value: LengthUnit.Centimeter,
	},
	{
		text: 'Inch',
		value: LengthUnit.Inch,
	},
];

const daysKey = 'helpers.days';
export const expirationDate = (t: TFunction) => ([
	{
		text: `30 ${t(daysKey)}`,
		value: 30,
	},
	{
		text: `60 ${t(daysKey)}`,
		value: 60,
	},
	{
		text: `90 ${t(daysKey)}`,
		value: 90,
	},
	{
		text: t('helpers.indefinitely'),
		value: 0,
	},
]);

export const itemType = (t: TFunction) => ([
	{
		text: t('enums.quickbooks-item-type.inventory'),
		value: QuickbooksItemType.Inventory,
	},
	{
		text: t('enums.quickbooks-item-type.non-inventory'),
		value: QuickbooksItemType.NonInventory,
	},
	{
		text: t('enums.quickbooks-item-type.service'),
		value: QuickbooksItemType.Service,
	},
]);

export const isLinkedToQuickbooksOptions = [
	{
		text: 'Linked',
		value: Number(true),
	},
	{
		text: 'Non-linked',
		value: Number(false),
	},
];

export const dateFormatOptions = [
	{
		text: 'MM/DD/YYYY',
		value: 'MM/DD/YYYY',
	},
	{
		text: 'DD/MM/YYYY',
		value: 'DD/MM/YYYY',
	},
	{
		text: 'MM-DD-YYYY',
		value: 'MM-DD-YYYY',
	},
	{
		text: 'DD-MM-YYYY',
		value: 'DD-MM-YYYY',
	},
];

export const timeFormatOptions = [
	{
		text: 'hh:mm a',
		value: 'hh:mm a',
	},
	{
		text: 'HH:mm',
		value: 'HH:mm',
	},
];

export const crematoryType = (t: TFunction) => ([
	{
		text: t('enums.crematory-type.both'),
		value: CrematoryType.Both,
	},
	{
		text: t('enums.crematory-type.aquamation'),
		value: CrematoryType.Aquamation,
	},
	{
		text: t('enums.crematory-type.cremation'),
		value: CrematoryType.Cremation,
	},
]);
