import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { User } from '@app/objects/User';
import { PriceKind, PriceType } from '@app/objects/Price';
import { ServiceType } from '@app/objects/Pet';

export interface CustomerInfo {
	companyName: string;
}

export interface WingstrackerCustomer extends WithId {
	clinicId: number;
	isCustomer: boolean;

	name: string;
}

export interface QuickbooksCustomer extends WithId {
	customerId: string;
	customer: CustomerInfo;

	wingsCustomerId: Nullable<number>;
	wingsCustomer: Nullable<WingstrackerCustomer>;
}

export enum QuickbooksItemType {
	Inventory = 4,
	NonInventory = 5,
	Service = 8,
}

export interface QuickbooksItem extends WithId {
	itemId: string;
	item: Item;
	count: number;
}

export interface Item {
	name: string;
	description: string;
	type: QuickbooksItemType;
}

export interface QuickbooksItemFilters {
	priceType: Array<PriceType>;
	serviceType: Array<ServiceType>;
	priceKind: Array<PriceKind>;
	clinic: Array<number>;
}

export interface QuickbooksItemLink {
	priceId: number;
	delete: boolean;
	filters?: QuickbooksItemFilters;
}

export interface QuickbooksSetItems extends WithId {
	itemId: string;
	selectAll: boolean;
	links: Array<QuickbooksItemLink>;
}

export interface QuickbooksInvoice extends WithId{
	quickbooksInvoiceId: string;
	quickbooksDocumentNumber: string;

	timeCreated: number;
	user: Nullable<User>;
	userId: number;
}

export function getTypeName(type: QuickbooksItemType, t) {
	switch (type) {
	case QuickbooksItemType.Inventory:
		return t('enums.quickbooks-item-type.inventory');
	case QuickbooksItemType.NonInventory:
		return t('enums.quickbooks-item-type.non-inventory');
	case QuickbooksItemType.Service:
		return t('enums.quickbooks-item-type.service');

	default:
		return '-';
	}
}
