import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
	withRouter,
	generatePath,
} from 'react-router-dom';

import Tabs, { TabsProps } from 'antd/lib/tabs';

import { parseQuery } from '@common/typescript/utils/url';
import { replaceSearchUrl } from '@common/react/components/Utils/Utils';

export enum TabMode {
	Query = 0, // Store current tab as a query-param (?tab=key)
	Location = 1, // Store current tab as location-param (/path/tab)
}

interface BaseProps {
	mode?: TabMode;
	defaultKey: string;
	tabsUrlKey: string;

	searchParams?: Record<string, string>;
}

type ComponentProps = TabsProps & BaseProps;
type Props = React.PropsWithChildren<unknown> & ComponentProps & RouteComponentProps<never>;

const propsAreEqual = (prevProps: React.PropsWithChildren<Props>, nextProps: React.PropsWithChildren<Props>) => {
	const activeTab = parseQuery(prevProps.location.search)[prevProps.tabsUrlKey] || prevProps.defaultKey;
	const newActiveTab = parseQuery(nextProps.location.search)[nextProps.tabsUrlKey] || nextProps.defaultKey;

	return activeTab === newActiveTab;
};

function getTab(props: Props, keyRef: string): string {
	switch (props.mode) {
	case TabMode.Location:
		return props.match.params[props.tabsUrlKey] || props.defaultKey;

	case TabMode.Query:
	default: {
		const query = parseQuery(props.location.search);

		return query[props.tabsUrlKey] ?? keyRef;
	}
	}
}

const Component: React.FC<Props> = (props: Props) => {
	const {
		history,
		location,
		match,
		tabsUrlKey,
		defaultKey,
		onChange,
		mode,
		children,
		searchParams,
		...rest
	} = props;

	const keyRef = React.useRef<string>(defaultKey);
	const [currentTab, setTab] = React.useState<string>(() => getTab(props, keyRef.current));
	const onChangeHandler = (activeKey: string) => {
		const search = searchParams && searchParams[activeKey] ? `${history.location.search}&${searchParams[activeKey]}` : '';

		if (mode === TabMode.Location) {
			const params: Record<string, string> = currentTab === activeKey
				? { ...match.params as object, [tabsUrlKey]: activeKey }
				: { [tabsUrlKey]: activeKey }; // delete all parameters except /:tab if the user has changed the tab/page
			const path = generatePath(match.path, params);
			const config: Partial<Location> = {
				hash: location.hash,
				pathname: path,
			};

			if (searchParams && searchParams[activeKey] && currentTab !== activeKey) config.search = search;

			if (currentTab === activeKey) config.search = history.location.search;

			history.replace(config);
		} else {
			keyRef.current = activeKey;
			history.replace({
				...history.location,
				pathname: history.location.pathname,
				search: replaceSearchUrl(search, props.tabsUrlKey, activeKey),
			});
		}
	};

	React.useEffect(() => {
		onChangeHandler(currentTab);
	}, []);

	React.useEffect(() => {
		const newActiveTab = getTab(props, keyRef.current);

		if (newActiveTab && newActiveTab !== currentTab) {
			setTab(newActiveTab);
			onChange?.(newActiveTab);
		}
	}, [location]);

	return (
		<Tabs {...rest} activeKey={currentTab} onChange={onChangeHandler}>
			{children}
		</Tabs>
	);
};

const TabsWithUrl: React.FC<Props> = React.memo(Component);

export default withRouter(TabsWithUrl);
