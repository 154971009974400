import * as React from 'react';

import { WithId } from '@common/typescript/objects/WithId';

import { count } from '@app/components/Utils/Utils';
import {
	Items,
	PaginationConfig,
	UsePagination,
} from '@app/hooks/PaginationManager/PaginationProps';

export const usePagination: UsePagination = (config?: PaginationConfig) => {
	const perPage = config?.perPage ?? 10;
	const items = config?.items ?? [];

	const [page, setPage] = React.useState(() => 0);
	const [total, setTotal] = React.useState(() => count(items));

	return {
		pagination: {
			page,
			perPage,
			total,
			hideOnSinglePage: true,
		},
		onPageChange: setPage,
		update: (items: Items<WithId>) => {
			setTotal(count(items));
		},
	};
};
