import { getParams, loadable, loadableDelay } from '@common/react/loadable/loadableSettings';

export const OldPetInventoryUsageTab = loadable(
	() =>
		loadableDelay(import(
			'@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetInventoryUsage'
		)),
	getParams('OldPetInventoryUsage'),
);

export const OldPetImportNotesTab = loadable(
	() =>
		loadableDelay(import(
			'@app/components/Pages/PetEditor/OldPetEditorTabs/PetImportNotes/OldPetImportNotes'
		)),
	getParams('OldPetImportNotes'),
);

export const OldPetHistoryChangeTab = loadable(
	() =>
		loadableDelay(import(
			'@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetHistoryChange'
		)),
	getParams('OldPetHistoryChange'),
);

export const OldPetStatusLogTab = loadable(
	() =>
		loadableDelay(import(
			'@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetStatusLog'
		)),
	getParams('OldPetStatusLog'),
);

export const OldPetEmailLogTab = loadable(
	() =>
		loadableDelay(import(
			'@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetEmailLog'
		)),
	getParams('OldPetEmailLog'),
);
