import * as React from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';

interface OwnProps {
	children?: React.ReactNode;
}

export const BodyLayout: React.FC<OwnProps> = (props: React.PropsWithChildren<OwnProps>) => {
	const user = useSelector((state: ApplicationState) => state.login.user);
	const role = user?.role;
	const isClinic = role === UserRole.ClinicManager || role === UserRole.ClinicUser;

	return (
		<main className={clsx('inner-page-wrapper', isClinic && 'inner-page-wrapper--clinic')}>
			<div
				className={clsx('inner-page-wrapper__inner', isClinic && 'inner-page-wrapper__inner--clinic')}
			>
				<div className="inner-page-wrapper__block-wrapper">
					{props.children}
				</div>
			</div>
		</main>
	);
};
