import * as React from 'react';

interface UploadProgressProps {
	progress: number; // [0; 100]

	activeBarColor?: string;
	barColor?: string;
}

export const UploadProgress: React.FC<UploadProgressProps> = (props: UploadProgressProps) => {
	const value = Math.min(100, Math.max(0, props.progress));

	return (
		<div className="file-preview">
			<div
				className="file-preview__progress-container"
				role="progressbar"
				style={{
					background: `radial-gradient(closest-side, white 89%, transparent 90%, transparent 100%),
						conic-gradient(${props.activeBarColor!} ${value}%, ${props.barColor!} 0%)`,
				}}
			>
				{value.toFixed(0)}%
			</div>
		</div>
	);
};

UploadProgress.defaultProps = {
	activeBarColor: 'lightskyblue',
	barColor: 'lightgray',
};
