import { ResponseError } from '@common/react/components/Api/RequestError';
import { addTask } from '@common/react/components/Api/Fetch';

/**
 * wrapError is used to extract error message from ResponseError
 * This really shouldn't be used this way, but for sake of compatability it is preserved
 * @param {Promise<T>} task - request task
 * @returns {Promise<T>} - request task with error being string (message) instead of RequestError object
 */
export function wrapError<T>(task: Promise<T>): Promise<T> {
	return task.catch((error: ResponseError) => {
		if (error.message === 'Access denied' && window) {
			window.location.href = '/';
		}

		console.log(error.message);

		throw error.message as string;
	});
}

/**
 * wrapDomain adds request task to domainTasks array
 * This way request should be awaited before SSR renders page and sends it to client.
 * @param {Promise<T>} task - request task
 * @returns {Promise<T>} - task that's been added to domain task array
 */
export function wrapDomain<T>(task: Promise<T>): Promise<T> {
	addTask(task);

	return task;
}
