import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';
import { request } from '@common/react/components/Api/Request';
import { wrapDomain } from '@common/react/components/Api/Wrappers';
import { ResponseError } from '@common/react/components/Api/RequestError';

import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store';

export interface RequestConfig {
	requestOnMount: boolean;
	cancelOnUnmount?: boolean;
}

type LoadFunction<TItem, TData> = (data?: TData) => Promise<TItem>;

export interface RequestData<TData, TItem> {
	loading: boolean;
	error: Nullable<string>;
	code: number;
	item: Nullable<TItem>;

	reload: LoadFunction<TItem, TData>;
	cancel: () => void;
}

const defaultConfig: RequestConfig = {
	requestOnMount: true,
	cancelOnUnmount: true,
};

function toResponseError(msg: string | ResponseError): ResponseError {
	if (typeof msg === 'string') return new ResponseError({ message: msg, code: -1 });

	return msg;
}

export function useRequest<TItem, TData extends object = never>(
	endpoint: string,
	data: TData | undefined = undefined,
	config: RequestConfig = defaultConfig,
): RequestData<TData, TItem> {
	const [item, setItem] = React.useState<TItem | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [error, setError] = React.useState<string | null>(null);
	const [code, setCode] = React.useState<number>(0);
	const controller = React.useRef<Nullable<AbortController>>(null);

	const cancel = () => controller.current?.abort();
	const load: LoadFunction<TItem, TData> = (data?: TData) => {
		setItem(null);
		setError(null);
		setLoading(true);

		controller.current?.abort();
		const abortController = new AbortController();
		controller.current = abortController;

		const task = wrapDomain(request<TItem, User, ApplicationState>(endpoint, data, undefined, abortController.signal));
		task.then((item: TItem) => {
			setItem(item);
			setLoading(false);
			setCode(0);
		})
			.catch((error: string | ResponseError) => {
				if (abortController.signal.aborted) return;

				const content = toResponseError(error);
				setError(content.message);
				setLoading(false);
				setCode(content.code);
			});

		return task;
	};

	React.useEffect(() => {
		if (config.requestOnMount) {
			load(data);
		}

		return () => {
			if (config.cancelOnUnmount) cancel();
		};
	}, []);

	return {
		loading,
		error,
		code,
		item,

		reload: load,
		cancel,
	};
}
