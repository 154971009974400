import * as React from 'react';

// Place before method
// Binds function to react component
export function member(target: React.Component, propertyKey: string, descriptor: PropertyDescriptor) {
	return {
		get() {
			const bound = descriptor.value.bind(this);

			Object.defineProperty(this, propertyKey, {
				value: bound,
			});

			return bound;
		}
	};
}
