import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Field, FieldProps, useFormikContext } from 'formik';

import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import ButtonGroup from 'antd/lib/button/button-group';
import Popconfirm from 'antd/lib/popconfirm';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { DeliveryTypeSelect } from '@app/components/UI/Inputs/DeliveryTypeSelect';
import {
	DeliveryType, Pet, ServiceType, deliveryTypeManager,
} from '@app/objects/Pet';
import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { ClinicListInfo } from '@app/objects/Clinic';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { ApplicationState } from '@app/store';
import { RegionSelect } from '@app/components/UI/Inputs/Address/RegionSelect';
import { addressService } from '@app/services/AddressService';
import { PostalCodeAutocomplete } from '@app/components/UI/Inputs/Address/PostalCodeAutocomplete';
import { TrackChange } from '@app/smart components/Tracker/TrackChange';
import { TextAreaResizeComponent } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Controls/TextAreaComponent';
import { setStatusColor } from '@app/components/Pages/PetEditor/OldPetEditorTabs/OldPetEmailLog';
import { MailStatus } from '@app/objects/EmailLog';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { request } from '@app/components/Api';
import { bindItemActionCreators } from '@app/store/Item';
import { PostalCode } from '@app/objects/PostalCode';
import { ClinicCrematory } from '@app/objects/ClinicCrematory';
import { Price, PriceKind } from '@app/objects/Price';

type SetValue<T, K extends keyof T = keyof T> = (key: K, value: T[K]) => void;

function toNumber(value: number | string | null | undefined): number {
	if (value === null || value === undefined) return 0;

	const cast = +value;
	if (Number.isNaN(cast) || cast === 0) return 0;

	return cast;
}

function toSetValue<T>(fieldProps: FieldProps<T, PetFormValues>): SetValue<PetFormValues> {
	return <K extends keyof PetFormValues = keyof PetFormValues> (key: K, value: PetFormValues[K]) =>
		fieldProps.form.setFieldValue(key, value, false);
}

function setDeliveryAddress(setValue: SetValue<PetFormValues>, clinicId: number, clinics: Array<ClinicListInfo>): void {
	const clinic = clinics.find((item: ClinicListInfo) => item.id === clinicId);
	const stateId = clinic.crematories.find((i: ClinicCrematory) => i.clinicId === clinicId).clinic?.stateId;

	if (clinic) {
		setValue('deliveryAddress', clinic.streetAddress);
		setValue('deliveryAddress2', clinic.streetAddress2);
		setValue('deliveryZip', clinic.zip);
		setValue('deliveryCity', clinic.city);
		setValue('deliveryStateId', stateId);
		setValue('deliveryClinicId', clinic.id);
	}
}

function onDeliveryClinicSelect(clinicId: number, values: PetFormValues, setValue: SetValue<PetFormValues>, clinics: Array<ClinicListInfo>): void {
	if (deliveryTypeManager.isClinic(values.deliveryType)) {
		setDeliveryAddress(setValue, clinicId, clinics);
	}
}

function onDeliveryTypeChange(
	value: DeliveryType,
	setValue: SetValue<PetFormValues>,
	values: PetFormValues,
	clinics: Array<ClinicListInfo>,
): void {
	const clinicId = toNumber(values.clinicId);
	setValue('deliveryType', value);

	if (deliveryTypeManager.isClinic(value) && clinicId > 0) {
		setDeliveryAddress(setValue, clinicId, clinics);
	}

	if (deliveryTypeManager.is(value, [DeliveryType.HomeDropOff, DeliveryType.Mail])) {
		setValue('deliveryAddress', values.ownerAddress);
		setValue('deliveryAddress2', values.ownerAddress2);
		setValue('deliveryZip', values.ownerZip);
		setValue('deliveryCity', values.ownerCity);
		setValue('deliveryStateId', values.ownerStateId);
	}
}

interface TrackClinicProps {
	value: Nullable<number>;
	onChange: (value: Nullable<number>) => void;
}

const TrackClinic: React.FC<TrackClinicProps> = (props: TrackClinicProps) => {
	React.useEffect(() => {
		props.onChange(props.value);
	}, [props.value]);

	return null;
};

interface DeliverySectionProps {
	country?: Nullable<string>;
	prices: Array<Price>;
}

type ResponseMailStatus = { code: ResponseCode }
enum ResponseCode {
	Success = 0,
	AlreadySent = 1
}

function onSendEmail(
	petId: number,
	onChange: (value: boolean) => void,
	update: (store: keyof ApplicationState, data: Partial<Pet>) => void,
	setLoading: (value: boolean) => void,
	resend?: boolean,
) {
	setLoading(true);
	request<ResponseMailStatus>('sendCrematoryCoordinatesEmail', { id: petId, resend })
		.then(({ code }: ResponseMailStatus) => {
			if (code === ResponseCode.AlreadySent) {
				onChange(true);
			} else {
				update('pet', { id: petId, coordinatesMailStatus: MailStatus.Success });
				onChange(false);
			}
		})
		.catch((error: string) => alertMessage(MessageType.error, error))
		.finally(() => setLoading(false));
}

export const DeliverySection: React.FC<DeliverySectionProps> = (props: DeliverySectionProps) => {
	const { t } = useTranslation();
	const { values: { deliveryType } } = useFormikContext<PetFormValues>();
	const clinics = useSelector((state: ApplicationState) => state.clinics).items ?? [];
	const carriers = useSelector((state: ApplicationState) => state.carriers).items ?? [];
	const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	const dispatch = useDispatch();
	const factory = bindItemActionCreators<Pet>(dispatch);

	const country = props.country ?? 'unknown';
	const details = addressService.provide(country);

	return (
		<SectionContainer titleLocalizationKey="sections.delivery">
			<Field name="deliveryType">
				{(fieldProps: FieldProps<DeliveryType, PetFormValues>) => (
					<TrackChange
						track={fieldProps.field.value}
						onChange={() => {
							onDeliveryTypeChange(fieldProps.field.value, toSetValue<DeliveryType>(fieldProps), fieldProps.form.values, clinics);
						}}
					/>
				)}
			</Field>
			<div className="form-group">
				<Field name="clinicId">
					{(fieldProps: FieldProps<number, PetFormValues>) => (
						<TrackClinic
							value={fieldProps.field.value}
							onChange={(value: Nullable<number>) => {
								const values = fieldProps.form.values;
								const isDeliveryNotClinicSet = values.deliveryClinicId === null || values.deliveryClinicId < 1;

								if (deliveryTypeManager.isClinic(values.deliveryType) && isDeliveryNotClinicSet) {
									setDeliveryAddress(toSetValue(fieldProps), value ?? -1, clinics);
								}
							}}
						/>
					)}
				</Field>
				<div className="row">
					<Field name="deliveryType">
						{(fieldProps: FieldProps<DeliveryType, PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName="form-group col-sm-4"
								title={`${t(iKey('labels.delivery-type'))}*`}
								render={({ field, form }: FieldProps<DeliveryType, PetFormValues>) => (
									<DeliveryTypeSelect
										value={field.value}
										prices={props.prices.filter((i) => i.priceKind === PriceKind.DeliveryPrice)}
										onChange={(value) =>
											onDeliveryTypeChange(value, toSetValue(fieldProps), form.values, clinics)}
									/>
								)}
							/>
						)}
					</Field>
					{
						deliveryTypeManager.isClinic(deliveryType)
							? (
								<Field name="deliveryClinicId">
									{(fieldProps: FieldProps<PetFormValues>) => (
										<FormikInput
											fieldProps={fieldProps}
											containerClassName="form-group col-sm-4"
											title={`${t(iKey('labels.delivery-clinic'))}*`}
											render={({ field, form }: FieldProps<number, PetFormValues>) => (
												<Select
													{...field}
													showSearch
													showAction={['focus', 'click']}
													optionFilterProp="children"
													onChange={(value) =>
														onDeliveryClinicSelect(value, form.values, toSetValue(fieldProps), clinics)}
													value={field.value ?? ''}
												>
													<Select.Option
														disabled
														value={-1}
														key={0}
													>
														Select clinic
													</Select.Option>
													{clinics.map((item) => (
														<Select.Option value={item.id} key={item.id}>
															{item.name}
														</Select.Option>
													))}
												</Select>
											)}
										/>
									)}
								</Field>
							) : null
					}
					{
						deliveryTypeManager.isMail(deliveryType)
							? (
								<Field name="carrierId">
									{(fieldProps: FieldProps<PetFormValues>) => (
										<FormikInput
											fieldProps={fieldProps}
											containerClassName="form-group col-sm-4"
											title={t(iKey('labels.carrier'))}
											render={({ field, form }: FieldProps<PetFormValues>) => (
												<Select
													{...field}
													showSearch
													showAction={['focus', 'click']}
													optionFilterProp="children"
													placeholder="Please, pick a carrier"

													onChange={(value) => {
														form.setFieldValue(field.name, value);
													}}
													value={field.value ?? undefined}
												>
													<Select.Option
														disabled
														value={-1}
														key={0}
													>
														Please, pick a carriers
													</Select.Option>
													<Select.Option
														value={0}
														key={0}
													>
														None
													</Select.Option>
													{carriers.map((carrier) => (
														<Select.Option value={carrier.id} key={carrier.id}>
															{carrier.name}
														</Select.Option>
													))}
												</Select>
											)}
										/>
									)}
								</Field>
							) : null
					}
					{
						deliveryTypeManager.isMail(deliveryType)
							? (
								<Field name="trackingNumber">
									{(fieldProps: FieldProps<PetFormValues>) => (
										<FormikInput
											containerClassName="form-group col-sm-4"
											fieldProps={fieldProps}
											title={t(iKey('labels.tracking-number'))}
										/>
									)}
								</Field>
							) : null
					}
				</div>
				{!deliveryTypeManager.isNone(deliveryType) && !deliveryTypeManager.isPickup(deliveryType) && (
					<>
						<div className="row">
							<Field name="deliveryAddress">
								{(fieldProps: FieldProps<PetFormValues>) => (
									<FormikInput
										containerClassName="form-group col-sm-6"
										fieldProps={fieldProps}
										title={`${t(iKey('labels.delivery-address-1'))}*`}
									/>
								)}
							</Field>
							<Field name="deliveryAddress2">
								{(fieldProps: FieldProps<PetFormValues>) => (
									<FormikInput
										containerClassName="form-group col-sm-6"
										fieldProps={fieldProps}
										title={t(iKey('labels.delivery-address-2'))}
									/>
								)}
							</Field>
						</div>
						<div className="row">
							<Field name="deliveryZip">
								{(fieldProps: FieldProps<PetFormValues>) => (
									<FormikInput
										fieldProps={fieldProps}
										title={`${t(iKey(`labels.delivery-${details.mailKey}`))}*`}
										containerClassName="form-group col-sm-4"
										render={(fieldProps: FieldProps<string, PetFormValues>) => (
											<PostalCodeAutocomplete
												value={fieldProps.field.value}
												onChange={(value: string) =>
													fieldProps.form.setFieldValue(fieldProps.field.name as keyof PetFormValues, value, false)}
												onSelect={(value, option: PostalCode) => {
													fieldProps.form.setValues({
														...fieldProps.form.values,
														deliveryZip: option.zip,
														deliveryCity: option.city,
														deliveryStateId: option.stateId,
													});
												}}
												country={country}
											/>
										)}
									/>
								)}
							</Field>
							<Field name="deliveryCity">
								{(fieldProps: FieldProps<PetFormValues>) => (
									<FormikInput
										fieldProps={fieldProps}
										title={`${t(iKey('labels.delivery-city'))}*`}
										containerClassName="form-group col-sm-4"
									/>
								)}
							</Field>
							<Field name="deliveryStateId">
								{(fieldProps: FieldProps<PetFormValues>) => (
									<FormikInput
										fieldProps={fieldProps}
										containerClassName="form-group col-sm-4"
										title={`${t(iKey(`labels.delivery-${details.regionKey}`))}*`}
										render={({ field, form }) => (
											<RegionSelect
												value={field.value}
												onChange={(state?: number) => form.setFieldValue(field.name, state)}

												country={country}
											/>
										)}
									/>
								)}
							</Field>
						</div>
						<Field name="serviceType">
							{({ field }: FieldProps<ServiceType, PetFormValues>) => {
								if (field.value !== ServiceType.Communal) return null;

								return (
									<div className="row">
										<Field name="spreadLat">
											{(fieldProps: FieldProps<PetFormValues>) => (
												<FormikInput
													fieldProps={fieldProps}
													containerClassName="form-group col-sm-4"
													title={t(iKey('labels.spread-lat'))}
												/>
											)}
										</Field>
										<Field name="spreadLong">
											{(fieldProps: FieldProps<PetFormValues>) => (
												<FormikInput
													fieldProps={fieldProps}
													containerClassName="form-group col-sm-4"
													title={t(iKey('labels.spread-long'))}
												/>
											)}
										</Field>
										<Field name="coordinatesMailStatus">
											{(fieldProps: FieldProps<PetFormValues>) => (
												<FormikInput
													fieldProps={fieldProps}
													title="Map / Mail Status / Action"
													containerClassName="col-sm-4"
													render={({ field, form }) => (
														<ButtonGroup style={{ width: '100%' }}>
															<Button
																type="link"
																disabled={!form.values.spreadLat || !form.values.spreadLong}
																target="_blank"
																href={`https://maps.google.com/maps?q=${form.values.spreadLat}, ${form.values.spreadLong}`}
																rel="noreferrer"
																style={{ border: '1px solid #d9d9d9' }}
															>
																<i className="fa fa-map-marker" />
																<span className="icon-margin">Show on map</span>
															</Button>
															<Button style={{ pointerEvents: 'none', color: setStatusColor(field.value) }}>
																{field.value ? MailStatus[field.value] : 'The email was\'t sent'}
															</Button>
															<Popconfirm
																title="The email has already been sent. Do you want to send email again?"
																visible={showConfirm}
																onVisibleChange={(visible: boolean) => {
																	if (!visible) {
																		setShowConfirm(visible);

																		return;
																	}

																	onSendEmail(form.values.id, setShowConfirm, factory.updateItem, setLoading);
																}}
																onConfirm={() =>
																	onSendEmail(form.values.id, setShowConfirm, factory.updateItem, setLoading, true)}
																onCancel={() => setShowConfirm(false)}
																okText="Yes"
																cancelText="No"
															>
																<Button
																	type="primary"
																	icon="mail"
																	title="Send email"
																	loading={loading}
																/>
															</Popconfirm>
														</ButtonGroup>
													)}
												/>
											)}
										</Field>
									</div>
								);
							}}
						</Field>
					</>
				)}
				<div className="row">
					<Field name="specialInstructions">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.special-instructions'))}
								containerClassName="col-sm-12"
								render={({ field }: FieldProps<string, PetFormValues>) => (
									<TextAreaResizeComponent
										name={field.name}
										value={field.value}
										onChange={field.onChange}
										onBlur={field.onBlur}
									/>
								)}
							/>
						)}
					</Field>
				</div>
			</div>
		</SectionContainer>
	);
};
