import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';

import { Nullable } from '@common/typescript/objects/Nullable';

import { getDeliveryTypeName, getServiceTypeName, Pet } from '@app/objects/Pet';

import { ResourceButton } from '@app/components/UI/Buttons/ResourceButton';
import { PriceTagDisplay } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Displays/PriceTagDisplay';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';

import '@app/scss/ui/blocks/header-affix.scss';

import { PdfField } from '@app/components/Pages/Clinics/ClinicTabs/Invoices/InvoiceEditor/InvoiceEditorComponents/FormFields';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { IPriceStack } from '@app/services/pricing/IPriceStack';
import { Stack, StackDirection } from '@app/components/Layout/Stack';

interface EditorHeaderProps {
	item: Pet;
	calculatedPrice: Nullable<IPriceStack>;
	formValues: PetFormValues;
	onRecalculate: () => void;
}

const InvoicePrint = ({ petId }) => {
	const { t } = useTranslation();
	const [excludeFree, setExcludeFree] = React.useState<boolean>(true);

	return (
		<Dropdown.Button
			className="btn invoice-dropdown--pet"
			disabled={petId < 1}
			type="primary"
			overlay={(
				<Menu>
					<Menu.Item key="excludeFree">
						<PdfField
							isChecked={excludeFree}
							onChange={setExcludeFree}
							title="Exclude Free Services"
						/>
					</Menu.Item>
				</Menu>
			)}
			icon={<i className="fa fa-ellipsis-h" />}
			placement="bottomRight"
		>
			<a
				href={`/generate-pet-receipt?petId=${petId}&excludeFree=${excludeFree}`}
				download
				target="_blank"
				rel="noreferrer"
			>
				<i className="fa fa-file-pdf-o" />
				<span className="icon-separator">{t(iKey('pet-tag.button-invoice-text'))}</span>
			</a>
		</Dropdown.Button>
	);
};

export const EditorHeader: React.FC<EditorHeaderProps> = (props: EditorHeaderProps) => {
	const { t } = useTranslation();

	return (
		<Stack
			direction={StackDirection.Horizontal}
			style={{ justifyContent: 'space-between' }}
			className="pet-editor-page__headline"
		>
			<Stack.LayoutItem fluid>
				<Stack
					direction={StackDirection.Vertical}
					gap={0}
				>
					<h1>
						{
							props.item.id > 0
								? `${t(iKey('actions.edit-pet'))} #${props.item.id}`
								: t(iKey('actions.add-pet'))
						}
					</h1>
					<p>
						{/* eslint-disable-next-line max-len */}
						{props.item.name}, {props.item.gender?.name ?? ' - '}, {props.item.petSpecie?.name ?? ' - '}, {props.item.petBreed?.name ?? ' - '}, {getServiceTypeName(props.item.serviceType, t)}, {getDeliveryTypeName(props.item.deliveryType)}
					</p>
				</Stack>
			</Stack.LayoutItem>
			<Stack.LayoutItem fluid>
				<Stack
					direction={StackDirection.Horizontal}
					gap={0}
					style={{
						alignItems: 'center',
						justifyContent: 'flex-end',
						height: '100%',
					}}
				>
					{
						props.calculatedPrice
							? (
								<PriceTagDisplay
									value={props.calculatedPrice}
									onRecalculate={props.onRecalculate}
									formValues={props.formValues}
								/>
							) : null
					}

					<InvoicePrint petId={props.item.id} />
					<ResourceButton
						className="btn btn-pdf"
						disabled={props.item.id < 1}
						url={`/get-pet-tag?petId=${props.item.id}`}
					>
						<i className="fa fa-file-pdf-o" />
						<span className="icon-separator">{t(iKey('pet-tag.button-text'))}</span>
					</ResourceButton>
				</Stack>
			</Stack.LayoutItem>
		</Stack>
	);
};
