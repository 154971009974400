import * as React from 'react';

import clsx from 'clsx';

import Button from '@common/react/components/Forms/Button';

export enum PromoteDirection {
	PD_NEXT,
	PD_PREVIOUS
}

export enum PromoteResponseCode
{
	Success = 0,

	BatchWarning = 1,
	ServicesWarning = 2,
	FinalStatusWarning = 3,
	MissingActualWeight = 4,
	PermissionDeniedWarning = 5,
	MissingUrnWarning = 6,
	HasUnconfirmedChanges = 7,
	RequireTrackingNumber = 10,
	MissingInternalId = 11,
	MissingAshesWeight = 12,
	ConfirmProducts = 13,
}

interface ComponentProps {
	onClick: (direction: PromoteDirection)=> void;
	loading?: boolean;
	disabled?: boolean;
	direction: PromoteDirection;

	children?: React.ReactNode;
}

export const PetStatusButton: React.FC<ComponentProps> = (props: ComponentProps) => {
	return (
		<Button
			type="button"
			onClick={() => props.onClick(props.direction)}
			className={clsx('btn btn-link', props.direction === PromoteDirection.PD_NEXT ? ' btn-right' : ' btn-left')}
			title={props.direction === PromoteDirection.PD_NEXT ? 'Next Status' : 'Previous Status'}
			isLoading={props.loading}
			disabled={props.disabled}
		>
			{props.children}
		</Button>
	);
};
