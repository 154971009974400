import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
	Field,
	FieldProps,
	FieldArray,
	FieldArrayRenderProps,
	useFormikContext,
	useField,
} from 'formik';

import { ColumnProps } from 'antd/es/table';
import Table from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';

import { isPresent, Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { BatchComponent } from '@app/components/Pages/PetEditor/BatchComponent';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { getUrnSegments, iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { BatchAction } from '@app/objects/BatchAction';
import { useModal } from '@app/hooks/Editors/useModal';
import { Preview } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/Preview';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { getPriceValue } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import {
	StoreEntriesUrnModal,
	allowAdd,
} from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';
import {
	StoreEntryPickValue,
} from '@app/objects/StoreEntry';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { DefaultUrnProvider } from '@app/components/Pages/PetEditor/OldPetEditor/DefaultUrnProvider';

export interface UrnsItemError {
 count: string;
}

export interface PreselectStore {
	storeId: number;
	clientId: string;
	valuesId: Array<number>;
	count: number;
}

export function sortTags(a: StoreEntryPickValue, b: StoreEntryPickValue): number {
	if (!a.value?.attribute?.name) return 0;
	if (!b.value?.attribute?.name) return 0;

	if (a.value?.attribute?.name > b.value?.attribute?.name) return 1;
	if (a.value?.attribute?.name < b.value?.attribute?.name) return -1;

	if (!a.value?.name) return 0;
	if (!b.value?.name) return 0;

	if (a.value.name > b.value.name) return 1;
	if (a.value.name < b.value.name) return -1;

	return 0;
}

function getColumns(
	translate: (key: string) => string,
	setPreview: (value?: string) => void,
	onOpenModal: () => void,
	onPreselect: (value: PreselectStore) => void,
): Array<ColumnProps<PetPrice>> {
	return TableBuilder.shape<PetPrice>()
		.addColumn({
			title: <b>Urn</b>,
			dataIndex: 'name',
			width: '30%',
			render: (text: string, record: PetPrice) => (
				<MobileCell caption={translate('table.columns.name')}>
					<span title={record.entry?.name ?? record.price?.name ?? record.name}>{record.entry?.name ?? 'Unknown Urn'}</span>
					{!allowAdd(record.entry, record.nodeId) && (
						<Tooltip title="Not all parameters for the urn are selected">
							<Icon type="exclamation-circle" className="color-red icon-margin" />
						</Tooltip>
					)}
				</MobileCell>
			),
		})
		.addColumn({
			title: <b>Value</b>,
			dataIndex: 'nodeId',
			render: (id: number, record: PetPrice) => {
				const names = getUrnSegments(record);

				return (
					<MobileCell caption={translate('table.columns.value')}>
						{names.map((cur: string) => (
							<Tag style={{ margin: '0 10px 5px 0' }} key={cur}>
								{cur}
							</Tag>
						))}
					</MobileCell>
				);
			},
		})
		.addColumn({
			title: <b>{translate('labels.urn-count')}</b>,
			dataIndex: 'count',
			width: '100px',
			render: (value: Nullable<number>, record: PetPrice, id: number) => (
				<Field name={`urns.${id}.count`}>
					{(fieldProps: FieldProps<PetFormValues>) => {
						const error = fieldProps.form.errors.urns?.[id] as UrnsItemError | undefined;
						const touched = fieldProps.form.touched.urns?.[id]?.count;
						const onChange = (value: number) => {
							if (!record.price) return;

							const count = value;
							const currentValue = getPriceValue(record.price, count);

							const values: Array<PetPrice> = fieldProps.form.values.urns.map((q: PetPrice) => {
								if (q.clientId === record.clientId) {
									return {
										...q,
										count,

										value: currentValue.value,
										extra: currentValue.extra,
									};
								}

								return q;
							});

							fieldProps.form.setFieldValue('urns', values);
						};

						return (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName={Boolean(fieldProps.form.submitCount) && error && touched && error.count
									? 'table-cell-error'
									: ''}
								render={({ field }: FieldProps<number, PetFormValues>) => (
									<NumberField
										{...field}
										onChange={onChange}
										disabled={record.done}
										min={1}
									/>
								)}
							/>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: <b>Cost</b>,
			dataIndex: 'value',
			width: '100px',
			render: (value: number, record: PetPrice, id: number) => (
				<Field name={`urns.${id}.value`}>
					{(fieldProps: FieldProps<PetFormValues>) => {
						const error = fieldProps.form.errors.urns?.[id];
						const touched = fieldProps.form.touched.urns?.[id]?.value;
						const onChange = (value: number) => {
							if (!record.price) return;

							const values: Array<PetPrice> = fieldProps.form.values.urns.map((q: PetPrice) => {
								if (q.clientId === record.clientId) {
									return {
										...q,
										value,
									};
								}

								return q;
							});

							fieldProps.form.setFieldValue('urns', values);
						};

						return (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName={Boolean(fieldProps.form.submitCount) && error && touched && error.value
									? 'table-cell-error'
									: ''}
								render={({ field }: FieldProps<number, PetFormValues>) => (
									<NumberField
										{...field}
										onChange={onChange}
										disabled={record.done}
									/>
								)}
							/>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: '',
			align: 'right',
			dataIndex: 'image',
			render: (_, record: PetPrice) => (
				<Field name="products">
					{() => {
						const inventoryItem = record.price?.inventoryItem;
						const image = inventoryItem?.originalAvatar ?? inventoryItem?.avatar;

						if (!image) return null;

						return (
							<button
								type="button"
								className="action-container"
								onClick={() => setPreview(image)}
							>
								<i className="fa fa-eye action_link" />
							</button>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: '',
			align: 'right',
			width: '80px',
			render: (value, item: PetPrice, id: number) => {
				if (item.done) return null;

				return (
					<FieldArray
						name="urns"
						render={(props: FieldArrayRenderProps) => (
							<Stack direction={StackDirection.Horizontal}>
								<button
									type="button"
									className="action-container"
									onClick={() => {
										onPreselect({
											storeId: item.entry?.id ?? -1,
											clientId: item.clientId,
											valuesId: [item.nodeId ?? -1],
											count: item.count,
										});
										onOpenModal();
									}}
								>
									<i className="fa fa-pencil action_link" />
								</button>
								<button
									type="button"
									className="action-container"
									onClick={() => {
										if (item.id > 0) {
											props.replace(id, { ...item, removed: true });
											// Move item to the end of the list (so that order of existing items
											// Confirms to order of visible items
											props.move(id, props.form.values.urns.length - 1);
										} else {
											props.remove(id);
										}
									}}
								>
									<i className="fa fa-trash action_danger" />
								</button>
							</Stack>
						)}
					/>
				);
			},
		})
		.build();
}

export function urnsError(error) {
	if (!error) return undefined;

	let value = error;
	if (Array.isArray(error)) {
		const list = error.filter(isPresent);
		if (!list.length) return undefined;

		value = Object.values(list[0]).join(' ');
	}

	return () => (
		<span className="color-red">
			<Icon type="exclamation-circle" /> {value}
		</span>
	);
}

export const ProcessSection: React.FC = () => {
	const modalControl = useModal(false);
	const { t } = useTranslation();
	const [, meta] = useField<Array<PetPrice>>('urns');
	const translate = (key: string) => t(iKey(key));
	const [preview, setPreview] = React.useState<string | undefined>(undefined);
	const [preselect, setPreselect] = React.useState<PreselectStore | undefined>(undefined);
	const { values, setFieldValue } = useFormikContext<PetFormValues>();

	React.useEffect(() => {
		if (preselect && !modalControl.state) setPreselect(undefined);
	}, [modalControl.state, preselect]);

	return (
		<>
			<SectionContainer titleLocalizationKey="sections.process">
				<DefaultUrnProvider />
				<FieldArray
					name="urns"
					render={(props: FieldArrayRenderProps) => (
						<div className="urns-container">
							<div className="table-controls__container">
								<div className="table-controls__action-container pull-right">
									<div className="table-controls__action">
										<button
											className="btn btn-transparent text-primary"
											type="button"
											onClick={modalControl.open}
											disabled={!props.form.values.crematoryId || props.form.values.crematoryId < 1}
										>
											<i className="fa fa-plus" />
											<span className="icon-margin">Add Urn</span>
										</button>
									</div>
								</div>
							</div>
							<Table
								columns={getColumns(translate, setPreview, modalControl.open, setPreselect)}
								dataSource={props.form.values.urns.filter((q: PetPrice) => !q.removed)}
								size="small"
								rowKey="clientId"
								pagination={false}
								className="form-group"
								loading={props.form.values.loadingUrns}
								title={urnsError(meta.error)}
							/>
						</div>
					)}
				/>

				<Field name="batchAction">
					{({ field, form }: FieldProps<BatchAction, PetFormValues>) => {
						if (form.values.id <= 0) return null;

						return (
							<div className="row">
								<div className="col-sm-12">
									<BatchComponent
										batchAction={field.value}
										position={form.values.batchMachineLocation}
										className="form-group batch-component"
									/>
								</div>
							</div>
						);
					}}
				</Field>
			</SectionContainer>
			<StoreEntriesUrnModal
				modalControl={modalControl}
				onChange={(items: Array<PetPrice>) => setFieldValue('urns', items)}
				serviceType={values.serviceType ?? ServiceType.Private}
				priceType={values.priceType}
				clinicId={values.clinicId ?? undefined}
				crematoryId={values.crematoryId}
				preselect={preselect}
			/>
			<Preview image={preview} onClose={() => setPreview(undefined)} />
		</>
	);
};
