import * as React from 'react';

import Card from 'antd/lib/card';

import { BatchAction } from '@app/objects/BatchAction';
import { DateView } from '@app/components/UI/Views/DateView/DateView';
import { PictureWall } from '@app/components/Forms/PictureWall';

interface BatchActionProps {
	batchAction: BatchAction | null;
	position: string;

	className?: string;
	style?: React.CSSProperties;
}

const getValidDate = (date: number | undefined | null) => {
	if (!date || date <= 0) return '—';

	return (
		<DateView date={date} withTime />
	);
};

const getLink = (batchId: number | undefined | null) => {
	if (batchId && batchId > 0) {
		const url = `/batch-action/${batchId}/view`;

		return <a href={url}>#{batchId}</a>;
	}

	return '—';
};

export const BatchComponent: React.FC<BatchActionProps> = ({
	batchAction,
	position,
	className,
	style,
}) => {
	if (!batchAction) return null;

	return (
		<div className={className} style={style}>
			<Card title={<strong className="field-heading">Batch</strong>} size="small">
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<strong>Batch number: </strong>
						{getLink(batchAction?.id)}
					</div>
					<div className="col-sm-6 col-md-4">
						<strong>Machine: </strong>
						{batchAction?.machine?.name ?? '—'}
					</div>
					<div className="col-sm-6 col-md-4">
						<strong>Position: </strong>
						{position?.length ? position : '—'}
					</div>
				</div>
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<strong>Created date: </strong>
						{getValidDate(batchAction?.createdDate)}
					</div>
					<div className="col-sm-6 col-md-4">
						<strong>Finished date: </strong>
						{getValidDate(batchAction?.finishedDate)}
					</div>
				</div>
				<div className="row">
					{batchAction.files.length > 0 && (
						<div className="col-md-12">
							<div className="card__content--wall">
								<PictureWall
									id={batchAction.id}
									type="batchAction"
									object={batchAction.files}
									buttonCaption="Upload"
									infoMessage="Image size must not exceed 4 Mb"
									style={{ height: 100, width: 100 }}
									lineHeight={100}

									withDeleteBtn={false}
								/>
							</div>
						</div>
					)}
				</div>
			</Card>
		</div>
	);
};
