import { WithId } from '@common/typescript/objects/WithId';

export enum CrematoryUserCounterEntity {
	Status = 0,
	SpecialService = 1,
}

export interface CrematoryUserCounter extends WithId {
	title: string;
	count: number;
	entityId: number;
}
