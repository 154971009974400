import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { v4 } from 'uuid';
import List from 'antd/lib/list';
import Popover from 'antd/lib/popover';
import Search from 'antd/lib/input/Search';
import { Field, FieldProps } from 'formik';
import Breadcrumb from 'antd/lib/breadcrumb';
import Divider from 'antd/lib/divider';

import { Nullable } from '@common/typescript/objects/Nullable';
import { ImageComponent } from '@common/react/components/UI/Image/Image';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ApplicationState } from '@app/store';
import { PetPrice, ServiceType } from '@app/objects/Pet';
import { Price, PriceKind, PriceType } from '@app/objects/Price';
import { ModalControls } from '@app/hooks/Editors/useModal';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { RootKey, SelectItem } from '@app/store/SelectList/SelectList';
import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { OnScrollManager } from '@app/services/OnScrollManager';
import { IActionCreators, getActionCreators } from '@app/store/SelectList/ListActions';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { getPriceValue } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import { getPrice } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceDialog';
import { ProductSelectFilter, isEqualProduct } from '@app/store/SelectList/SelectsInterfaces';
import { useCrematory } from '@app/hooks/useCrematory';
import { UserRole } from '@app/objects/User';

import noImage from '@images/no_image.jpg';

import '@app/scss/pages/pet-editor.scss';

interface ProductModalProps {
	modal: ModalControls;

	prices: Array<Price>;

	petId: number;
	products: Array<PetPrice>;

	clinicId?: Nullable<number>;
	crematoryId?: number;

	serviceType: ServiceType;
	priceType: PriceType;

	onChange: (values: Array<PetPrice>) => void;
}

interface Product extends InventoryItem {
	count: number;
}

interface InventoryList {
	list: Array<InventoryItem>;
	total: number;
}

const setList = (
	key: string,
	setKey: (key: string) => void,
	onChange: (value: InventoryList) => void,
	store: SelectItem<InventoryItem, ProductSelectFilter>,
) => {
	setKey(key);

	onChange({
		list: store?.items,
		total: store?.pagination.total,
	});
};

const getNewProduct = (price: Price, item: Product, petId: number): PetPrice => {
	const value = getPriceValue(price, item.count);

	return {
		id: -1,
		clientId: v4(),
		removed: false,

		petId,
		pet: null,

		priceId: price.id,
		price,

		count: item.count,
		done: false,
		completedCount: 0,

		value: value.value,
		extra: value.extra,
		name: item.name,

		editor: null,
		editorId: null,

		pickupService: null,
		pickupServiceId: null,

		batchCount: price.batchCount,
		batchPrice: price.batchPrice,

		node: null,
		nodeId: null,

		note: '',
	};
};

const onModalOk = (
	petId: number,
	values: Array<PetPrice>,
	setValue: (value: Array<PetPrice>) => void,
	onClose: () => void,
	prices?: Array<Price>,
	product?: Product,
) => {
	if (!product || !prices) return;

	const price = prices.find((i) => i.inventoryItemId === product.id);
	const newProductPetPrice = getNewProduct(price, product, petId);

	onClose();
	setValue([newProductPetPrice, ...values]);
};

interface DefaultComponentProps {
	product?: Product;
	storeKey?: string;
}

interface HeadProps extends DefaultComponentProps {
	breadcrumbs: Array<string>;
	setQuery: (value: string) => void;
	onReset: () => void;
}

export const Head: React.FC<HeadProps> = (props: HeadProps) => (
	<>
		<div className="pet-editor__product__breadcrumb">
			<Breadcrumb>
				<Breadcrumb.Item
					onClick={() => props.onReset()}
				>
					All Categories
				</Breadcrumb.Item>
				{props.breadcrumbs.map((item) => <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>)}
			</Breadcrumb>
		</div>
		<div className="pet-editor__product__search">
			<Search
				allowClear
				onChange={(event) => {
					if (event.target.value === '' && event.type === 'click' && props.setQuery) {
						props.setQuery('');
					}
				}}
				placeholder="Enter a name"
				onSearch={(e: string) => props.setQuery(e)}
				style={{ padding: '0 0 30px' }}
				enterButton={<i className="fa fa-search" />}
			/>
		</div>
		<div className="pet-editor__product__item-title">
			<h4>{props.product && props.storeKey !== RootKey ? props.product?.name ?? 'Unknown product' : ''}</h4>
		</div>
	</>
);

interface ItemListProps extends DefaultComponentProps {
	quantity: Map<number, number>;
	inventoryList?: InventoryList,
	availablePrices: Array<Price>;
	loading: boolean;
	getActionsProducts?: (value: string) => IActionCreators<unknown, 'products'>;
	getActionsUrns?: (value: string) => IActionCreators<unknown, 'urns'>;
	onSelect: (value: InventoryItem, price?: Price) => void;
}

const getTitle = (title: string, category?: ItemCategory | null, description?: string) => (
	<>
		{category && category === ItemCategory.ProductCategory ? <i className="fa fa-folder-open" style={{ marginRight: '5px' }} /> : ''}
		{title}
		{description && (
			<Popover content={description}>
				<i className="fa fa-question-circle-o" style={{ marginLeft: '5px' }} />
			</Popover>
		)}
	</>
);

function getDivider(item: InventoryItem, index: number, list?: Array<InventoryItem>) {
	if (item.category !== ItemCategory.UrnCategory || !list) return null;

	const specials = list.filter((i) => i.isSpecial).length;
	const indexOfFirstSpecial = list.length - specials;

	if (index === 0 && !item.isSpecial) {
		return <Divider orientation="left">Default Urns</Divider>;
	}

	if (index === indexOfFirstSpecial && item.isSpecial) {
		return <Divider orientation="left">Special Urns</Divider>;
	}

	return null;
}

export const ItemList: React.FC<ItemListProps> = (props: ItemListProps) => {
	const scrollRef = React.useRef<OnScrollManager>(new OnScrollManager());
	const crematory = useCrematory();
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const showPricesToClinic = role !== UserRole.ClinicUser && role !== UserRole.ClinicManager ? true : (crematory?.showPricesToClinic ?? false);

	return (
		<div
			className="pet-editor__product__list"
			onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
				const hasMore = Boolean(props.inventoryList && props.inventoryList.total > props.inventoryList?.list.length);
				const actions = (props.getActionsProducts ?? props.getActionsUrns)?.(props.storeKey ?? RootKey);

				if (actions) scrollRef.current.onScroll(e.target as HTMLElement, props.loading, hasMore, actions.loadMoreItems);
			}}
		>
			<List
				loading={props.loading}
				dataSource={props.inventoryList?.list}
				renderItem={(item: InventoryItem, index: number) => {
					const isPriceItem = item.category === ItemCategory.Product || item.category === ItemCategory.UrnCategory;
					const price = props.availablePrices.find((i) => i.inventoryItemId === item.id);
					const disabled = (!price && isPriceItem)
					|| (item.quantity === undefined || item.quantity <= 0 || item.quantity <= (props.quantity.get(item.id) ?? -1));

					return (
						<>
							{getDivider(item, index, props.inventoryList?.list)}
							<List.Item
								onClick={() => {
									if (disabled) return;

									props.onSelect(item, price);
								}}
								className={clsx(
									props.product?.id === item.id && 'list-item__active',
									disabled && 'list-item__disabled',
									props.storeKey !== RootKey && 'list-item__withoutBorder',
								)}
							>
								<List.Item.Meta
									title={getTitle(item.name, item.category, item.description)}
								/>
								{showPricesToClinic && !disabled && isPriceItem && <div>{getPrice(price?.value)}</div>}
							</List.Item>
						</>
					);
				}}
				size="small"
			/>
		</div>
	);
};

interface ItemFieldProps {
	quantity: Map<number, number>;
	product?: Product;
	onChange: (value?: Product) => void;
}

export const getError = (product: Product, quantity: Map<number, number>) => {
	let message: Nullable<string> = null;

	if (product.quantity && (product.count > product.quantity
	|| (quantity.get(product.id) ?? -1) + product.count > product.quantity)) {
		message = 'The selected quantity exceeds the quantity of this product in the inventory';
	}
	if (product.count < 0) message = 'The count must be a positive';
	if (Number.isNaN(product?.count)) message = 'This field is required';

	return message ? <div className="validation-message">{message}</div> : null;
};

export const ItemField: React.FC<ItemFieldProps> = (props: ItemFieldProps) => {
	const error = props.product && getError(props.product, props.quantity);

	return (
		<div className="pet-editor__product__count-field">
			<Field name="count">
				{(fieldProps: FieldProps<PetFormValues>) => (
					<>
						<FormikInput
							title="Count"
							fieldProps={fieldProps}
							containerClassName={clsx('col-12', Boolean(error) && 'has-error')}
							render={({ field }: FieldProps<PetFormValues>) => (
								<input
									id={field.name}
									name={field.name}
									className="form-control"

									type="number"
									min={0}

									value={props.product?.count ?? 1}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										if (props.product) props.onChange({ ...props.product, count: event.currentTarget.valueAsNumber });
									}}
								/>
							)}
						/>
						{error}
					</>
				)}
			</Field>
		</div>
	);
};

const handleSelectedItem = (
	item: InventoryItem,
	breadcrumbs: Array<string>,
	setBreadcrumbs: (value: Array<string>) => void,
	setStoreKey: (value: string) => void,
	setProduct: (value: Product) => void,
): void => {
	if (item.category === ItemCategory.ProductCategory) {
		const key = item.id.toString();
		if (!breadcrumbs.includes(item.name)) {
			setBreadcrumbs([...breadcrumbs, item.name]);
		}

		setStoreKey(key);
	} else {
		setProduct({ ...item, count: 1 });
	}
};

function setQuantity(items: Array<PetPrice>) {
	const result = new Map<number, number>();

	items.forEach((item: PetPrice) => {
		const key = item.price?.inventoryItemId ?? -1;

		result.set(key, item.count);
	});

	return result;
}

function setDisabled(product: Product | undefined, quantity: Map<number, number>) {
	return !product
	|| (product.quantity && (product.count > product.quantity || (quantity.get(product.id) ?? -1) + product.count > product.quantity))
	|| product.count < 0
	|| Number.isNaN(product?.count);
}

export const ProductModal: React.FC<ProductModalProps> = (props: ProductModalProps) => {
	const dispatch = useDispatch();
	const [storeKey, setStoreKey] = React.useState<string>(RootKey);

	const [loading, setLoading] = React.useState<boolean>(false);
	const [breadcrumbs, setBreadcrumbs] = React.useState<Array<string>>([]);
	const [inventoryList, setInventoryList] = React.useState<InventoryList | undefined>(undefined);
	const [availablePrices, setAvailablePrices] = React.useState<Array<Price>>(props.prices);
	const [product, setProduct] = React.useState<Product | undefined>(undefined);
	const [query, setQuery] = React.useState<string>('');

	const inventoryStore = useSelector((state: ApplicationState) => state.selects.products);
	const getActions = (key: string) => bindActionCreators(getActionCreators(
		'products',
		{ endpoint: 'inventoryItemList', key, equal: isEqualProduct },
	), dispatch);

	const categoryFilters: ProductSelectFilter = React.useMemo(() => ({
		search: query,
		serviceType: props.serviceType,
		priceType: props.priceType,
		category: [ItemCategory.ProductCategory, ItemCategory.Product],
		parentId: [null],
	}), [props.serviceType, props.priceType, query, props.clinicId, props.crematoryId]);

	const productFilters = React.useMemo(() => ({
		search: query,
		parentId: [Number(storeKey) ?? null],
	}), [query, storeKey]);

	const handleReset = () => {
		setBreadcrumbs([]);
		setQuery('');
		setProduct(undefined);
		setList(RootKey, setStoreKey, setInventoryList, inventoryStore[RootKey]);
	};

	React.useEffect(() => {
		if (!props.modal.state) return;

		const actions = getActions(storeKey);
		const filters = storeKey === RootKey ? categoryFilters : productFilters;
		actions.request(filters);
	}, [props.modal.state, categoryFilters, storeKey, productFilters]);

	React.useEffect(() => {
		setLoading(inventoryStore[storeKey]?.isLoading);
		setInventoryList({
			list: inventoryStore[storeKey]?.items,
			total: inventoryStore[storeKey]?.pagination.total,
		});
	}, [inventoryStore, storeKey]);

	React.useEffect(() => {
		let prices = props.prices.filter((price) => price.priceKind === PriceKind.ProductPrice);

		if (prices.some((price: Price) => price.clinicId)) {
			prices = prices.filter((price: Price) => price.clinicId);
		}

		setAvailablePrices(prices);
	}, [props.prices]);

	React.useEffect(() => {
		if (!props.modal.state) handleReset();
	}, [props.modal.state]);

	return (
		<ModalWrapper
			title="Add Product"
			isOpened={props.modal.state}
			onOk={() => onModalOk(props.petId, props.products, props.onChange, props.modal.close, availablePrices, product)}
			onCancel={props.modal.close}
			width="60%"
			modalClassName="pet-editor__product__modal"
			okButtonProps={{ disabled: setDisabled(product, setQuantity(props.products)) }}
		>
			<div className="pet-editor__product__modal-container">
				<Head
					breadcrumbs={breadcrumbs}
					onReset={handleReset}
					setQuery={setQuery}
					storeKey={storeKey}
					product={product}
				/>
				<ItemList
					availablePrices={availablePrices}
					inventoryList={inventoryList}
					loading={loading}
					quantity={setQuantity(props.products)}
					storeKey={storeKey}
					getActionsProducts={getActions}
					product={product}
					onSelect={(item: InventoryItem) => handleSelectedItem(item, breadcrumbs, setBreadcrumbs, setStoreKey, setProduct)}
				/>
				<ImageComponent src={product?.avatar ?? noImage} fallback={noImage} className="pet-editor__product__image-wrapper" />
				<ItemField product={product} onChange={setProduct} quantity={setQuantity(props.products)} />
			</div>
		</ModalWrapper>
	);
};
