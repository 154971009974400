import * as React from 'react';

import { FileInterface, FileType } from '@common/typescript/objects/FileInterface';
import { Nullable } from '@common/typescript/objects/Nullable';
import { upload } from '@common/react/components/Forms/Files/Upload';

interface UploadFileProps {
	type?: string; /// Type of connected entity
	objectId?: number; /// Id of connected entity

	onUpload: (item: FileInterface) => void;
	onProgress?: (progress: Nullable<number>) => void;

	disabled?: boolean;
}

type OwnProps = React.PropsWithChildren<UploadFileProps>;

function uploadFile(
	type: UploadFileProps['type'],
	objectId: UploadFileProps['objectId'],
	onProgress: UploadFileProps['onProgress'],
): Promise<FileInterface> {
	return new Promise<File>((resolve, reject) => {
		const element = document.createElement('input');
		element.type = 'file';

		element.onchange = () => {
			const item = element?.files?.item(0);

			if (!item) {
				reject(new Error('No files were selected'));

				return;
			}

			resolve(item);
		};
		element.click();
	})
		.then((item: File) =>
			upload({
				file: item,
				fileType: FileType.File,
				type,
				objectId,
			}, undefined, onProgress));
}

export const UploadFile: React.FC<OwnProps> = (props: OwnProps) => {
	const action = React.useCallback(() => {
		const onProgress = props.onProgress ? (value: Nullable<number>) => props.onProgress?.(value === null ? null : value * 100) : undefined;

		uploadFile(props.type, props.objectId, onProgress)
			.then((item: FileInterface) => props.onUpload(item));
	}, [
		props.type,
		props.objectId,
		props.onUpload,
		props.onProgress,
	]);

	return (
		<div className="upload-file">
			<button
				type="button"
				className="btn btn-primary btn-outline"
				disabled={props.disabled}
				onClick={action}
			>
				{props.children}
			</button>
		</div>
	);
};

UploadFile.defaultProps = {
	disabled: false,
};
