import * as React from 'react';
import { useSelector } from 'react-redux';

import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import { isPresent } from '@common/typescript/objects/WithRemoved';
import { Loading } from '@common/react/components/UI/Loading/Loading';

import { PetEngraving, ServiceType } from '@app/objects/Pet';
import { Price, PriceKind, PriceType } from '@app/objects/Price';
import { request } from '@app/components/Api';
import { useMemoAsync } from '@app/hooks/useMemoAsync';

import { useCrematory } from '@app/hooks/useCrematory';
import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';

import '@app/scss/ui/components/engraving-list.scss';

interface OwnProps {
	crematoryId?: number;
	clinicId?: number;
	ignoreClinic?: boolean;
	prefillEngraving?: boolean;

	priceType: PriceType;
	serviceType: ServiceType;

	value: Array<PetEngraving>;
	onChange: (value: Array<PetEngraving>) => void;
	onChangePrice: (value: Array<Price>) => void;
}

function addEngraving(list: Array<PetEngraving>): Array<PetEngraving> {
	const present = list.filter(isPresent);
	const order = present.length ? Math.max.apply(
		null,
		present.map((item: PetEngraving) => item.order),
	) + 1 : 0;
	const item: PetEngraving = {
		id: -1,
		name: '',
		order,
		removed: false,
	};

	return [
		...list,
		item,
	];
}

function updateEngraving(value: string, order: number, list: Array<PetEngraving>, prices: Array<Price>): Array<PetEngraving> {
	let index = -1;
	let priceIndex = -1;

	for (let i = 0; i < list.length; i++) {
		const item = list[i];

		if (!item.removed) {
			priceIndex++;

			if (item.order === order) {
				index = i;

				break;
			}
		}
	}

	if (prices.length <= priceIndex) return list;

	const price = prices[priceIndex];
	if (price.maxSize && value.length > price.maxSize) return list;

	return list.map((item: PetEngraving, idx: number) => {
		if (index !== idx) return item;

		return {
			...item,
			name: value,
		};
	});
}

function removeEngraving(order: number, list: Array<PetEngraving>, prices: Array<Price>): Array<PetEngraving> {
	const index = list.findIndex((item: PetEngraving) => !item.removed && item.order === order);
	const item = list[index];
	let result: Array<PetEngraving> = [];

	if (item.id > 0) {
		result = list.map((item: PetEngraving, idx: number) => {
			if (index !== idx) return item;

			return {
				...item,
				removed: true,
			};
		});
	} else {
		result = list.filter((item: PetEngraving, idx: number) => index !== idx);
	}

	let priceId = 0;
	for (let i = 0; i < result.length; i++) {
		const cur = result[i];

		if (!cur.removed && priceId < prices.length) {
			const price = prices[priceId++];
			if (price.maxSize && price.maxSize < cur.name.length) {
				result[i] = {
					...cur,
					name: cur.name.slice(0, price.maxSize),
				};
			}
		}
	}

	return result;
}

/**
 * Get a list of Engraving prices for particular Crematory / Clinic
 * @param raw - Source prices to pick from
 * @param priceType
 * @param serviceType
 * @param clinicId
 * @param ignoreClinic - filter by clinicId or not
 */
function getPrices(
	raw: Array<Price>,
	priceType: PriceType,
	serviceType: ServiceType,
	clinicId: Nullable<number>,
	ignoreClinic?: boolean,
): Array<Price> {
	const query = raw.filter((item: Price) => item.priceType === priceType && item.serviceType === serviceType);
	let prices: Array<Price> = [];

	if (!ignoreClinic && clinicId !== null && query.some((item: Price) => item.clinicId === clinicId)) {
		prices = query.filter((item: Price) => item.clinicId === clinicId);
	} else if (query.some((item: Price) => item.clinicId !== null)) {
		prices = query.filter((item: Price) => item.clinicId !== null);
	} else {
		prices = query.filter((item: Price) => item.clinicId === null);
	}

	return prices.sort((a: Price, b: Price) => (a.order ?? 0) - (b.order ?? 0));
}

function renderHelperText(order: number, prices: Array<Price>, list: Array<PetEngraving>, showPricesToClinic: boolean) {
	let priceIndex = -1;

	for (let i = 0; i < list.length; i++) {
		const item = list[i];

		if (!item.removed) {
			priceIndex++;
			if (item.order === order) break;
		}
	}

	if (prices.length <= priceIndex) return null;

	const price = prices[priceIndex];
	let text = 'The value of unlimited length';

	if (price.maxSize) {
		text = `The value should not exceed ${price.maxSize} characters`;
	}

	const priceValue = showPricesToClinic ? `(${price.value})` : '';

	return (
		<span className="history-item__user">
			{text} {priceValue}
		</span>
	);
}

export const EngravingList: React.FC<OwnProps> = (props: OwnProps) => {
	const controller = React.useRef<Nullable<AbortController>>(null);
	const lock = React.useRef<number>(0);
	const crematory = useCrematory();
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const showPricesToClinic = role !== UserRole.ClinicUser && role !== UserRole.ClinicManager ? true : (crematory?.showPricesToClinic ?? false);
	const [loading, setLoading] = React.useState<boolean>(false);

	const rawPrices: Array<Price> = useMemoAsync<Array<Price>>([], () => {
		setLoading(true);
		const clinics: Array<Nullable<number>> = [null];
		if (props.clinicId) clinics.push(props.clinicId);

		controller.current?.abort();
		const params = {
			clinicId: clinics,
			filters: {
				crematoryId: props.crematoryId,
				priceKind: [
					PriceKind.Engraving,
				],
			},
			count: 100,
			offset: 0,
		};

		const abortController = new AbortController();
		controller.current = abortController;
		lock.current += 1;

		return request<List<Price>>('priceList', params, undefined, abortController.signal)
			.then((item: List<Price>) => ({ item: item.list, aborted: abortController.signal.aborted }))
			.catch((error: Error) => {
				console.warn('Failed to get a list of prices for engraving: ', error.message ? error.message : error);

				return { item: [], aborted: abortController.signal.aborted };
			})
			.finally(() => {
				if (abortController.signal.aborted) return;

				setLoading(false);
				lock.current -= 1;
			});
	}, [props.crematoryId, props.clinicId]);

	const prices = React.useMemo(
		() => getPrices(rawPrices, props.priceType, props.serviceType, props.clinicId ?? null, props.ignoreClinic),
		[rawPrices, props.priceType, props.serviceType, props.clinicId, props.ignoreClinic],
	);

	const list = props.value.filter(isPresent);
	const canAdd = prices.length > list.length;

	React.useEffect(() => {
		return () => controller.current?.abort();
	}, []);

	React.useEffect(() => {
		if (lock.current) return;

		const size = Math.min(list.length, prices.length);
		const value: Array<PetEngraving> = [];

		if (props.prefillEngraving || prices.length) {
			for (let i = 0; i < size; i++) {
				const price = prices[i];
				value.push({
					...list[i],
					name: price.maxSize && price.maxSize > 0 ? list[i].name.slice(0, price.maxSize) : list[i].name,
				});
			}
		}

		if (list.length > size) {
			for (let i = size; i < list.length; i++) {
				if (list[i].id > 0) {
					value.push({
						...list[i],
						removed: true,
					});
				}
			}
		}

		props.onChange(value);
	}, [prices]);

	React.useEffect(() => {
		props.onChangePrice(prices);
	}, [prices]);

	if (!prices.length && !loading) return null;

	return (
		<div className="engraving-list">
			<h5><b>Engraving:</b></h5>
			{loading ? <Loading caption="" /> : null}
			{
				!loading && prices.length > 0 ? (
					<ul>
						{
							list.map((item: PetEngraving) => (
								<li key={item.order} className="is-relative">
									<div className="engraving-list__form">
										<input
											className="form-control"
											value={item.name}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
												props.onChange(updateEngraving(event.currentTarget.value, item.order, props.value, prices))}
										/>
										<button
											type="button"
											className="action-container"
											onClick={() => props.onChange(removeEngraving(item.order, props.value, prices))}
										>
											<i className="fa fa-trash action_danger" />
										</button>
									</div>
									{renderHelperText(item.order, prices, props.value, showPricesToClinic)}
								</li>
							))
						}
						{
							canAdd ? (
								<li>
									<button
										onClick={() => props.onChange(addEngraving(props.value))}
										className="btn btn-transparent text-primary"
										type="button"
									>
										<i className="fa fa-plus" />
										<span className="icon-margin">Add Engraving Line</span>
									</button>
								</li>
							) : null
						}
					</ul>
				) : null
			}
		</div>
	);
};
