import * as React from 'react';
import {connect} from 'react-redux';

import {bindActionCreators} from 'redux';

import {getSignalR} from '@common/react/utils/configureSignarR';
import {ChatMessage, ChatMessageAccess } from '@common/react/objects/Chat';
import {Notification} from '@common/typescript/objects/Notification';
import * as LoginState from '@common/react/store/Login';
import {BaseUser} from '@common/react/objects/BaseUser';
import {BaseApplicationState} from '@common/react/store';

interface ReduxProps {
	user: BaseUser | null;
}

interface Actions {
	loginActions: LoginState.LoginActionCreators<BaseUser, BaseApplicationState<BaseUser>>;
}

type Props = ReduxProps & Actions;

class SignalR extends React.Component<Props, {}> {
	componentDidMount() {
		getSignalR().then((connection) => {
			connection.on('handle', (notification: Notification<BaseUser>) => {
				const user = this.props.user as BaseUser;

				switch (notification.objectType) {
					case 'ChatMessage':
						const chatMessage = notification.data as ChatMessage;
						if (user.id !== chatMessage.userId) {
							this.props.loginActions.updateUser({unviewedMessagesCount: user.unviewedMessagesCount + 1 });
						}
						break;
					case 'ChatMessageAccess':
						const chatMessageAccess = notification.data as ChatMessageAccess;
						if (user.id === chatMessageAccess.userId && chatMessageAccess.viewed && user.unviewedMessagesCount > 0) {
							this.props.loginActions.updateUser({unviewedMessagesCount: user.unviewedMessagesCount - 1});
						}
						break;
					case 'Init':
					case 'UserSession':
						this.props.loginActions.setUserAndSession(notification.data.user as BaseUser, notification.data.guid as string);
						break;
					case 'NotificationUser':
						if (notification.service) {
							this.props.loginActions.updateUser({ unviewedNotificationsCount: user.unviewedNotificationsCount - 1 });
						}
						break;
					default:
						if (!notification.service) {
							this.props.loginActions.updateUser({ unviewedNotificationsCount: user.unviewedNotificationsCount + 1 });
						}
						break;
				}
			});
		});
	}

	render() {
		return null;
	}
}

export default connect<ReduxProps, Actions, {}, BaseApplicationState<BaseUser>>(
	state => ({
		user: state.login.user
	}),
	dispatch => ({
		loginActions: bindActionCreators(LoginState.getActionCreators(), dispatch)
	})
)(SignalR);