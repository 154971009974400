/* --- Begin Polyfills --- */
import 'raf/polyfill';

import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/set';
import 'core-js/features/map';
import 'core-js/features/weak-map';
import 'core-js/features/promise';
import 'core-js/features/url';
import 'core-js/features/url-search-params';

/* --- End Polyfills --- */

import { BootManager } from '@app/services/BootManager';

const manager = new BootManager();
manager.boot();
