import { Action, ActionCreatorsMapObject, Reducer } from 'redux';

import { BaseAppThunkAction } from '@common/react/store';

import { CrematoryPreferences } from '@app/objects/Crematory';
import { User } from '@app/objects/User';
import { request } from '@app/components/Api';
import { ApplicationState } from '@app/store/index';

export interface CrematoryPreferencesStateRecord {
	isLoading: boolean;
	item: CrematoryPreferences | null;
	error: string | null;
}

export interface CrematoryPreferencesState {
	[id: number]: CrematoryPreferencesStateRecord;
}

const defaultCrematoryPreferencesState: CrematoryPreferencesState = {};

export enum ActionKeys {
	REQUESTCREMATORYPREFERENCES = 'REQUESTCREMATORYPREFERENCES',
	SETCREMATORYPREFERENCES = 'SETCREMATORYPREFERENCES',
	UPDATECREMATORYPREFERENCES = 'UPDATECREMATORYPREFERENCES',
	REMOVECREMATORYPREFERENCES = 'REMOVECREMATORYPREFERENCES',
}

interface RequestCrematoryPreferencesAction extends Action<ActionKeys.REQUESTCREMATORYPREFERENCES> {
	id: number;
}

interface SetCrematoryPreferencesAction extends Action<ActionKeys.SETCREMATORYPREFERENCES> {
	id: number;
	item: CrematoryPreferences | null;
	error: string | null;
}

interface RemoveCrematoryPreferencesAction extends Action<ActionKeys.REMOVECREMATORYPREFERENCES> {
	id: number;
}

export type KnownPreferencesActions = RequestCrematoryPreferencesAction
	| SetCrematoryPreferencesAction
	| RemoveCrematoryPreferencesAction;

type ActionResult = Promise<CrematoryPreferences> | void;
type ThunkAction<T extends ActionResult> = BaseAppThunkAction<KnownPreferencesActions, User, ApplicationState, T>

export interface CrematoryPreferencesActionCreators extends ActionCreatorsMapObject<ThunkAction<Promise<CrematoryPreferences> | void>> {
	loadPreferences: (crematoryId: number, count: number) => ThunkAction<Promise<CrematoryPreferences>>;
	updatePreferences: (crematoryId: number, preferences: Partial<CrematoryPreferencesStateRecord>) => ThunkAction<void>;
	removePreferences: (crematoryId: number) => ThunkAction<void>;
}

export interface CrematoryPreferencesMappedActionCreators extends ActionCreatorsMapObject<ActionResult> {
	loadPreferences: (crematoryId: number, count: number) => Promise<CrematoryPreferences>;
	updatePreferences: (crematoryId: number, preferences: Partial<CrematoryPreferences>) => void;
	removePreferences: (crematoryId: number) => void;
}

export function getCrematoryPreferencesActionCreators(): CrematoryPreferencesActionCreators {
	function loadPreferences(crematoryId: number, count: number) {
		return (dispatch, getState): Promise<CrematoryPreferences> => {
			const state = (getState() as ApplicationState).crematoryPreferences;
			const record = state[crematoryId];

			if (!record?.item) {
				const task = request<CrematoryPreferences>('crematoryPreferencesState', {
					ids: [crematoryId],
					count,
				})
					.then()
					.catch();

				dispatch({
					id: crematoryId,
					type: ActionKeys.REQUESTCREMATORYPREFERENCES,
				});

				return task;
			}

			return Promise.resolve(record.item);
		};
	}

	function updatePreferences(crematoryId: number, preferences: Partial<CrematoryPreferencesStateRecord>) {
		return (dispatch, getState): void => {
			const state = (getState() as ApplicationState);
			const record = state[crematoryId];

			if (!record.item) {
				dispatch({
					id: crematoryId,
					type: ActionKeys.SETCREMATORYPREFERENCES,
					item: null,
					error: `Unable to update crematory preferences with id ${crematoryId} as no record found in store!`,
				});

				return;
			}

			dispatch({
				id: crematoryId,
				type: ActionKeys.SETCREMATORYPREFERENCES,
				item: { ...record.item, ...preferences },
				error: null,
			});
		};
	}

	function removePreferences(crematoryId: number) {
		return (dispatch, getState): void => {
			const state = (getState() as ApplicationState);
			const record = state[crematoryId];

			if (record) {
				dispatch({
					id: crematoryId,
					type: ActionKeys.REMOVECREMATORYPREFERENCES,
				});
			}
		};
	}

	return {
		loadPreferences,
		updatePreferences,
		removePreferences,
	};
}

export function getCrematoryPreferencesReducer(): Reducer<CrematoryPreferencesState, KnownPreferencesActions> {
	return (state: CrematoryPreferencesState = defaultCrematoryPreferencesState, action: KnownPreferencesActions) => {
		switch (action.type) {
		case ActionKeys.REQUESTCREMATORYPREFERENCES:
			return {
				...state,
				[action.id]: {
					isLoading: true,
					item: null,
					error: null,
				},
			};

		case ActionKeys.SETCREMATORYPREFERENCES:
			return {
				...state,
				[action.id]: {
					isLoading: false,
					item: action.item,
					error: action.error,
				},
			};

		case ActionKeys.REMOVECREMATORYPREFERENCES:
			if (state[action.id]) {
				const newState = { ...state };
				delete newState[action.id];

				return newState;
			}

			return state;

		default:
			return state;
		}
	};
}
