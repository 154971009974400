import {
	FileInterface,
	FileType,
} from '@common/typescript/objects/FileInterface';
import { Nullable } from '@common/typescript/objects/Nullable';

export type TransformData = (data: FormData) => FormData;

interface ResponseResult<T> {

	success: boolean;

	response: T;
}

interface UploadFileParams<TParams> {
	file: File;
	fileType: FileType;

	type?: string;
	objectId?: number;

	data?: TParams;
	headers?: HeadersInit;
}

export const upload = <TParams = null>(
	params: UploadFileParams<TParams>,
	transformData: TransformData = (data: FormData) => data,
	onProgress?: (progress: Nullable<number>) => void,
): Promise<FileInterface> => {
	const promise: Promise<ResponseResult<FileInterface>> = new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		const formData = new FormData();

		formData.append('file', params.file);
		formData.append('fileType', params.fileType.toString());

		if (params.type) {
			formData.append('objectType', params.type);
		}

		if (params.objectId) {
			formData.append('objectId', params.objectId.toString());
		}

		if (params.data) {
			const data = params.data;
			Object.keys(data).forEach((key: string) => formData.append(`data[${key}]`, data[key].toString()));
		}

		if (onProgress !== undefined) {
			onProgress(0);
			xhr.upload.addEventListener('progress', (e) => onProgress(e.loaded / e.total));
		}

		xhr.addEventListener('load', () => resolve(JSON.parse(xhr.response) as ResponseResult<FileInterface>));
		xhr.addEventListener('error', () => reject(new Error('File upload failed')));
		xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
		xhr.open('POST', 'fineUploader', true);

		xhr.send(transformData(formData));
	});

	return promise
		.then(((response: ResponseResult<FileInterface>) => {
			if (!response.success) throw response.response;

			return response.response;
		}))
		.catch((error) => {
			console.log(error.message);
			throw error;
		})
		.finally(() => onProgress?.(null));
};
