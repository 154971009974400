import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Field, FieldProps, useFormikContext } from 'formik';

import Checkbox from 'antd/lib/checkbox';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import Datepicker from '@common/react/components/Forms/Datepicker/Datepicker';

import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { PetSpecieSelect } from '@app/components/UI/Inputs/PetSpecieSelect';
import { BreedSelect } from '@app/components/UI/Inputs/PetBreedSelect';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { GenderSelect } from '@app/components/UI/Inputs/GenderSelect';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import {
	PetEngraving,
	ServiceType,
	updateEngraving as getUpdatedEngraving,
	needUpdateEngraving,
} from '@app/objects/Pet';
import { EngravingList } from '@app/components/Various/Pet/EngravingList';
import { Price } from '@app/objects/Price';
import { ApplicationState } from '@app/store';
import { useCrematory } from '@app/hooks/useCrematory';

export const PetInfoSection: React.FC = () => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory(values.crematoryId);
	const [availableEngravingPrices, setAvailableEngravingPrices] = React.useState<Array<Price>>([]);
	const needUpdate = needUpdateEngraving(values.name, values.engraving, availableEngravingPrices, values.serviceType, crematory?.prefillEngraving);

	React.useEffect(() => {
		if (needUpdate && values.id < 0 && values.name) {
			setFieldValue('engraving', getUpdatedEngraving(values.name, values.engraving));
		}
	}, [availableEngravingPrices, values.serviceType, values.name, values.id]);

	return (
		<SectionContainer titleLocalizationKey="sections.pet">
			<div className="row">
				<Field name="name">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="form-group col-sm-4"
							title={`${t(iKey('labels.pet-name'))} *`}
							render={({ field, form }: FieldProps<string, PetFormValues>) => (
								<input
									type="text"
									className="form-control"
									id={field.name}
									{...field}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										field.onChange(e);
										const engraving = form.values.engraving;

										if (needUpdate) {
											form.setFieldValue('engraving', getUpdatedEngraving(e.target.value, engraving));
										}
									}}
								/>
							)}
						/>
					)}
				</Field>
				<Field name="petSpecieId">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="form-group col-sm-4"
							title={`${t(iKey('labels.pet-species'))} *`}
							render={({ field }: FieldProps<number, PetFormValues>) => (
								<PetSpecieSelect
									value={field.value}
									onChange={(value: number) => {
										fieldProps.form.setFieldValue('petSpecieId', value);
										fieldProps.form.setFieldValue('petBreedId', -1);
										fieldProps.form.setFieldValue('breedName', '');
									}}
									crematoryId={crematory?.id}
								/>
							)}
						/>
					)}
				</Field>
				<Field name="petBreedId">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<>
							<FormikInput
								fieldProps={fieldProps}
								containerClassName="form-group col-sm-4"
								title={`${t(iKey('labels.pet-breed'))} *`}
								render={({ field, form }: FieldProps<number, PetFormValues>) => (
									<>
										<BreedSelect
											petSpecieId={form.values.petSpecieId}
											crematoryId={crematory?.id}

											onChange={(id: number | undefined) => {
												form.setFieldValue('petBreedId', id, false);
											}}
											value={field.value}
										/>
										<Field name="isMixed">
											{({ field: mixedField, form }: FieldProps<boolean, PetFormValues>) => (
												<Checkbox
													onChange={(e) => form.setFieldValue(mixedField.name, e.target.checked)}
													checked={mixedField.value}
												>
													Mixed
												</Checkbox>
											)}
										</Field>
									</>
								)}
							/>
						</>
					)}
				</Field>
			</div>
			<div className="row">
				<Field name="genderId">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="form-group col-sm-4"
							title={`${t(iKey('labels.pet-gender'))} *`}
							render={({ field, form }: FieldProps<number, PetFormValues>) => (
								<GenderSelect
									handleChange={(name, value) => form.setFieldValue(name, value)}
									value={field.value}
								/>
							)}
						/>
					)}
				</Field>
				<Field name="color">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={t(iKey('labels.pet-color'))}
							containerClassName="form-group col-sm-4"
						/>
					)}
				</Field>
				<Field name="receivedDate">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							containerClassName="form-group col-sm-6 col-md-4"
							title={t(iKey('labels.date-received'))}
							render={({ field, form }) => (
								<Datepicker
									maxDateToday
									format={user?.localeUserPreferences?.dateFormat}

									value={field.value}
									onChange={(date) => form.setFieldValue(field.name, date)}
								/>
							)}
						/>
					)}
				</Field>
			</div>

			<div className="row">
				<Field name="reportedWeight">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={`${t(iKey('labels.pet-reported-weight'))} *`}
							containerClassName="form-group col-sm-4"
							render={({ field, form }: FieldProps<number, PetFormValues>) => (
								<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} />
							)}
						/>
					)}
				</Field>
				<Field name="actualWeight">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={t(iKey('labels.pet-actual-weight'))}
							containerClassName="form-group col-sm-4"
							render={({ field, form }: FieldProps<number, PetFormValues>) => (
								<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} />
							)}
						/>
					)}
				</Field>
				<Field name="ashesWeight">
					{(fieldProps: FieldProps<PetFormValues>) => (
						<FormikInput
							fieldProps={fieldProps}
							title={t(iKey('labels.pet-ashes-weight'))}
							containerClassName="form-group col-sm-4"
							render={({ field, form }: FieldProps<number, PetFormValues>) => (
								<NumberField {...field} onChange={(value: number) => form.setFieldValue(field.name, value)} />
							)}
						/>
					)}
				</Field>
			</div>

			<Field name="engraving">
				{
					(fieldProps: FieldProps<Array<PetEngraving>>) => {
						if (fieldProps.form.values.serviceType === ServiceType.Communal) return null;

						return (
							<div className="row">
								<div className="col-sm-6 col-md-4 form-group">
									<EngravingList
										crematoryId={fieldProps.form.values.crematoryId}
										clinicId={fieldProps.form.values.clinicId}
										priceType={fieldProps.form.values.priceType}
										serviceType={fieldProps.form.values.serviceType}
										value={fieldProps.field.value}
										onChange={(value: Array<PetEngraving>) => {
											fieldProps.form.setFieldValue(fieldProps.field.name, value);
										}}
										onChangePrice={setAvailableEngravingPrices}
										prefillEngraving={crematory?.prefillEngraving}
									/>
								</div>
							</div>
						);
					}
				}
			</Field>
		</SectionContainer>
	);
};
