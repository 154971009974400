import { Nullable } from '@common/typescript/objects/Nullable';

import { DeliveryType, ServiceType } from '@app/objects/Pet';
import { PriceType, Price, PriceKind } from '@app/objects/Price';
import { Query } from '@app/services/query/Query';
import { ProductContainer } from '@app/components/Utils/Prices/Helpers';

export interface FilterData {
	crematoryId: number;
	clinicId: Nullable<number>;

	priceType: PriceType;
	serviceType: ServiceType;

	petSpecieId: number;
	weight: number;

	deliveryType: DeliveryType;
	discountId: Nullable<number>;
}

interface Pick {
	price: Price;
	distance: number;
}

function getDistance(rangeFrom: number, rangeTo: number, value: number): number {
	if (value < rangeFrom) return -1;
	if (value >= rangeTo) return value - rangeTo;

	return 0;
}

function select(list: Array<Pick>): Nullable<Price> {
	let min: Nullable<Pick> = null;

	for (let i = 0; i < list.length; i++) {
		const item = list[i];

		if (min === null || item.distance <= min.distance) {
			min = item;
		}
	}

	return min?.price ?? null;
}

export class PriceFilter {
	private readonly data: FilterData;

	constructor(data: FilterData) {
		this.data = data;
	}

	private bySpecies(query: Query<Price>): Query<Price> {
		if (this.data.petSpecieId <= 0) return query;

		const filtered = query.clone().where((q: Price) => q.petSpecieId === this.data.petSpecieId);
		if (filtered.any()) return filtered;

		return query.where((q: Price) => q.petSpecieId === null);
	}

	private getProductQuery(prices: Array<Price>, type: PriceKind): Array<Price> {
		const key = type === PriceKind.UrnPrice ? 'urns' : 'products';
		const ids = this.data[key].filter((q: ProductContainer) => !q.removed).map<Nullable<number>>((q: ProductContainer) => q.categoryId);
		if (!ids.length) {
			ids.push(null);
		}

		const products = prices
			.filter((q: Price) => q.priceKind === type)
			.filter((q: Price) => q.clinicId === this.data.clinicId || q.clinicId === null)
			.filter((q: Price) => ids.includes(q.inventoryItemId));

		const clinicQuery = products.filter((q: Price) => q.clinicId === this.data.clinicId);
		if (clinicQuery.length !== 0) return clinicQuery;

		return products.filter((q: Price) => q.clinicId === null);
	}

	private getDiscountQuery(prices: Array<Price>): Array<Price> {
		return prices
			.filter((q: Price) => q.priceKind === PriceKind.Discount)
			.filter((q: Price) => q.clinicId === null);
	}

	private getQuery(prices: Array<Price>) {
		const delivery = prices
			.filter((q: Price) => q.crematoryId === this.data.crematoryId)
			.filter((q: Price) => q.serviceType === this.data.serviceType)
			.filter((q: Price) => q.priceType === this.data.priceType)
			.filter((q: Price) => q.priceKind === PriceKind.DeliveryPrice);

		const clinicQuery = delivery.filter((q: Price) => q.clinicId === this.data.clinicId);

		if (clinicQuery.length === 0) {
			return delivery.filter((q: Price) => q.clinicId === null);
		}

		return delivery.filter((q: Price) => q.clinicId === this.data.clinicId);
	}

	private getRushQuery(prices: Array<Price>) {
		const rush = prices
			.filter((q: Price) => q.crematoryId === this.data.crematoryId)
			.filter((q: Price) => q.serviceType === this.data.serviceType)
			.filter((q: Price) => q.priceType === this.data.priceType)
			.filter((q: Price) => q.priceKind === PriceKind.RushFee);

		const clinicQuery = rush.filter((q: Price) => q.clinicId === this.data.clinicId);

		if (clinicQuery.length === 0) {
			return rush.filter((q: Price) => q.clinicId === null);
		}

		return rush.filter((q: Price) => q.clinicId === this.data.clinicId);
	}

	private getBaseQuery(prices: Array<Price>): Query<Price> {
		const base: Query<Price> = new Query<Price>(prices)
			.where((q: Price) => q.crematoryId === this.data.crematoryId)
			.where((q: Price) => q.serviceType === this.data.serviceType)
			.where((q: Price) => q.priceKind === PriceKind.BasePrice);

		const clinicQuery = base.clone().where((q: Price) => q.clinicId === this.data.clinicId);
		if (clinicQuery.any()) {
			return this.bySpecies(clinicQuery);
		}

		return this.bySpecies(base.where((q: Price) => q.clinicId === null));
	}

	public getBase(prices: Array<Price>): Nullable<Price> {
		const list = this.getBaseQuery(prices).array().sort((a: Price, b: Price) => a.from - b.from);

		const pick: Array<Pick> = list.map((q: Price) => ({
			price: q,
			distance: getDistance(q.from, q.to, this.data.weight),
		})).filter((q: Pick) => q.distance >= 0);

		return select(pick);
	}

	public getDelivery(prices: Array<Price>): Nullable<Price> {
		return this.getQuery(prices).find((q: Price) => q.deliveryType === this.data.deliveryType);
	}

	public getProduct(prices: Array<Price>, type: PriceKind): Array<Price> {
		return this.getProductQuery(prices, type);
	}

	public getDiscount(prices: Array<Price>): Nullable<Price> {
		return this.getDiscountQuery(prices).find((q: Price) => q.id === this.data.discountId);
	}

	public getRush(prices: Array<Price>): Nullable<Price> {
		const query = this.getRushQuery(prices);
		if (!query.length) return null;

		return query[0];
	}
}
