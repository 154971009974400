import React from 'react';
import { useSelector } from 'react-redux';

import clsx from 'clsx';

import Spin from 'antd/lib/spin';
import List from 'antd/lib/list';
import Divider from 'antd/lib/divider';

import { Nullable } from '@common/typescript/objects/Nullable';

import { OnScrollManager } from '@app/services/OnScrollManager';
import { AvailableStoreEntry } from '@app/objects/StoreEntry';
import { useCrematory } from '@app/hooks/useCrematory';
import { ApplicationState } from '@app/store';
import { UserRole } from '@app/objects/User';
import { ModalFormValues, newStoreEntry } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';
import { Price } from '@app/objects/Price';

interface StoreEntriesItemListProps {
	list?: Nullable<Array<AvailableStoreEntry>>;
	storeEntry: Nullable<ModalFormValues>;
	loading: boolean;
	total: number;

	onLoadMore: () => void;
	onSelectStore: (value: ModalFormValues) => void;
}

export function sorterPrices(prices: Array<Price>) {
	const values = prices.map((price) => price.batchPrice + Math.max(1 - price.batchCount, 0) * price.value);
	const sorter = values.sort((a, b) => a - b);

	return {
		from: sorter[0],
		to: sorter[sorter.length - 1],
	};
}

function getPrice(prices: Array<Price>): string {
	const { from, to } = sorterPrices(prices);

	if (from === to) return `$${from?.toFixed(2)}`;

	return `$${from?.toFixed(2)} - $${to?.toFixed(2)}`;
}

interface EntryGroups {
	special: Array<AvailableStoreEntry>;
	default: Array<AvailableStoreEntry>;
}

function toEntryGroups(list: Array<AvailableStoreEntry> = []): EntryGroups {
	return {
		special: list.filter((item: AvailableStoreEntry) => item.storeEntry.isSpecial),
		default: list.filter((item: AvailableStoreEntry) => !item.storeEntry.isSpecial),
	};
}

export const StoreEntriesItemList: React.FC<StoreEntriesItemListProps> = (props: StoreEntriesItemListProps) => {
	const {
		list,
		onLoadMore,
		loading,
		onSelectStore,
		total,
		storeEntry,
	} = props;

	const scrollRef = React.useRef<OnScrollManager>(new OnScrollManager());
	const crematory = useCrematory();
	const role = useSelector((state: ApplicationState) => state.login.user?.role);
	const showPricesToClinic = role !== UserRole.ClinicUser && role !== UserRole.ClinicManager ? true : (crematory?.showPricesToClinic ?? false);
	const groups: EntryGroups = toEntryGroups(list ?? []);

	return (
		<div
			className="pet-editor__product__list"
			onScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
				const hasMore = Boolean(list && total > list.length);

				scrollRef.current.onScroll(e.target as HTMLElement, loading, hasMore, onLoadMore);
			}}
		>
			{loading && !groups.default.length && !groups.special.length && <Spin style={{ display: 'block' }} />}
			{groups.default.length ? (
				<>
					<Divider orientation="left">Default Urns</Divider>
					<List
						loading={loading}
						dataSource={groups.default}
						renderItem={(item: AvailableStoreEntry) => (
							<List.Item
								onClick={() => onSelectStore(newStoreEntry(item))}
								className={clsx(item.id === storeEntry?.id && 'list-item__active')}
							>
								<List.Item.Meta title={item.storeEntry.name} />
								{showPricesToClinic && <div className="icon-margin">{getPrice(item.prices)}</div>}
							</List.Item>
						)}
						size="small"
					/>
				</>
			) : null}
			{
				groups.special.length
					? (
						<>
							<Divider orientation="left">Special Urns</Divider>
							<List
								loading={loading}
								dataSource={groups.special}
								renderItem={(item: AvailableStoreEntry) => (
									<List.Item
										onClick={() => onSelectStore(newStoreEntry(item))}
										className={clsx(item.id === storeEntry?.id && 'list-item__active')}
									>
										<List.Item.Meta title={item.storeEntry.name} />
										{showPricesToClinic && <div className="icon-margin">{getPrice(item.prices)}</div>}
									</List.Item>
								)}
								size="small"
							/>
						</>
					) : null
			}
		</div>
	);
};
