import * as React from 'react';

import { Nullable } from '@common/typescript/objects/Nullable';

import {
	CoreSelect,
	CoreSelectProps,
} from '@app/components/UI/Inputs/CoreSelect';
import { PickupServicesSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { SelectRequestParams } from '@app/store/SelectList/ListActions';
import { ServiceType } from '@app/objects/Pet';
import { PriceKind, PriceType } from '@app/objects/Price';

interface BaseProps {
	serviceType: ServiceType;
	priceType: PriceType;
	crematoryId?: number;
	clinicId?: Nullable<number>;
	availableForPricing?: boolean;

	disabled?: boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;

	pickDefault?: (entity) => number;
	onDeselect?: (id: number) => void;
	onSelect?: (id: number) => void;

	label?: string;
	placeholder?: string;
	allowClear?: boolean;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value?: number) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value?: Array<number>) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

interface RequestParams extends SelectRequestParams<number> {
	priceType: PriceType;
	serviceType: ServiceType;
	clinicId: Nullable<number>;
}

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

export const PickupServicePricesSelect: React.FC<OwnProps> = (props: OwnProps): JSX.Element => {
	const [query, setQuery] = React.useState<string>('');
	const filters: Nullable<PickupServicesSelectFilter> = React.useMemo(() => ({
		name: query,
		search: query,
		priceKind: [PriceKind.PickupPrice],
		crematoryId: props.crematoryId,
	}), [props.crematoryId, query]);

	const reqParams = React.useMemo(() => ({
		priceType: props.priceType,
		serviceType: props.serviceType,
		clinicId: props.clinicId,
		availableForPricing: props.availableForPricing,
	}), [props.priceType, props.serviceType, props.clinicId, props.availableForPricing]);

	const properties: CoreSelectProps<number, 'pickupServicePrices', RequestParams> = {
		...props,
		onSearch: setQuery,
		filters,
		reqParams,
		shouldPickDefault: () => false,
		isEmptyValue,
		store: 'pickupServicePrices',
	};

	return <CoreSelect {...properties} />;
};
