import * as React from 'react';

import { DateType, formatDate } from '@app/components/Utils/Utils';

interface DateProps {
	date: DateType,
	view?: 'short' | 'long',
	withTime?: boolean,
	withWeekDay?: boolean,
	fallback?: string,
	containerClassName?: string;
}

export const DateView: React.FC<DateProps> = 	({
	date, view = 'short', withTime = false, withWeekDay = false, fallback = '-', containerClassName,
}: DateProps): JSX.Element => (
	<span className={containerClassName}>
		{
			formatDate(date, {
				view,
				withTime,
				withWeekDay,
				fallback,
			})
		}
	</span>
);
