import { fetch } from '@common/react/components/Api/Fetch';

import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { ResponseErrorContent, ResponseError } from '@common/react/components/Api/RequestError';

interface Message<T> {
	success: number;
	response: T;
	session: string;
}

/**
 * This is the base of request - simple fetch to server to dedicated endpoint(data).
 * The result is converted to RequestError (upon failure) or item of requested entity T.
 * @param {string} type - endpoint request is intended to be made to (Actor name with word 'Actor' omitted, spelled with camelCase)
 * @param {BaseParams} data - request params (actor message data)
 * @param {TApplicationState} state - current state (is required for login data)
 * @param signal
 * @returns {Promise<T>} promise that is resolved with requested data or rejected with RequestError object
 */
export function request<
	T,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
	>(type: string, data: BaseParams = {}, state?: TApplicationState, signal?: AbortSignal): Promise<T> {
	const headers: HeadersInit = {
		'Content-type': 'application/json; charset=utf-8',
	};

	if (state?.login?.session) {
		headers.Cookie = `session=${state.login.session}`;
	}

	return fetch('api/post', {
		signal,
		credentials: 'same-origin',
		method: 'POST',
		headers,
		body: JSON.stringify({
			type,
			data: JSON.stringify(data),
		}),
	})
		.then((response) => response.json() as Message<T | ResponseErrorContent>)
		.then((data: Message<T | ResponseErrorContent>) => {
			if (!data.success) throw new ResponseError(data.response as ResponseErrorContent);

			return data.response as T;
		});
}
