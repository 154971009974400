import { Nullable } from '@common/typescript/objects/Nullable';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { WithId } from '@common/typescript/objects/WithId';

import { toWords } from '@app/components/Utils/Utils';

export interface SpecialService extends WithDeleted {
	name: string;
	description: string;
	crematoryId: Nullable<number>;
	parent: Nullable<SpecialService>;
	parentId: Nullable<number>;
	specialServiceType: Nullable<SpecialServiceType>;

	hasNote: boolean;
	defaultNote: string;
}

export interface SpecialServiceSelectInfo extends WithId {
	name: string;
}

export enum SpecialServiceType {
	Service = 0,
	Kit = 1,
	KitService = 2,
}

const group: Array<SpecialServiceType> = [
	SpecialServiceType.Kit,
];

export function isGroup(serviceType?: Nullable<SpecialServiceType>): boolean {
	if (serviceType === null || serviceType === undefined) return false;

	return group.includes(serviceType);
}

export function getName(serviceType: SpecialServiceType): string {
	return toWords(SpecialServiceType[serviceType]);
}
