import { WithDeleted } from '@common/typescript/objects/WithDeleted';

export enum TriggerEvent
{
	OnWillEnter = 0, // Trigger will be fired before entering current status
	OnWillLeave = 1, // Trigger will be fired before leaving current status
}

export enum TriggerAction
{
	ActualWeightPopup = 0, // Show popup with request of actual weight,
	UrnPopup = 1, // Show popup with request of urn (urn category, really),
	TrackingPopup = 2, // Show popup with request of tracking id,
	InternalIdPopup = 3, // Show popup with request of internal id,
	AshesWeightPopup = 4, // Show popup with request of ashes weight,
	ProductPopup = 5,
}

export interface TriggerStatus extends WithDeleted {
	statusOrderId: number;
	event: TriggerEvent;
	action: TriggerAction;
}
