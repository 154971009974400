import * as React from 'react';
import { useSelector } from 'react-redux';

import { Nullable } from '@common/typescript/objects/Nullable';
import { ImageComponent } from '@common/react/components/UI/Image/Image';

import {
	CrematorySelectFilter,
} from '@app/store/SelectList/SelectsInterfaces';

import { CoreSelect } from '@app/components/UI/Inputs/CoreSelect';
import { CrematoryListInfo } from '@app/objects/Crematory';
import { ApplicationState } from '@app/store';

interface BaseProps {
	disabled?: boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;

	pickDefault?: (entity) => number;
	onSelect?: (id: number) => void;
	onDeselect?: (id: number) => void;

	label?: string;
	placeholder?: string;
	noPreselect?: boolean;
	allowClear?: boolean;
	withLogo?: boolean;
}

interface SingleProps extends BaseProps {
	value?: number;
	onChange: (value: number | undefined) => void;
	multiple?: false;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (value: Array<number> | undefined) => void;
	multiple: true;
}

type OwnProps = SingleProps | MultipleProps;

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value): boolean {
	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

function getName(item: CrematoryListInfo, withLogo?: boolean) {
	if (withLogo) {
		return (
			<div className="crematory-logo">
				<ImageComponent src={item.avatar} alt={item.name} className="crematory-logo__img" />
				<span className="icon-margin">{item.name}</span>
			</div>
		);
	}

	return item.name;
}

export const CrematorySelect = (props: OwnProps): JSX.Element => {
	const [query, setQuery] = React.useState<string>('');
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);

	const filters: Nullable<CrematorySelectFilter> = React.useMemo(() => ({
		name: query,
		search: query,
	}), [query]);

	React.useEffect(() => {
		if (activeCrematoryId) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore:next-line
			props.onChange(activeCrematoryId);
		}
	}, [activeCrematoryId]);

	const properties = {
		...props,
		onSearch: setQuery,
		filters,
		getName: (item: CrematoryListInfo) => getName(item, props.withLogo),
		isEmptyValue,
		shouldPickDefault,
	};

	return <CoreSelect store="crematories" {...properties} />;
};
