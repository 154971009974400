// import * as React from 'react';
import { withRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { ErrorBoundary } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';

import '@app/scss/pages/error.scss';

class SentryErrorBoundaryInner extends ErrorBoundary {
	componentDidCatch(error, errorInfo) {
		super.componentDidCatch(error, errorInfo);
		Sentry.captureException(error);
	}
}

export const SentryErrorBoundary = withRouter(SentryErrorBoundaryInner);
