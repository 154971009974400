import * as React from 'react';

import Icon from 'antd/lib/icon';

import {
	ClinicSelectFilter,
} from '@app/store/SelectList/SelectsInterfaces';
import { CoreSelect } from '@app/components/UI/Inputs/CoreSelect';
import { ClinicSelectInfo } from '@app/objects/Clinic';
import { EntityType } from '@app/store/SelectList/UtilityTypes';

type Entity = EntityType<'clinics'>;

interface BaseSelectProps {
	crematoryId?: number;
	customers?: boolean;
	pickDefault?: (entity: Array<ClinicSelectInfo>) => number;
	isDefault?: boolean;
	markCustomer?: boolean;

	multiple?: boolean;
	disabled?: boolean;
	className?: string;
	selectClassName?: string;
	activeClassName?: string;
	localOptions?: Array<Entity>;

	onSelect?: (id: number) => void;
	onDeselect?: (value: number) => void;
	allowClear?: boolean;

	label?: string;
	placeholder?: string;
}

interface SingleSelectProps extends BaseSelectProps {
	value?: number;
	onChange: (value?: number) => void;

	multiple?: false;
}

interface MultipleSelectProps extends BaseSelectProps {
	value?: Array<number>;
	onChange: (value?: Array<number>) => void;

	multiple: true;
}

type ClinicSelectProps = SingleSelectProps | MultipleSelectProps;

function isEmptyValue(value?: number | Array<number>) {
	if (value === undefined) return true;
	if (Array.isArray(value)) return value.length === 0;

	return value === -1;
}

function shouldPickDefault(store, multiple, isEmptyValue, reqProps, value, isDefault = true): boolean {
	if (!isDefault) return false;

	return !store.isLoading
		&& store.items?.length && store.pagination.total === 1
		// eslint-disable-next-line sonarjs/no-all-duplicated-branches
		&& (multiple ? isEmptyValue?.(value) : isEmptyValue?.(value))
		&& reqProps.isEmpty(store.filters);
}

function getName(item: ClinicSelectInfo, markCustomer: boolean = true) {
	if (markCustomer) {
		return (
			<div className="select__item-mark">
				{item.isCustomer && <Icon type="user" title="Customer" />}
				<span>{item.name}</span>
			</div>
		);
	}

	return item.name;
}

export const ClinicSelect: React.FC<ClinicSelectProps> = (props: ClinicSelectProps) => {
	const [query, setQuery] = React.useState<string>(() => '');

	const filters: ClinicSelectFilter = React.useMemo(() => ({
		name: query,
		search: query,
		crematories: props.crematoryId ? [props.crematoryId] : [],
		isCustomer: props.customers,
	}), [query, props.customers, props.crematoryId]);

	const properties = {
		...props,
		onSearch: setQuery,
		filters,
		shouldPickDefault: (store, multiple, isEmptyValue, reqProps, value) =>
			shouldPickDefault(store, multiple, isEmptyValue, reqProps, value, props.isDefault),
		getName: (item: ClinicSelectInfo) => getName(item, props.markCustomer),
		isEmptyValue,
	};

	return <CoreSelect store="clinics" {...properties} />;
};
