import * as React from 'react';

import Select, { SelectProps } from 'antd/lib/select';

import { Value } from '@app/components/UI/Filters/FilterHook';

export interface GroupOption {
	title: string;
	options: Array<Option>;
}

export interface Option {
	text: string;
	value: number | string;
}

interface BaseProps {
	withFieldName?: boolean;
	className?: string;
	style?: React.CSSProperties;
	size?: SelectProps['size'];
	onDeselect?: (fieldName: string, type: string, id?: number) => void;
	options: Array<Option>;
	fieldName: string;
	deselectType: string;
	placeholder?: string;
	filterName: string;
	selectClassName?: (value: string | number | Array<number> | undefined, length: number) => string;
	allowClear?: boolean;
	group?: (items: Array<Option>) => Array<GroupOption>;
	disabled?: boolean;
}

interface MultipleProps extends BaseProps {
	value?: Array<number>;
	onChange: (values: Array<number>) => void;
	multiple: boolean;
}

interface SingleProps extends BaseProps {
	value?: Value;
	onChange: (value: number) => void;
	multiple?: boolean;
}

type OwnProps = MultipleProps | SingleProps
const { Option, OptGroup } = Select;

function useOptions(options: Array<Option>, group?: (items: Array<Option>) => Array<GroupOption>) {
	if (group) {
		return group(options).map((item: GroupOption) => (
			<OptGroup label={item.title} key={item.title}>
				{item.options.map((item: Option) => (
					<Option
						key={item.value}
						value={Number(item.value)}
					>
						{item.text}
					</Option>
				))}
			</OptGroup>
		));
	}

	return options.map((item) => <Option key={item.value} value={Number(item.value)}>{item.text}</Option>);
}

export const LocalSelect = (props: OwnProps): React.ReactElement => {
	const {
		value,
		onChange,
		withFieldName,
		className,
		style,
		size,
		multiple = false,
		onDeselect,
		options,
		fieldName,
		deselectType,
		placeholder,
		filterName,
		selectClassName,
		allowClear = true,
		group,
		disabled,
	} = props;

	const [query, setQuery] = React.useState<string>('');
	const currentOptions = useOptions(options, group);

	const properties = {
		style,
		className: selectClassName ? selectClassName(value, query.length) : '',
		size,
		showArrow: true,
		showSearch: true,
		allowClear,
		disabled,
		showAction: ['focus', 'click'],
		mode: multiple ? 'multiple' : 'default',
		placeholder: placeholder ?? fieldName,
		optionFilterProp: 'children',
		onChange,
		value,
		children: currentOptions,
		onSearch: (query: string) => setQuery(query),
		onDeselect: (value: number) => onDeselect && onDeselect(filterName, deselectType, value),
		onSelect: () => setQuery(''),
		onBlur: () => setQuery(''),
	};

	return (
		<div className={className}>
			{withFieldName && <span className="field-name">{fieldName}</span>}
			{
				props.multiple
					? (<Select<Array<number>> {...(properties as SelectProps<Array<number>>)} />)
					: (<Select<number> {...(properties as SelectProps<number>)} />)
			}
		</div>
	);
};
