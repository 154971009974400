import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { UserRole } from '@app/objects/User';
import { InnerRouteProps, InnerRoute } from '@app/InnerRoute';
import { ApplicationState } from '@app/store';

interface UserDependantProps extends InnerRouteProps {
	redirectPath?: string;
	title?: string;
	dependantComponents: {
		[UserRole.CrematoryUser]?: React.ComponentType;
		[UserRole.Admin]?: React.ComponentType;
		[UserRole.ClinicUser]?: React.ComponentType;
		[UserRole.ClinicManager]?: React.ComponentType;
		[UserRole.Crematory]?: React.ComponentType;
	};
}

interface ReduxProps {
	role: UserRole | null;
}

type InjectedUserDependantRouteProps = UserDependantProps & ReduxProps;

const InjectedUserDependantRoute: React.FC<InjectedUserDependantRouteProps> = ({
	role, redirectPath = '/', title, dependantComponents, ...rest
}) => {
	if (role === null) return <Redirect to={redirectPath} />;

	return (
		<InnerRoute
			{...rest}
			component={dependantComponents[role]}
			title={title}
			redirectPath={redirectPath}
		/>
	);
};

export const UserDependantRoute = connect<ReduxProps, never, UserDependantProps, ApplicationState>(
	(state: ApplicationState) => ({
		role: state.login.user?.role ?? null,
	}),
)(InjectedUserDependantRoute);
