import { Nullable } from '@common/typescript/objects/Nullable';

interface WithName {
  firstName: string;
  lastName: string;
  email: string;
}

export const formatUserName = (user: Nullable<WithName>) => {
	if (!user) return 'Unknown user';

	return `${user?.firstName ?? ''} ${user?.lastName ?? ''} (${user?.email ?? ''})`;
};
